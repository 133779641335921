import { TaxmasterComponent } from './components/pages/settings/taxmaster/taxmaster.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganizationPageComponent } from './components/pages/settings/organization-page/organization-page.component';
import { AuthGuard } from './guards/auth.guard';
import { UserRolePageComponent } from './components/pages/settings/user-role-page/user-role-page.component';
import { AddPromotionFormPageComponent } from './components/forms/add-promotion-form-page/add-promotion-form-page.component';
import { InvitationFormPageComponent } from './components/pages/invitation-form-page/invitation-form-page.component';
import { MBDoubleLoadComponent } from './components/pages/settings/mb-double-load/mb-double-load.component';
import { PortalInfoComponent } from './components/portal/info/portal-info/portal-info.component';
import { InvoiceBaseComponent } from './components/portal/invoice/invoice-base/invoice-base.component';
import { DeliveryBaseComponent } from './components/portal/delivery/delivery-base/delivery-base.component';
import { CreditNoteBaseComponent } from './components/portal/credit-note/credit-note-base/credit-note-base.component';
import { OrderBaseComponent } from './components/portal/orders/order-base/order-base.component';
import { BenchmarkDashboard1Component } from './components/portal/dashboard/components/benchmark-dashboard1/benchmark-dashboard1.component';
import { OrderFormComponent } from './components/portal/orders/order-form/order-form.component';
import { DeliveryFormComponent } from './components/portal/delivery/delivery-form/delivery-form.component';
import { InvoiceFormComponent } from './components/portal/invoice/invoice-form/invoice-form.component';
import { CreditNoteFormComponent } from './components/portal/credit-note/credit-note-form/credit-note-form.component';
import { InvoiceResolveService } from './components/portal/invoice/resolvers/invoice-resolve.service';
import { DeliveryResolveService } from './components/portal/delivery/resolvers/delivery-resolve.service';
import { CreditNoteResolveService } from './components/portal/credit-note/resolvers/credit-note-resolve.service';
import { PortalAuthGuard } from './guards/portal-auth.guard';
import { OrderViewResolveService } from './components/portal/orders/resolvers/order-view-resolve.service';
import { InvoiceViewResolveService } from './components/portal/invoice/resolvers/invoice-view-resolve.service';
import { DeliveryViewResolveService } from './components/portal/delivery/resolvers/delivery-view-resolve.service';
import { ExpensesMasterComponent } from './components/portal/expenses/expenses-master/expenses-master.component';
import { AddExpensesComponent } from './components/portal/expenses/add-expenses/add-expenses.component';
import { InvoiceImportComponent } from './components/portal/invoice/invoice-import/invoice-import.component';
import { InvoiceExportComponent } from './components/portal/invoice/invoice-export/invoice-export.component';
import { ConvertToDeliveryComponent } from './components/portal/orders/convert-to-delivery/convert-to-delivery.component';
import { OrderViewDetailResolveService } from './components/portal/orders/resolvers/order-view-detail-resolver.service';
import { OrderResolveService } from './components/portal/orders/resolvers/order-resolve.service';
import { OrderImportComponent } from './components/portal/orders/order-import/order-import.component';
import { CollectionBaseComponent } from './components/portal/collection/collection-base/collection-base.component';
import { CollectionFormComponent } from './components/portal/collection/collection-form/collection-form.component';
import { CollectionResolveService } from './components/portal/collection/resolvers/collection-resolve.service';
import { DeliveryUpdateComponent } from './components/portal/delivery/delivery-update/delivery-update.component';
import { DeliveryImportComponent } from './components/portal/delivery/delivery-import/delivery-import.component';
import { GenerateInvoiceResolveService } from './components/portal/invoice/resolvers/generate-invoice-resolve.service';
import { GenerateInvoiceComponent } from './components/portal/invoice/generate-invoice/generate-invoice.component';
import { PosResolveService } from './components/loyality/pos/pos-resolve.service';
import { SegmentResolveService } from './components/loyality/segment/segment-resolve.service';
import { CampaignCategoriesResolveService } from './components/loyality/campaign-categories/campaign-categories-resolve.service';
import { EarningRulesResolveService } from './components/loyality/earning-rules/earning-rules-resolve.service';
import { UserRolesComponent } from './components/portal/portal-setting/user-roles/user-roles.component';
import { OrderVsInvoiceReportComponent } from './components/portal/invoice/order-vs-invoice-report/order-vs-invoice-report.component';
//import { CustomerPageComponent } from './components/loyality/customer/customer-page/customer-page.component';
//import { InvoiceFormComponent } from './components/portal/invoice/invoice-form/invoice-form.component';

const routes: Routes = [
  { path: 'dashboard', redirectTo: '/dashboard/board1', pathMatch: 'full' },

  /***** Payal work start *****/
  // { path: 'portal/dashboard', component: PortalDashboardComponent },
  {
    path: 'portal/dashboard',
    redirectTo: 'portal/dashboard/board1',
    pathMatch: 'full',
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/dashboard/board1',
    component: BenchmarkDashboard1Component,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/info',
    component: PortalInfoComponent,
    canActivate: [PortalAuthGuard],
  },
  // {
  //   path: 'portal/guide',
  //   component: PortalInfoComponent,
  //   canActivate: [PortalAuthGuard],
  // },
  {
    path: 'portal/order',
    component: OrderBaseComponent,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/order/add',
    component: OrderFormComponent,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/settings/user-role',
    component: UserRolesComponent,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/order/edit/:uuid',
    resolve: { order: OrderViewResolveService },
    canActivate: [PortalAuthGuard],
    component: OrderFormComponent,
  },
  {
    path: 'portal/order/re-order/:uuid',
    resolve: { order: OrderViewResolveService },
    canActivate: [PortalAuthGuard],
    component: OrderFormComponent,
  },
  {
    path: 'portal/order/start-delivery/:uuid',
    resolve: {
      order: OrderViewDetailResolveService,
      itemsorder: OrderViewResolveService,
      convert: OrderResolveService,
    },
    component: ConvertToDeliveryComponent,
  },
  {
    path: 'portal/guide',
    canActivate: [PortalAuthGuard],
    loadChildren: () =>
      import('./components/portal/guide/guide.module').then(
        (module) => module.GuideModule
      ),
  },
  {
    path: 'portal/reports',
    canActivate: [PortalAuthGuard],
    loadChildren: () =>
      import('./components/portal/reports/reports.module').then(
        (module) => module.ReportsModule
      ),
  },

  {
    path: 'portal/order/import',
    component: OrderImportComponent,
    canActivate: [PortalAuthGuard],
  },

  {
    path: 'portal/invoice',
    component: InvoiceBaseComponent,
    canActivate: [PortalAuthGuard],
  },
  // {
  //   path: 'portal/invoice/add',
  //   component: InvoiceFormComponent,
  //   resolve: {
  //     resolved: InvoiceResolveService,
  //   },
  //   canActivate: [PortalAuthGuard],
  // },
  // {
  //   path: 'portal/invoice/edit/:uuid',
  //   canActivate: [PortalAuthGuard],
  //   resolve: {
  //     resolved: InvoiceResolveService,
  //     editResolve: InvoiceViewResolveService,
  //   },
  //   component: InvoiceFormComponent,
  // },
  {
    path: 'portal/invoice/import',
    component: InvoiceImportComponent,
    resolve: {
      resolved: InvoiceResolveService,
    },
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/invoice/view',
    component: OrderVsInvoiceReportComponent,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/invoice/export',
    component: InvoiceExportComponent,
    resolve: {
      resolved: InvoiceResolveService,
    },
    canActivate: [PortalAuthGuard],
  },
  // {
  //   path: 'portal/invoice/generate-invoice/:uuid',
  //   resolve: {
  //     resolved: GenerateInvoiceResolveService,
  //   },
  //   component: GenerateInvoiceComponent,
  // },
  // {
  //   path: 'portal/delivery',
  //   component: DeliveryBaseComponent,
  //   canActivate: [PortalAuthGuard],
  // },
  // {
  //   path: 'portal/delivery/add',
  //   component: DeliveryFormComponent,
  //   resolve: {
  //     resolved: DeliveryResolveService,
  //   },
  //   canActivate: [PortalAuthGuard],
  // },
  // {
  //   path: 'portal/delivery/edit/:uuid',
  //   resolve: {
  //     resolved: DeliveryResolveService,
  //     delivery: DeliveryViewResolveService
  //   },
  //   component: DeliveryFormComponent
  // },
  // { path: 'portal/delivery/update', component: DeliveryUpdateComponent },
  // { path: 'portal/delivery/import', component: DeliveryImportComponent },
  {
    path: 'portal/credit-note',
    component: CreditNoteBaseComponent,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/credit-note/add',
    component: CreditNoteFormComponent,
    resolve: {
      resolved: CreditNoteResolveService,
    },
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/credit-note/edit/:uuid',
    canActivate: [PortalAuthGuard],
    resolve: {
      resolved: CreditNoteResolveService,
    },
    component: CreditNoteFormComponent,
  },
  {
    path: 'portal/collection',
    component: CollectionBaseComponent,
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/collection/add',
    component: CollectionFormComponent,
    resolve: {
      resolved: CollectionResolveService,
    },
    canActivate: [PortalAuthGuard],
  },
  {
    path: 'portal/collection/edit/:uuid',
    canActivate: [PortalAuthGuard],
    resolve: {
      resolved: CollectionResolveService,
    },
    component: CollectionFormComponent,
  },
  {
    path: 'portal/expense',
    component: ExpensesMasterComponent,
    canActivate: [PortalAuthGuard],
  },

  /***** Payal work end *****/

  /***** Loyality work start *****/

  //{ path: 'loyality/customer', component: CustomerPageComponent },

  {
    path: 'loyality/dashboard/board1',
    component: BenchmarkDashboard1Component,
  },
  {
    path: 'loyality/customer',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/loyality//customer/customer.module').then(
        (module) => module.CustomerModule
      ),
  },

  {
    path: 'loyality/pos',
    //canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/loyality/pos/pos.module').then(
        (module) => module.PosModule
      ),
    resolve: {
      resolved: PosResolveService,
    },
  },

  {
    path: 'loyality/segment',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/loyality/segment/segment.module').then(
        (module) => module.SegmentModule
      ),
    resolve: {
      resolved: SegmentResolveService,
    },
  },

  { path: 'loyality/settings/users', component: UserRolePageComponent },

  {
    path: 'loyality/settings',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/main/settings/setting.module').then(
        (module) => module.SettingModule
      ),
  },

  {
    path: 'loyality/level',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/loyality/levels/levels.module').then(
        (module) => module.LevelsModule
      ),
  },

  {
    path: 'loyality/transfers',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './components/loyality/points-transfers/points-transfers.module'
      ).then((module) => module.PointsTransfersModule),
  },

  {
    path: 'loyality/earning-rules',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/loyality/earning-rules/earning-rules.module').then(
        (module) => module.EarningRulesModule
      ),
    resolve: {
      resolved: EarningRulesResolveService,
    },
  },

  {
    path: 'loyality/redeemed-rewards',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './components/loyality/redeemed-rewards/redeemed-rewards.module'
      ).then((module) => module.RedeemedRewardsModule),
  },

  {
    path: 'loyality/transactions',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './components/loyality/transactions-loyality/transactions-loyality.module'
      ).then((module) => module.TransactionsLoyalityModule),
  },

  {
    path: 'loyality/reward-campaigns',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './components/loyality/reward-campaigns/reward-campaigns.module'
      ).then((module) => module.RewardCampaignsModule),
  },
  {
    path: 'loyality/categories',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './components/loyality/campaign-categories/campaign-categories.module'
      ).then((module) => module.CampaignCategoriesModule),
    resolve: {
      resolved: CampaignCategoriesResolveService,
    },
  },
  {
    path: 'loyality/emails',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/loyality/emails/emails.module').then(
        (module) => module.EmailsModule
      ),
  },

  /***** Loyality work end *****/

  // { path: 'portal/dashboard/board1', component: BenchmarkDashboard1Component },
  // { path: 'portal/info', component: PortalInfoComponent },
  // { path: 'portal/order', component: OrderBaseComponent },
  // { path: 'portal/order/add', component: OrderFormComponent},
  // { path: 'portal/invoice', component: InvoiceBaseComponent },
  // { path: 'portal/invoice/add', component: InvoiceFormComponent , resolve: {
  //   resolved: InvoiceResolveService,
  // },},
  // { path: 'portal/invoice/edit/:uuid', component: InvoiceFormComponent , resolve: {
  //   resolved: InvoiceResolveService,
  // },},
  // { path: 'portal/delivery', component: DeliveryBaseComponent },
  // { path: 'portal/delivery/add', component: DeliveryFormComponent, resolve: {
  //   resolved: DeliveryResolveService
  // }, },
  // { path: 'portal/credit-note', component: CreditNoteBaseComponent },
  // { path: 'portal/credit-note/add', component: CreditNoteFormComponent, resolve: {
  //   resolved: CreditNoteResolveService
  // } },

  // { path: 'portal/invoice/edit', component: InvoiceFormComponent },
  // { path: 'portal/invoice/add', component: InvoiceFormComponent },
  { path: '', redirectTo: '/dashboard/board1', pathMatch: 'full' },

  { path: 'settings/organization', component: OrganizationPageComponent },
  { path: 'MBdoubleload', component: MBDoubleLoadComponent },

  { path: 'account/password-change', component: InvitationFormPageComponent },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
  },
  {
    path: 'portal',
    canActivate: [PortalAuthGuard],
    loadChildren: () =>
      import('./components/portal/portal.module').then(
        (module) => module.PortalModule
      ),
  },
  {
    path: 'pricing-plan',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/main/pricing-plan/pricing-plan.module').then(
        (module) => module.PricingPlanModule
      ),
  },
  {
    path: 'transaction',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/main/transaction/transaction.module').then(
        (module) => module.TrsactionModule
      ),
  },
  {
    path: 'inventory',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/main/inventory/inventory.module').then(
        (module) => module.InventoryModule
      ),
  },
  {
    path: 'supervisor',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/main/supervisor/supervisor.module').then(
        (module) => module.SupervisorModule
      ),
  },
  {
    path: 'guide',
    loadChildren: () =>
      import('./components/guide/guide.module').then(
        (module) => module.GuideModule
      ),
  },
  {
    path: 'target',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./components/main/target/target.module').then(
        (module) => module.TargetModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      { path: 'settings/users', component: UserRolePageComponent },

      {
        path: 'settings/taxes',
        component: TaxmasterComponent,
        data: { animation: 'tax' },
      },
      {
        path: 'masters',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/master/master.module').then(
            (module) => module.MasterModule
          ),
      },
      {
        path: 'settings',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/settings/setting.module').then(
            (module) => module.SettingModule
          ),
      },
      {
        path: 'grouping',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/grouping/grouping.module').then(
            (module) => module.GroupingModule
          ),
      },
      {
        path: 'salesperson',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/pages/Salesmann/salesman.module').then(
            (module) => module.SalesPersonModule
          ),
      },
      {
        path: 'finance',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/finance/finance.module').then(
            (module) => module.FinanceModule
          ),
      },

      {
        path: 'expense',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/expenses/expense.module').then(
            (module) => module.ExpensesModule
          ),
      },
      {
        path: 'estimate',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/estimate/estimate.module').then(
            (module) => module.EstimateModule
          ),
      },
      {
        path: 'merchandising',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/merchandising/merchandising.module').then(
            (module) => module.MerchandisingModule
          ),
      },
      {
        path: 'reports',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./components/main/reports/reports.module').then(
            (module) => module.ReportsModule
          ),
      },
      { path: 'promotion/add', component: AddPromotionFormPageComponent },
      { path: '**', redirectTo: 'dashboard' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
