<div class="row">
  <div class="col-md-12">
    <form [formGroup]="filterForm" (ngSubmit)="applyFilterOnClick()">
      <div class="form-group row" style="padding: 5px">
        <div class="col-sm-12">
          <div class="row mt-2 pl-3">
            <div class="col-3">
              <input
                type="date"
                formControlName="startdate"
                (change)="applyFilter()"
                class="form-control form-control-sm"
                placeholder="Start Date"
              />
            </div>
            <div class="">
              <span>_</span>
            </div>
            <div class="col-3">
              <input
                type="date"
                formControlName="enddate"
                (change)="applyFilter()"
                class="form-control form-control-sm"
                placeholder="End Date"
              />
            </div>
            <!-- <div class="col">
                            <mat-select formControlName="type" (selectionChange)="changeFilterType($event.value)"
                                class="form-control form-control-sm" placeholder="Filter Type">
                                <mat-option *ngFor="let item of filtersList" [value]="item">{{item}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'Channel'">
                            <app-multiautocomplete isSingle="false" [control]="filterForm.controls['channel']"
                                [options]="channelList" (selectionchanged)="applyFilter()" text="name" id="id"
                                placeholder="Search channel">
                            </app-multiautocomplete>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'Region'">
                            <app-multiautocomplete isSingle="false" [options]="regionList"
                                (selectionchanged)="applyFilter()" [control]="filterForm.controls['region']"
                                text="region_name" id="id" placeholder="Search region">
                            </app-multiautocomplete>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'Merchandiser'">
                            <app-multiautocomplete isSingle="false" [options]="merchandiserList"
                                (selectionchanged)="applyFilter()" [control]="filterForm.controls['salesman']" text=""
                                id="user_id" objectName="user" param1="firstname" param2="lastname"
                                placeholder="Search merchandiser">
                            </app-multiautocomplete>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'Supervisor'">
                            <app-multiautocomplete isSingle="false" [options]="supervisorList"
                                (selectionchanged)="applyFilter()" [control]="filterForm.controls['supervisor']"
                                text="salesman_supervisor" id="id" objectName="user-root" param1="firstname"
                                param2="lastname" placeholder="Search supervisor">
                            </app-multiautocomplete>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'Salesman'">

                            <app-multiautocomplete isSingle="false" [options]="salesmanList"
                                (selectionchanged)="applyFilter()" [control]="filterForm.controls['salesman']"
                                text="user" id="id" placeholder="Select Salesman" objectName="user-root"
                                param1="firstname" param2="lastname">
                            </app-multiautocomplete>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'NSM'">
                            <app-multiautocomplete isSingle="false" [options]="nsmList"
                                (selectionchanged)="applyFilter()" [control]="filterForm.controls['nsm']" text="name"
                                id="id" objectName="user-root" param1="firstname" param2="lastname"
                                placeholder="Search NSM">
                            </app-multiautocomplete>
                        </div>
                        <div class="col" *ngIf="filterForm.controls['type'].value == 'ASM'">
                            <app-multiautocomplete isSingle="false" [options]="asmList"
                                (selectionchanged)="applyFilter()" [control]="filterForm.controls['asm']" text="name"
                                id="id" objectName="user-root" param1="firstname" param2="lastname"
                                placeholder="Search ASM">
                            </app-multiautocomplete>
                        </div> -->
            <div class="col-1">
              <button type="submit" class="btn btn-sm btn-info mr-1">
                <i
                  class="fa fa-arrow-right"
                  style="height: 27px; font-size: 26px"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div
  class="col-sm-12 row"
  style="margin-top: 26px; border-bottom: 1px #bfc5cb solid"
>
  <!-- <div class="col" style="height: 100px; text-align: center;">
        <p class="chart-heading">Sales vs Target</p>
        <p><img src="assets/images/greendot.png" /></p>
        <p>{{dashboardData?.Achivements?.salesTarget}}<span class="slash-size">/</span>{{dashboardData?.Achivements?.targetValue}} (AED)</p>
        <div style="text-align: center;">

            <span style="position: absolute;margin-top: -10px;font-size: x-large;z-index: 9;">|</span>
            <mat-progress-bar style="width: 100px;margin-left: 47px;" mode="determinate" [value]="dashboardData?.Achivements?.SallesVsTargetPer">
            </mat-progress-bar>
        </div>
        <p style="margin-top: 12px;">{{dashboardData?.Achivements?.SallesVsTargetPer}}%</p>

    </div> -->
  <div class="col" style="height: 100px; text-align: center">
    <p class="chart-heading">Total Order</p>
    <p><img src="assets/images/greendot.png" /></p>
    <p>
      {{ dashboardData?.total_amount | number : "3.1-2" }}
      (AED)
    </p>

    <div style="padding: 0 76px">
      <mat-progress-bar
        style="width: 100%"
        mode="determinate"
        [value]="dashboardData?.total_amount"
      >
      </mat-progress-bar>
    </div>
    <!-- <p style="margin-top: 12px;">{{dashboardData?.Achivements?.gross_achivement.gross_per}}%</p> -->
  </div>
  <!-- <div class="col" style="height: 81px; text-align: center;">
    <p class="chart-heading">Total Order</p>
    <p><img src="assets/images/redcircle.png" /></p>
    <p>
      {{ dashboardData?.net_achivementnet_total
      }}<span class="slash-size">/</span
      >{{ dashboardData?.Achivements?.net_achivement.target | number: "3.1-2" }}
      (Units)
    </p>
    <div style="padding: 0 76px;">
      <mat-progress-bar
        style="width: 100%;"
        mode="determinate"
        [value]="dashboardData?.Achivements?.net_achivement.net_pr"
        [color]="color"
      >
      </mat-progress-bar>
    </div>
    <p style="margin-top: 12px;">
      {{ dashboardData?.Achivements?.net_achivement.net_pr }}%
    </p>
  </div> -->
  <div class="col" style="height: 81px; text-align: center">
    <p class="chart-heading">Total Invoice</p>
    <p><img src="assets/images/redcircle.png" /></p>
    <p>
      {{ dashboardData?.invoice_amount | number : "3.1-2" }}
      <!-- <span class="slash-size">/</span
      >{{
        dashboardData?.Achivements?.return_achivement.total | number: "3.1-2"
      }} -->
      (AED)
    </p>
    <div style="padding: 0 76px">
      <mat-progress-bar
        style="width: 100%"
        mode="determinate"
        [value]="dashboardData?.invoice_amount"
        [color]="color"
      >
      </mat-progress-bar>
    </div>
    <!-- <p style="margin-top: 12px;">
      {{ dashboardData?.Achivements?.return_achivement.return_pr }}%
    </p> -->
  </div>
  <!-- <div class="col" style="height: 81px; text-align: center;">
    <p class="chart-heading">Total Return</p>
    <p><img src="assets/images/redcircle.png" /></p>
    <p>
      {{
        dashboardData?.Achivements?.estimated_closing.es_closing
          | number: "3.1-2"
      }}<span class="slash-size">/</span
      >{{
        dashboardData?.Achivements?.estimated_closing.target | number: "3.1-2"
      }}
      (AED)
    </p>
    <div style="padding: 0 76px;">
      <mat-progress-bar
        style="width: 100%;"
        mode="determinate"
        value="23"
        [color]="color"
      >
      </mat-progress-bar>
    </div>
    <p style="margin-top: 12px;">
      {{ dashboardData?.Achivements?.estimated_closing.es_percentage }}%
    </p> -->
  <!-- <p style="margin-top: 12px;">23%</p> -->
  <!-- </div> -->
</div>
<!-- <div class="col-sm-12 row" style="margin-top: 11px;"></div> -->

<!-- <div class="col-sm-12 row">
  <div class="col-sm-3" style="height: 200px;">
    <div #charRef></div>
  </div>
  <div class="col-sm-3" style="height: 200px;">
    <div #charRef1></div>
  </div>
  <div class="col-sm-3" style="height: 200px;">
    <div #charRef3></div>
  </div>
  <div class="col-sm-3" style="height: 200px;">
    <div #charRef5></div>
  </div>
</div> -->

<div class="col-sm-12 row" style="margin-top: 50px">
  <div class="col-sm-4" style="height: 150px">
    <p class="chart-heading">Total Order vs Total Invoice Amount</p>
    <div #donot1Ref></div>
  </div>
  <div class="col-sm-4">
    <p class="chart-heading">Most Ordered Item</p>
    <div class="mat-item-select-panel">
      <table mat-table [dataSource]="dataSource">
        <!-- Position Column -->
        <ng-container matColumnDef="item_name">
          <th mat-header-cell *matHeaderCellDef>Item Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.items?.item_name }}
            <!-- -{{ element.route_name }} -->
          </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="item_qty">
          <th mat-header-cell *matHeaderCellDef>Total Qty</th>
          <td mat-cell *matCellDef="let element">{{ element.item_qty }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="item_price">
          <th mat-header-cell *matHeaderCellDef>Total AMount</th>

          <td mat-cell *matCellDef="let element">{{ element.item_price }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <!-- <ng-container matColumnDef="Quantity">
          <th mat-header-cell *matHeaderCellDef>JP%</th>
          <td mat-cell *matCellDef="let element">{{ element.JP_percent }}</td>
        </ng-container> -->

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
  <div class="col-sm-4" style="height: 150px">
    <p class="chart-heading">Total Order Vs Total Invoice Qty</p>
    <div #donot2Ref></div>
  </div>
</div>
<div class="col-sm-12 row" style="margin-top: 30px">
  <div class="col-sm-12" style="height: 200px; margin-top: 20px">
    <p class="chart-heading">Month Order Value {{ monthName }} {{ year }}</p>
    <div #charRef4></div>
  </div>
</div>
