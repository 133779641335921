<div class="card">
  <div class="card-header pt-4">
    <span class="h6 font-weight-bold">Sync {{title}}</span>
    <a [mat-dialog-close]="true" class="closeicon settingclose"><i class="os-icon os-icon-x"></i></a>
  </div>
  <div class="card-body">
    <mat-divider></mat-divider>
    <form [formGroup]="exportForm">
      <div>
        <section class="full-w">
          <mat-radio-group formControlName="type" name="type">
            <mat-radio-button class="example-margin full-w" value="1">All</mat-radio-button>
            <mat-radio-button class="example-margin full-w" value="0">Date Range</mat-radio-button>
          </mat-radio-group>
        </section>
        <div class="full-w row" *ngIf="typevalue == 0">
          <div class="col-8">
            <label class="full-w">From </label>
            <input type="date" formControlName="start_date" name="start_date" class="form-control form-control-sm"
              placeholder="Choose From Date">
          </div>
          <div class="col-8">
            <label class="full-w">To </label>
            <input type="date" formControlName="end_date"  name="start_date"
              class="form-control form-control-sm" placeholder="Choose To Date">
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="card-footer">
    <div class="d-inline float-right">
      <button class="btn btn-sm btn-p" [disabled]="exportForm.invalid" [mat-dialog-close]="true"
        (click)="syncForm()">
        Submit
      </button>
      <button class="btn btn-sm btn-white mr-2" mat-dialog-close>Cancel</button>
    </div>
  </div>