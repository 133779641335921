import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PAGE_SIZE } from 'src/app/app.constant';
import { CustomerActivityListComponent } from 'src/app/components/main/master/journey-plan/journey-plan-detail/customer-activity-list/customer-activity-list.component';
import { Customer } from 'src/app/components/main/transaction/collection/collection-models';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { PortalInfoService } from '../../portal-info.service';
@Component({
  selector: 'app-portal-info-sales',
  templateUrl: './portal-info-sales.component.html',
  styleUrls: ['./portal-info-sales.component.scss']
})
export class PortalInfoSalesComponent implements OnInit {
  @Input() public customer: any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('formDrawer') ExpemseFormDrawer: MatDrawer;
  //customer: any = customerInfo;
  @Input() public lobInfo: any;
  public goToTransaction: any;
  public newExpenseData = {};
  public transactionList: any = [
    { id: 'invoice', value: 'Invoice' },
    // { id: 'estimation', value: 'Estimate' },
    { id: 'order', value: 'Order' },
    // { id: 'expense', value: 'Expense' },
    { id: 'creditnote', value: 'Credit Note' },
    { id: 'collection', value: 'Collection' },
  ];

  public salesmanTabs = {
    invoice: false,
    collection: false,
    delivery_detail: false,
    estimation: false,
    expense: false,
    isRecurringExpense: false,
    creditnote: false,
    order: false,
  };
  public salesOptions: any = [
    'All',
    'Draft',
    'Client Viewed',
    'Partially Paid',
    'Overdue',
    'Paid',
    'Void',
  ];

  displayedColumns: string[] = [
    'date',
    'invoice',
    'amount',
    'balancedue',
    'status',
  ];
  displayedOrderColumns: string[] = [
    'date',
    'order',
    'amount',
    'status',
  ];
  displayedColumnsExpense: string[] = [

  ];
  displayedColumnsCollection: string[] = [
    'collection_number',
    'invoice_amount',
    'payment_mode',
  ];
  displayedColumnsDelivery: string[] = [
    'delivery_number',
    'delivery_date',
    'grand_total',
    'status',
  ];
  displayedColumnsCreditNotes: string[] = [
    'credit_note_number',
    'credit_note_date',
    'grand_total',
    'status',
  ];

  dataSource = new MatTableDataSource<any>();
  invoices = new MatTableDataSource<CustomerDetail>();
  creditNotes = new MatTableDataSource<CustomerDetail>();
  expense = new MatTableDataSource<CustomerDetail>();
  deliveryDetail = new MatTableDataSource<CustomerDetail>();
  estimation = new MatTableDataSource<CustomerDetail>();
  collection = new MatTableDataSource<CustomerDetail>();
  tabFilter: FormControl = new FormControl();
  currentTab: string;
  response: any;
  pageSize = PAGE_SIZE;
  private fds: FormDrawerService;
  constructor(private infoService: PortalInfoService, private router: Router, fds: FormDrawerService) {
    //super('Expenses');
    Object.assign(this, { fds });
  }

  ngOnInit(): void {
    this.tabFilter.valueChanges.subscribe((item) => {
      let element: HTMLElement = document.querySelector(
        `#${item.split(' ').join('')}Btn`
      ) as HTMLElement;
      if (!element) return;
      element.click();
    });
  }

  onToggleTab(tabName) {
    console.log(tabName)
    if (!this.salesmanTabs[tabName]) {
      this.dataSource.data = []
      this.getDetail(tabName, 1)
    }
    Object.keys(this.salesmanTabs).forEach((item) => {
      if (item === tabName) return;
      this.salesmanTabs[item] = false;
    });
    this.salesmanTabs[tabName] = !this.salesmanTabs[tabName];

  }

  onPageFired(data) {
    this.getDetail(this.currentTab, data['pageIndex'] + 1)
  }
  getDetail(tabName, page) {
    this.currentTab = tabName;
    //this.dataSource.data =this.customer
    //this.response = this.customer;
    this.infoService
      .getCustomerDetail(this.customer.user_id, page, this.pageSize, tabName, this.lobInfo?.lob_id)
      .subscribe((res: any) => {
        if (res.status) {
          this.response = res.data;
          this.dataSource.data = res.data.data
          console.log(this.dataSource)
        }
      });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.fds.setDrawer(this.ExpemseFormDrawer);
    if (this.router.url.includes('expense/add')) {
      this.openAddExpenses()
    }
  }

  updateTableData(data) {
    this.newExpenseData = data;
  }
  openAddExpenses() {
    // this.router.navigate(['add-expenses']);
    this.fds.setFormName('add-expense');
    this.fds.setFormType('Add');
    this.fds.open();
  }


}

export interface CustomerDetail {
  id: number;
  uuid: string;
  customer_id: number;
  invoice_number: string;
  invoice_date: string | Date | null;
  grand_total: string;
  status: string | null;
  pending_amount: any;
  delivery_number: string;
  collection_number: string;
  delivery_date: string | Date | null;
  expense_date: string | Date | null;
  reference: string;
  expense_category: string | null;
  invoice_amount: string;
  payment_mode: string;
}

