import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { AuthService } from 'src/app/services/auth.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-profile-drawer',
  templateUrl: './profile-drawer.component.html',
  styleUrls: ['./profile-drawer.component.scss']
})
export class ProfileDrawerComponent implements OnInit, OnChanges,AfterViewInit {
  @Input() drawerData: any;
  firstname;
  lastname;
  email;
  id;
  public avatarImage: string = 'assets/images/p1.ico';
  domain = window.location.host.split('.')[0];
  allSoftware = JSON.parse(localStorage.getItem('allSoftware'));
  access_token = localStorage.getItem('token');
  subdomains = [
    { name: 'Pre Sale', url: 'https://presales.mobiato-msfa.com', img: '/assets/img/pre-logo.png' },
    { name: 'Vansales Hybrid', url: 'https://vansales.mobiato-msfa.com', img: '/assets/img/van-logo.png' },
    { name: 'Merchandising', url: 'https://merchandising.mobiato-msfa.com', img: '/assets/img/mar-logo@2x.png' },
    { name: 'Invoice', url: 'https://invoice.mobiato-msfa.com', img: '/assets/img/invoice.png' },
    // { name: 'Loyality Management', url: 'http://localhost:4200', img: '/assets/img/loyality.png' },
    { name: 'Loyality Management', url: `http://${window.location.host}`, img: '/assets/img/loyality.png' },
  ];


  constructor(private fds: FormDrawerService, private router: Router, private authService: AuthService) {
  }

  ngOnInit(): void {
    console.log(this.drawerData)
    this.fds.formType.subscribe(x => {
      console.log(x)
    })
  }

  ngAfterViewInit(): void {
    this.fds.formType.subscribe(x => {
      console.log(x)
    })
  }

  ngOnChanges() {
    if(this.drawerData){
      this.avatarImage = this.drawerData?.avatarImage;
      this.firstname = this.drawerData.firstname
      this.lastname = this.drawerData.lastname
      this.email = this.drawerData.email
      this.id = this.drawerData.id
    }
  }

  close() {
    console.log(this.fds.closeNav())
    this.fds.closeNav();
  }

  open(){
    this.fds.closeNav();
    this.fds.setFormName('profile');
    this.close();
  }


  signOut() {
    this.firstname = ''
    this.lastname = ''
    this.email = ''
    console.log('signout')
    if (this.checkPortalSidebar()) {
      this.portalSignOut();
    } else {
      this.userSignOut();
    }
    this.close();
  }

  userSignOut() {
    this.authService.logout().subscribe((res) => {
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.removeItem('appSettingSidebar');
      localStorage.removeItem('appLoyalitySettingSidebar');
      localStorage.removeItem('reportbar');
      localStorage.removeItem('appLoyalitySidebar');
      localStorage.removeItem('allSoftware');
      localStorage.removeItem('org_name');
      window.location.reload();
    });
  }

  portalSignOut() {
    this.authService.logout().subscribe((res) => {
      localStorage.setItem('isPortalLoggedIn', 'false');
      localStorage.setItem('isLoggedIn', 'false');
      localStorage.removeItem('portalCustomer');
      localStorage.removeItem('portalPermissions') ;
      localStorage.removeItem('portalAccessToken') ;
      localStorage.removeItem('portalLoggedInUser');
      localStorage.removeItem('portal-reportbar');
      localStorage.removeItem('portalSidebar');
      this.router.navigate(['/auth/my-portal/engear/login']);
      window.location.reload();
    })
  }

  checkPortalSidebar() {
    const value = JSON.parse(localStorage.getItem('isPortalLoggedIn'))
    if (value && value === true && this.router.url.includes('portal')) {
      return true;
    } else {
      return false;
    }
  }

  checkApp(name) {
    let obj = this.allSoftware.filter(
      (x) => {
        return x.name.toLowerCase().replace(/\s/g, '') == name.toLowerCase().replace(/\s/g, '')
      });
    return obj.length > 0 ? false : true;
  }

  getAppUrl(app) {
    let App = 'local'
    if (app == 'local') {
      //if (App == 'local') {
      window.open(`http://localhost:4400?is_active=0&access_token=${this.access_token}`, "_blank");
      //window.open(`http://localhost:4400?is_active=1&access_token=${this.access_token}`, "_blank");
      return false;
    }
    let obj = this.allSoftware.filter(
      (x) => {
        return x.name.toLowerCase().replace(/\s/g, '') == app.name.toLowerCase().replace(/\s/g, '')
      });
    let url = '';
    if (obj.length > 0) {
      if (app.name == 'Loyality Management') {
        url = `${app.url}?is_active=1&access_token=${this.access_token}&name=${app.name}`;
        window.open(url, "_blank");
      }
      else {
        url = `${app.url}?is_active=${obj[0].is_active}&access_token=${this.access_token}`;
        window.open(url, "_blank");
      }
    }
    //return url;
  }

  checkAppActive(name) {
    let obj = this.allSoftware.filter(
      (x) => {
        return x.name.toLowerCase().replace(/\s/g, '') == name.toLowerCase().replace(/\s/g, '')
      });
    let url = true;
    if (obj.length > 0) {
      url = obj[0].is_active == 1 ? false : true;
    }
    return url;
  }


}
