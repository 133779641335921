
<div class="container mt-1">
    <div class="row " >
        <div class="col-md-2">
            <h3 class="pref">Preferences</h3>
            <div class="rowm">
                <div class="scrollable">
                    <div class="lpane-grp">
                        <ul class="nav nav-pills flex-column">
                            <li class="nav-item" *ngFor="let navOption of preferenceNavOptions; let i = index;"> 
                                <a id="ember2314" 
                                    [ngClass]="i == activeRoute ? 'active' : ''" 
                                    (click)="routeTo(navOption.routeTo,i)" 
                                    class="nav-link ember-view"
                                > {{ navOption.label }} 
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-10 p-0">
            <!-- <app-preference-right-panel></app-preference-right-panel> -->
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

