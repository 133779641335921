
import { Observable, forkJoin } from 'rxjs';
import { Injectable, SkipSelf } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, filter } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MasterService } from 'src/app/components/main/master/master.service';
import { endpoints } from 'src/app/api-list/api-end-points';
@Injectable({
  providedIn: 'root',
})
export class PortalInfoService {
  // constructor(@SkipSelf() private http: HttpClient, public masterService: MasterService, public apiService: ApiService) { }
  constructor(private http: HttpClient, public masterService: MasterService, public apiService: ApiService) { }

  getData(): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    return this.http
      .get<any>('../../assets/constants/dashboard.json', { headers })
      .pipe(map((r) => r));
  }

  getMasterList(): Observable<any> {
    const obj = {
      "list_data": ["org-role", "nsm", "asm", "salesman", "region", "channel", "merchandiser", "salesman_supervisor", 'item_major_category', 'item', 'brand_list'],
      "function_for": "customer"
    }
    const masterData = this.masterService.masterList(obj).pipe(map(result => result));
    return forkJoin({ masterData });
  }

  getDashboardFiltersData(filterdata): Observable<any> {
    const dashboardData = this.apiService.getDashboarddata(filterdata).pipe(map(result => result));
    return forkJoin({ dashboardData });
  }
  getDashboard2FiltersData(filterdata): Observable<any> {
    const obj = {
      "list_data": ["org-role", "nsm", "asm", "region", "channel", "merchandiser", "salesman_supervisor", 'item_major_category', 'item', 'brand_list'],
      "function_for": "customer"
    }
    const dashboardData = this.apiService.getDashboard2data(filterdata).pipe(map(result => result));
    const masterData = this.masterService.masterList(obj).pipe(map(result => result));

    return forkJoin({ masterData, dashboardData });
  }

  getDashboard3FiltersData(filterdata): Observable<any> {
    const obj = {
      "list_data": ["org-role", "nsm", "asm", "region", "channel", "merchandiser", "salesman_supervisor"],
      "function_for": "customer"
    }
    const dashboardData = this.apiService.getDashboard3data(filterdata).pipe(map(result => result));
    const masterData = this.masterService.masterList(obj).pipe(map(result => result));

    return forkJoin({ masterData });
  }

  getDashboard4FiltersData(filterdata): Observable<any> {
    const dashboardData = this.apiService.getDashboard4data(filterdata).pipe(map(result => result));
    return forkJoin({ dashboardData });
  }

  getDashboardDataByFilter(body): Observable<any> {
    return this.apiService.getDashboarddata(body).pipe(map(result => result));
  }
  getDashboard2DataByFilter(body): Observable<any> {
    return this.apiService.getDashboard2data(body).pipe(map(result => result));
  }
  getDashboard3DataByFilter(body): Observable<any> {
    return this.apiService.getDashboard3data(body).pipe(map(result => result));
  }
  getDashboard4DataByFilter(body): Observable<any> {
    return this.apiService.getDashboard4data(body).pipe(map(result => result));
  }

  getBenchmarkDashboardDataByFilter(body): Observable<any> {
    return this.apiService.getnewDashboarddata(body).pipe(map(result => result));
  }
  getBenchmarkDashboard4DataByFilter(body): Observable<any> {
    return this.apiService.getnewDashboard4data(body).pipe(map(result => result));
  }
  getCustomerDetail(
    uuid: number,
    pageNumber: number,
    pageSize: number,
    module: string,
    lob_id: number = 0
  ): Observable<any> {
    return this.http.get<any>(
      endpoints.portalapiendpoint.customer.detail(uuid, pageNumber, pageSize, module, lob_id)
    );
  }
  
  getInvoiceChart(payload): Observable<any> {
    return this.http.post<any>(endpoints.apiendpoint.customer.chart(), payload);
  }

  getBalance(customerId: number, lob_id = 0): Observable<any> {
    return this.http.get<any>(
      endpoints.apiendpoint.customer.balance(customerId) + `?lob_id=${lob_id}`
    );
  }

  getStatement(payload: any): Observable<any> {
    return this.http.post<any>(
      endpoints.apiendpoint.customer.statement(),
      payload
    );
  }

  downloadPdf(payload: any): Observable<any> {
    return this.http.post<any>(
      endpoints.apiendpoint.customer.statement(),
      payload
    );
  }
  getPdf(url: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers
      .set('Accept', 'application/pdf')
      .set('Content-Type', 'application/pdf');
    return this.http.get<any>(url, { headers, responseType: 'blob' as 'json' });
  }
}
