import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CommonToasterService } from 'src/app/services/common-toaster.service';
import { CompDataServiceType } from 'src/app/services/constants';
import { DataEditor } from 'src/app/services/data-editor.service';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-delete',
  templateUrl: './order-delete.component.html',
  styleUrls: ['./order-delete.component.scss']
})
export class OrderDeleteComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<OrderDeleteComponent>,
    private commonToasterService: CommonToasterService,
    private orderService: OrderService,
    private dataService: DataEditor,
    private router: Router,
    ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

  cancel(){
    this.dialogRef.close();
  }

  confirm(){
    this.orderService.getDeleteOrderById(this.data.uuid).subscribe(res=>{
      this.router.navigate(['portal/order']);
      this.dialogRef.close();
    })
    // this.dialogRef.close();
  }

  

}
