<section class="">
    <div class="pl-3">
        <div class="row">
            <div class="col-md-4 p-0 pr-lg-3  pr-md-0 pl-0">
                <app-left-panel-overview [customer]="customer" [lobInfo]="lobInfo"></app-left-panel-overview>
            </div>
            <div class="col-md-8 p-0">
                <app-right-panel-overview [customer]="customer" [lobInfo]="lobInfo"></app-right-panel-overview>
            </div>
        </div>
    </div>

</section>
