import { Component, OnInit, ViewChild, HostListener, OnDestroy, SimpleChanges } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SidenavService } from 'src/app/services/sidenav.service';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { ThrowStmt } from '@angular/compiler';
import { Subscription } from 'rxjs';
import { DataEditor } from 'src/app/services/data-editor.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {
  activePath: string;
  settingsNav: boolean;
  portalNav: boolean = false;
  panelOpenState;
  showTitle: boolean = true;
  sidebarConfig = [];
  sidebarSettingConfig = [];
  sidebarPortalConfig = [];
  //domain = window.location.host.split('.')[0];
  domain = 'merchandising';
  showSidebar: boolean = true;
  private _interval: NodeJS.Timeout;
  private subscriptionName: Subscription;
  sidebarLoyalityConfig: any;
  loyalityNav: any;
  sidebarLoyalitySettingConfig: any;
  loyalitySettingsNav: boolean;
  constructor(
    private router: Router,
    private sidenavService: SidenavService,
    private fds: FormDrawerService,
    private dataEditorService: DataEditor
  ) {
    let url_string = window.location.href;
    let urlData = url_string?.split("=");
    let urlName = urlData[urlData?.length - 1].replace(/%20/g, '');
    //let appName =  localStorage.getItem('appName')
    let appName = sessionStorage.getItem('appName')
    // console.log("urlName", urlName);
    if (urlName !== 'LoyalityManagement' && appName !== 'LoyalityManagement' && !window.location.pathname.includes('portal')) {
      this.sidenavService.softwareSidebar();
    }
    if(localStorage.getItem('isPortalLoggedIn') == 'true'  && window.location.pathname.includes('portal')){
      this.sidenavService.portalSoftwareSidebar();
    }

    // this.subscriptionName = this.dataEditorService.getSideNavUpdate().subscribe
    //   (message => { //message contains the data sent from service
    //     //this.sid = message;
    //     //window.location.reload();
    //     // for (let menu of this.sidebarConfig) {
    //     //   this.featureCheck(menu.label)
    //     // }
    //     this.sidebarConfig = message.data
    //     this.sidenavService.appSidebar = message.text;
    //     localStorage.setItem('appSidebar', JSON.stringify(this.sidebarConfig));

    //   });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    localStorage.removeItem('portalNav');
    this.initSideBar();
  }

  initSideBar() {
    this.router.events.subscribe(e => {
      this.fds.close();
      if (e instanceof NavigationEnd) {
        this.activePath = e.url;
        if (this.activePath.includes('/settings')) {
          if (this.activePath.includes('/loyality/setting')) {
            this.showSidebar = true;
            //   //localStorage.setItem('appLoyalitySettingNav', JSON.stringify(true));
            //this.getLoyalitySettingNav();
            this.loyalitySettingsNav = true;
            //  if(this.sidebarLoyalitySettingConfig.length == 0){
            //    this.getSideBar();
            //  }
          }
          else {
            this.settingsNav = true;
            this.showSidebar = true;
          }
        }
        else if (this.activePath.includes('/portal')) {
          this.showSidebar = true;
          localStorage.setItem('portalNav', JSON.stringify(true));
          //this.portalNav = true;
          this.getPortalNav();
        }
        else if (this.activePath.includes('/my-portal')) {
          this.showSidebar = false;
        }
        else if (this.activePath.includes('/loyality')) {
          this.showSidebar = true;
          this.loyalitySettingsNav = false;
          this.loyalityNav = true;
          //if(this.sidebarLoyalityConfig.length == 0){
          //this.getLoyalityNav();
          //}
          localStorage.setItem('loyalityNav', JSON.stringify(true));
        }
        else {
          this.settingsNav = false;
          this.portalNav = false;
          this.showSidebar = true;
          this.loyalityNav = false;
          this.loyalitySettingsNav = false;
        }
      }
    })
    if (this.sidebarConfig.length == 0) {
      this.getSideBar();
    }
  }

  getSideBar() {
    this.sidenavService.getSideBar().subscribe((res) => {
      this.sidebarConfig = res;
      // console.log(res);
      // for (let menu of this.sidebarConfig) {
      //   menu.show = this.featureCheck(menu.label);
      // }
      // localStorage.setItem('appSidebar', JSON.stringify(this.sidebarConfig));

    });
    this.sidenavService.getSettingSideBar().subscribe((res) => {
      this.sidebarSettingConfig = res;
    });
    // this.sidenavService.getPortalSideBar().subscribe((res) => {
    //   this.sidebarPortalConfig = res;
    // });

    //this.getPortalNav();
    this.getLoyalityNav();
    this.getLoyalitySettingNav();
  }

  getPortalNav() {
    this.portalNav = JSON.parse(localStorage.getItem('portalNav')) ? JSON.parse(localStorage.getItem('portalNav')) : false;
    this.sidenavService.getPortalSideBar().subscribe((res) => {
      let permission = JSON.parse(localStorage.getItem('portalPermissions'))
      // console.log(permission ,res);
      this.sidebarPortalConfig = res.filter((p:any)=> permission.some(r=>p.label === r.moduleName));
      // console.log(res)
    });
  }

  getLoyalitySettingNav() {
    //this.portalNav = JSON.parse(localStorage.getItem('portalNav')) ? JSON.parse(localStorage.getItem('portalNav')): false;
    //this.loyalitySettingsNav = true;
    //if(this.loyalitySettingsNav){
    this.sidenavService.getLoyalitySettingsSideBar().subscribe((res) => {
      this.sidebarLoyalitySettingConfig = res;
      //this.sidebarPortalConfig = res;
    });
    //}
  }
  getLoyalityNav() {
    //this.loyalityNav = JSON.parse(localStorage.getItem('loyalityNav')) ? JSON.parse(localStorage.getItem('loyalityNav')): false;
    //this.loyalityNav = true;
    //if(this.loyalityNav){
    this.sidenavService.getLoyalitySideBar().subscribe((res) => {
      this.sidebarLoyalityConfig = res;
    });
    //}
  }

  featureCheck(value, hasSubmenu?) {
    // let appName =  sessionStorage.getItem('appName')
    // this._interval = setInterval(() => {
    // 	if (appName) {
    //     clearInterval(this._interval);
    //     return this.sidenavService.featureCheck(value);
    // 	}
    // }, 100);
    return this.sidenavService.featureCheck(value);
  }
  settingFeatureCheckDropDowns(value) {
    return this.sidenavService.settingFeatureCheckDropDowns(value);
  }

  sidebarFeatureCheckDropDowns(value) {
    return this.sidenavService.settingFeatureCheckDropDowns(value);
  }

  sumenuExistCheck(submenu, hasSubmenu) {
    return this.sidenavService.sumenuExistCheck(submenu, hasSubmenu);
  }

  sbarSubMenuExistCheck(submenu, hasSubmenu) {
    return this.sidenavService.sidemenuExistCheck(submenu, hasSubmenu);
  }

  settingFeatureCheck(value, hasSubmenu) {
    return this.sidenavService.settingFeatureCheck(value, hasSubmenu);
  }

  portalFeatureCheck(value, hasSubmenu) {
    return this.sidenavService.portalFeatureCheck(value, hasSubmenu);
  }

  loyalityFeatureCheck(value, hasSubmenu) {
    return this.sidenavService.loyalityFeatureCheck(value, hasSubmenu);
  }

  loyalitysumenuExistCheck(submenu, hasSubmenu) {
    return this.sidenavService.loyalitySuMenuChecks(submenu, hasSubmenu);
  }

  loyalityFeatureCheckDropdown(value) {
    return this.sidenavService.loyalityFeatureCheckDropDowns(value);
  }

  isSpacing(value) {
    return this.sidenavService.isSpacing(value);
  }
  // onSideNavClick(){
  //   this.showTitle = !this.showTitle;
  //   document.querySelector('body').classList.add('sideclosed');
  // }
  ngOnDestroy() {
    //this.subscriptionName.unsubscribe();
  }
  navigateTo(path) {
    const sidebarContainer = document.querySelector('._sidenav');
    // sidebarContainer.classList.add('mobilemenu')
    sidebarContainer.classList.remove('collapse_sidenav')
    this.router.navigate([path]);
  }
  onToggle() {
    const sidebarContainer = document.querySelector('._sidenav');
    //sidebarContainer.classList.remove('mobilemenu')
    sidebarContainer.classList.toggle('collapse_sidenav')
    // this.showTitle = !this.showTitle
  }
  navigateBack() {
    let backNavigationPath: string = this.sidenavService.getBackNavigationPath();
    console.log(backNavigationPath)
    this.settingsNav = this.portalNav ? false : true;
    if (this.checkPortalSidebar()) {
      backNavigationPath = '/portal/dashboard/board1'
    }
    // console.log(this.portalNav);
    // console.log(this.settingsNav)
    this.router.navigate([backNavigationPath]);
  }
  isActive(s: string) {
    if (this.router.url.includes(s)) {
      return true;
    }
    else {
      return false;
    }
  }
  isActivePath(s: string) {
    if (this.router.url.startsWith(s))
      return true;
    else
      return false;
  }
  displayRoles(): boolean {
    if (localStorage.getItem('userType') == '1' || localStorage.getItem('userType') == '0') {
      return true;
    } else {
      return false;
    }
  }

  //For Portal Side
  checkPortalSidebar() {
    const value = JSON.parse(localStorage.getItem('isPortalLoggedIn'))
    if (value && value === true && this.router.url.includes('portal')) {
      return true;
    } else {
      return false;
    }
  }

}
