import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonToasterService } from 'src/app/services/common-toaster.service';
import { environment } from 'src/environments/environment';
import { OrderService } from '../../orders/order.service';
import { PaymentGatewayComponent } from '../payment-gateway/payment-gateway.component';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.scss']
})
export class PaymentConfirmComponent implements OnInit {
  modeselect = 0;
  handler: any = null;
  hideShow = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentConfirmComponent>,
    private toaster: CommonToasterService,
    private orderService: OrderService,
    public dialog: MatDialog,
    public router: Router
  ) {
  }

  ngOnInit(): void {
    this.loadStripe();
  }

  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_live_51HcR0mAa9qxelKXvYrgUbeoS16KZUpiBbVOV2JHgFvxgCK66xj2QP5gieVPkA2PDtDPcVMPr1TtNYRGKhSOYhswB00PmHCy3E9',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token)
            // alert('Payment Success!!');
          }
        });
      }
      window.document.body.appendChild(s);
    }
  }

  changeInPaymentType(event) {
    //  this.modeselect = event.value;
  }

  selectedOption(data: any) {
    this.modeselect = data;
    this.hideShow = false;
  }

  confirm() {
    if (this.modeselect === 0) {
      // this.dialog.open(PaymentGatewayComponent, {
      //   width: '500px',
      //   height: '600px',
      //   data: this.data
      // });
      // this.pay('Paid');
      this.post('Card on Delivery');
      this.dialogRef.close();
    } else {
      this.post('Cash on Delivery');
      this.dialogRef.close();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  onClick() {
    this.hideShow = !this.hideShow;
  }

  pay(paymentStatus) {
    var handler = (<any>window).StripeCheckout.configure({
      key: environment.payment_key,
      locale: 'auto',
      currency: 'inr',
      token: (token: any) => {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        console.log(token);
        this.post(paymentStatus);
        // alert('Token Created!!');
      }
    });

    handler.open({
      name: 'Demo Site',
      description: '2 widgets',
      amount: this.data.originalData.grand_total * 100
    });
  }

  post(paymentStatus) {
    if (this.data.isEditData == true) {
      this.data.originalData.payment_status = paymentStatus;
      this.orderService.editOrder(this.data.originalData.uuid, this.data.originalData).subscribe(res => {
        if (res.status === true) {
          this.toaster.showSuccess('Success', res.message);
          this.router.navigate(['portal/order']);
        } else {
          this.toaster.showError('Error', res.message);
        }
      });
    }
    else {
      this.data.originalData.payment_status = paymentStatus;
      this.orderService.addorderList(this.data.originalData).subscribe(res => {
        if (res.status === true) {
          // this.paymentInitiated=true;
          this.toaster.showSuccess('Success', res.message);
          this.router.navigate(['portal/order']);
        } else {
          this.toaster.showError('Error', res.message);
        }
      });
    }
  }
}
