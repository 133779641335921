<section class="statementCls">
  <div class="pl-3">
    <div class="row statementCls1">
      <div class="col-lg-6 col-md-12 col-sm-12">
        <mat-select [formControl]="dateFilter" placeholder="Date Filter" class="form-control form-control-sm">
          <mat-option *ngFor="let item of salesOptions" [value]="item.id">
            <button mat-button>{{ item.label }}</button>
          </mat-option>
        </mat-select>

        <div class="dropDiv">

        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 text-right">
        <div class="text-right">
          <div class="btn-group btnrow mr-2">
            <button class="btn" printSectionId="print-section" printTitle="Statement" [printStyle]="{
                h1: { color: 'red' },
                h2: { border: 'solid 1px' }
              }" [useExistingCss]="true" ngxPrint>
              <i class="fa fa-print iconCl" aria-hidden="true"></i>
            </button>
            <button class="btn" (click)="downloadPdf()">
              <i class="fa fa-file-pdf-o iconCl" aria-hidden="true"></i>
            </button>
          </div>
          <button class="btn btn-primary btn-sm">
            <i class="fa fa-envelope-o" aria-hidden="true"></i> &nbsp;Send Email
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="custmPara text-center">
          <h4>Customer Statement for test test</h4>
          <h5>
            <span>From</span> {{ statement?.accountSummary?.statement_date }}
          </h5>
        </div>
      </div>
    </div>
    <!-- tables -->
    <div class="card border" id="print-section">
      <div class="card-body" [innerHTML]="template"></div>
    </div>

  </div>
</section>