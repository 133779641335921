<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Date Range</label>
        <!-- <div class="col-sm-9" style="display:flex;">
                    <input type="date" formControlName="startdate" class="form-control form-control-sm" placeholder="Start Date">
                   &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
                   <input type="date" formControlName="enddate" class="form-control form-control-sm" placeholder="End Date">
                </div> -->
        <div class="col-sm-9">
          <div class="row">
            <div class="col">
              <input
                type="date"
                formControlName="startdate"
                class="form-control form-control-sm"
                placeholder="Start Date"
              />
            </div>
            <div class="col-1 p-0">
              &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
            </div>
            <div class="col">
              <input
                type="date"
                formControlName="enddate"
                class="form-control form-control-sm"
                placeholder="End Date"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Total Range</label>
        <div class="col-sm-9" style="display: flex;">
          <input
            type="text"
            formControlName="startrange"
            class="form-control form-control-sm"
            placeholder="Start Range"
          />
          &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
          <input
            type="text"
            formControlName="endrange"
            class="form-control form-control-sm"
            placeholder="End Range"
          />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Order NO</label>
        <div class="col-sm-9">
          <input
            type="text"
            formControlName="order_no"
            class="form-control form-control-sm"
            placeholder="Order NO"
          />
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Customer Name</label>
        <div class="col-sm-9">
          <input
            type="text"
            formControlName="customerName"
            class="form-control form-control-sm"
            placeholder="Customer  Name"
          />
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}</label>
        <div class="col-sm-9">
          <mat-select
            class="form-control form-control-sm"
            formControlName="salesman"
          >
            <mat-option *ngFor="let item of salesman" [value]="item.id">
              {{ item.firstname }} {{ item.lastname }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Status</label>
        <div class="col-sm-9">
          <mat-select
            class="form-control form-control-sm"
            formControlName="current_stage"
          >
            <mat-option *ngFor="let item of statusList" [value]="item.id">
              {{ item.name }}
            </mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </div>
</form>
