<section class="">
  <div class="heading-row">

    <h2 class="d-inline "><i class="os-icon os-icon-shopping-cart mr-2"></i>{{ pageTitle }}</h2>

    <div class="d-inline float-right  pl-0">
      <button mat-button class="btn btn-sm btn-white mr-2 dropdown-toggle" [matMenuTriggerFor]="menu2">More</button>
      <mat-menu #menu2="matMenu" xPosition="before">
        <button *ngIf="showConvertToDelivery" mat-menu-item class="height-40"
          (click)="postFinalOrder('orderDelivery')">Convert
          to Delivery</button>
        <button mat-menu-item class="height-40" (click)="goToOrder()">Go to Orders</button>
      </mat-menu>
      <button mat-button class="btn btn-sm btn-secondary" (click)="goBackToOrdersList()"><i
          class="fa fa-angle-left mr-1"></i><span class="back-btn"> Back</span></button>
    </div>
  </div>

  <div class="separator"></div>
  <form [formGroup]="orderFormGroup" class="order-form custom-mat square">
    <!-- <div class="card">
      <div class="card-header overview">
        <div class="row">
          <div class="col-md-4">
            <label for="">Order Type</label>
            <div class="form-group smmatinput">
              <mat-form-field>
                <mat-label>Select Order Type</mat-label>
                <mat-select class="no-disabled-text " id="typeFormField" (ngModelChange)="orderTypeChanged($event)"
                  required [(ngModel)]="selectedOrderTypeId" [ngModelOptions]="{ standalone: true }"
                  [disabled]="isDeliveryForm">
                  <mat-option *ngFor="let order of orderTypes" [value]="order.id">{{ order.name }}</mat-option>
                  <hr class="orderHr">
                  <mat-option id="typeBtn" class="in-opt-btn"><button (click)="addOrderType()"><i
                        class="os-icon os-icon-plus"></i> Add Type</button></mat-option>
                </mat-select>
                <mat-error *ngIf="orderTypeFormControl.hasError('required')">Please select a type</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div> -->


    <!-- <div [ngClass]="{ 'inactive-form': orderTypeFormControl.invalid }" class="card"> -->
    <div class="card">
      <div class="card-body overview">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div *ngIf="!isDepotOrder" class="form-group smmatinput">
              <label for="">Customer</label>
              <div *ngIf="isDeliveryForm"><input type="text" class="form-control form-control-sm non-editable"
                  [value]="orderData.customer.customer_name" disabled></div>
              <div *ngIf="!isDeliveryForm">
                <mat-form-field class="example-full-width" id="customerFormField">
                  <input type="text" placeholder="Search Customer" matInput [matAutocomplete]="auto"
                    [formControl]="customerFormControl" (input)="filterSearchCustomer($event)">
                  <mat-autocomplete appOptionScroll (optionsScroll)="onScroll()"
                    (optionSelected)="getCustomerLobList($event.option.value)" #auto="matAutocomplete"
                    (opened)="checkFormValidation('order')"  required>
                    <mat-option *ngFor="let customer of filterCustomer" [value]="customer">
                      {{ customer.customer_code }} - {{ customer.name }} </mat-option>
                    <hr class="customerHr">
                    <mat-option id="custBtn" class="in-opt-btn"><button (click)="addCustomer()"><i
                          class="os-icon os-icon-plus"></i> Add Customer</button></mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="customerFormControl.hasError('required')">Please select a customer</mat-error>
                </mat-form-field>
                <div class="row mt-3">
                  <div *ngIf="is_lob" class="col">
                    <div class="form-group row mb-3 m-0 p-0">
                      <label> Customer Lob:</label>
                      <div class="col-11 p-0">
                        <app-multiautocomplete isSingle="true" [options]="customerLobList"
                          [control]="customerLobFormControl" text="name" id="lob_id" placeholder="Select Lob"
                          objectName="lob" param1="name" param2="">
                        </app-multiautocomplete>
                        <mat-error *ngIf="customerLobFormControl.hasError('required')">Please select a Customer Lob
                        </mat-error>
                      </div>
                      <!-- <div class="col-1 p-2 mt-2">
                              <a (click)="openItemLob()" class="ml-2 settingIcon"><i
                                  class="os-icon os-icon-ui-46" aria-hidden="true"></i></a>
                          </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group smmatinput" *ngIf="isCustomerlobShow">
              <label for="">Warehouse</label>
              <div>
                <app-multiautocomplete isSingle="true" [options]="storageLocationList" [control]="warehouseFormControl"
                  text="name" id="id" placeholder="Select Warehouse" objectName="customer-root-ddl" [param1]="'code'"
                  [param2]="'name'">
                </app-multiautocomplete>
              </div>
            </div>
            <div *ngIf="isDepotOrder" class="form-group smmatinput">
              <label for="">Depot</label>
              <div>
                <mat-form-field id="depotFormField">
                  <mat-label>Select Depot</mat-label>
                  <mat-select class="no-disabled-text" (blur)="checkFormValidation('order')" required
                    (ngModelChange)="depotChanged($event)" [formControl]="depotFormControl"
                    [(ngModel)]="selectedDepotId" [disabled]="isDeliveryForm">
                    <mat-option *ngFor="let depot of depots" [value]="depot.id">{{ depot.depot_name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="depotFormControl.hasError('required')">Please select a depot</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group smmatinput">
              <label for="">{{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}</label>
              <div>
                <!-- <mat-form-field id="salesmanFormField">
                  <mat-label>Select {{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}
                  </mat-label>
                  <mat-select class="no-disabled-text" (blur)="checkFormValidation('order')"
                    [formControl]="salesmanFormControl">
                    <mat-option *ngFor="let sman of salesmen" [value]="sman.user.id">
                      {{ sman.user ? sman.user.firstname + ' ' + sman.user.lastname : '' }} - {{ sman.salesman_code }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="salesmanFormControl.hasError('required')">Please select a salesman</mat-error>
                </mat-form-field> -->
                <app-multiautocomplete isSingle="true" [options]="salesmen" [control]="salesmanFormControl" text="user"
                  id="user_id" placeholder="Select Salesman" objectName="user" param1="firstname" param2="lastname">
                </app-multiautocomplete>
              </div>
            </div>
            <div class="form-group smmatinput">
              <label for="">Customer LPO</label>
              <div>
                <input type="text" id="customeLPO" class="form-control form-control-sm"
                  [formControl]="customerLPOFormControl" />
              </div>
            </div>

          </div>

          <div class="col-md-4 ">
            <div class="form-group">
              <label for="">Order Number</label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="orderNumber"
                  [ngModelOptions]="{standalone: true}" (change)="setOrderNumber($event.target.value)" maxlength="10"
                  [disabled]="(isEditForm || this.isDeliveryForm || nextCommingNumberofOrderCode != '')" />
                <span class="setting-btn float-right"><button (click)="openNumberSettings()"><i
                      class="os-icon os-icon-settings"></i></button></span>
              </div>

            </div>
            <div class="form-group">
              <label for="">Delivery Date</label>
              <input [min]="todayDate" type="date" class="form-control form-control-sm" required
                [formControl]="deliveryDateFormControl" id="deliveryDate">
            </div>
            <div class="form-group smmatinput">
              <label for="">Payment Terms</label>
              <mat-form-field>
                <mat-label>Select Payment Term</mat-label>
                <mat-select class="no-disabled-text" required id="termFormField"
                  (ngModelChange)="payTermChanged($event)" [(ngModel)]="selectedPaymentTermId"
                  [ngModelOptions]="{ standalone: true }" [disabled]="isDeliveryForm">
                  <mat-option *ngFor="let term of terms" [value]="term.id">{{ term.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentTermFormControl.hasError('required')">Please select a payment term</mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label for="">Due Date</label>
              <input type="date" [min]="dueDateSet" [formControl]="dueDateFormControl" required
                class="form-control form-control-sm" id="dueDate">
            </div>
          </div>



        </div>
      </div>

      <div class="bottom-form">
        <div class="item-container custom-mat-no-border cform1"
          [ngClass]="{ 'inactive-form': isDepotOrder ? depotFormControl.invalid : customerFormControl.invalid }">

          <button class="btn btn-sm filter-btn" [matMenuTriggerFor]="menu" style="height: 30px;"><i
              class="fa fa-filter"></i></button>
          <mat-menu #menu="matMenu">
            <div class="card-body p-2" style="min-width: 200px;">
              <ul style="list-style:none; padding-left: 7px; margin-bottom: 0;">
                <li *ngFor="let header of itemTableHeaders.slice(5, 9)">
                  <input type="checkbox" style="margin: 2px" [(ngModel)]="header.show"
                    (click)="$event.stopPropagation()" [ngModelOptions]="{ standalone: true }">
                  {{ header.label }}
                </li>
              </ul>
              <div class="float-right mb-2">
                <button mat-button class="btn mat-inherit-line-height filter-cancel-btn">Cancel</button>
              </div>
            </div>
          </mat-menu>
          <div class="item-table-div">
            <div class="table-responsive">
              <table class="item-table cardtable table ">
                <thead>
                  <tr>
                    <th class="head" *ngFor="let head of filteredTableHeaders">{{ head.label }}</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="form-body">
                  <tr formArrayName="items" class="item-row" *ngFor="let item of itemFormControls; let i = index;">
                    <ng-container [formGroupName]="i">
                      <td data-th="#" class="item-sequence" *ngIf="isEditForm">
                        <span>{{ i + 1 }}</span>
                        <input [ngClass]="getItemStatus(item) ? 'notAvaliable' : ''"
                          (change)="itemDidSelected($event, payloadItems[i])" [disabled]="getItemStatus(item)"
                          type="checkbox" name="item">
                      </td>
                      <td class="item-sequence" *ngIf="!isEditForm">
                        <span>{{ i + 1 }}</span>
                        <input (change)="itemDidSelected($event, payloadItems[i])" type="checkbox" name="item">
                      </td>
                      <td data-th="Item Code" *ngIf="isDeliveryForm"><input type="text"
                          class="form-control form-control-sm non-editable item-name"
                          [value]="payloadItems[i].item.name" disabled></td>
                      <td data-th="Item Code" class="item-name" *ngIf="!isDeliveryForm">
                        <mat-form-field class="item-name-field">
                          <input type="text" required placeholder="Search an item" matInput formControlName="item"
                            [matAutocomplete]="auto" (keyup)="fiterItems($event.target.value)">
                          <mat-autocomplete appOptionScroll #auto="matAutocomplete"
                            (opened)="checkFormValidation('order')"
                            (optionSelected)="itemDidSearched($event.option.value, i)"
                            [displayWith]="itemsControlDisplayValue">
                            <mat-option class="mat-option" *ngFor="let item of filteredItems"
                              [value]="itemControlValue(item)">
                              {{item.item_code}} - {{ item.item_name }} </mat-option>
                            <mat-option *ngIf="isLoading">
                              <div class="select-placeholder-container">
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                              </div>
                            </mat-option>
                            <hr class="customerHr">
                            <mat-option id="custBtn" class="in-opt-btn"><button (click)="redirectToItem()"><i
                                  class="os-icon os-icon-plus"></i> Add Item</button></mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </td>
                      <td data-th="Item Name" *ngIf="isDeliveryForm"><input type="text"
                          class="form-control form-control-sm non-editable item-name" formControlName="item_name"
                          disabled></td>
                      <td data-th="Item Name" class="item-name" *ngIf="!isDeliveryForm">
                        <input type="text" class="form-control form-control-sm non-editable item-name"
                          formControlName="item_name" disabled>
                      </td>
                      <!-- <td class="uom-td" *ngIf="isDeliveryForm"><input type="text" class="form-control form-control-sm non-editable" [value]="getUomValue(payloadItems[i])" disabled></td> -->
                      <td data-th="UOM" *ngIf="isDeliveryForm"><input type="text"
                          class="form-control form-control-sm non-editable uom-td"
                          [value]="item.value.item_uom_list[i]?.name" disabled></td>
                      <td data-th="UOM" class="uom-td" *ngIf="!isDeliveryForm">
                        <select class="custom-select" [attr.disabled]="item?.value?.is_free || null" required
                          formControlName="item_uom_id">
                          <option [value]="uom?.id" *ngFor="let uom of item?.value?.item_uom_list">{{ uom?.name }}
                          </option>
                        </select>
                      </td>
                      <td data-th="Quantity" class="stats-cell">
                        <input type="number" min="0" class="form-control" placeholder="Quantity"
                          [readonly]="item?.value?.is_free" formControlName="item_qty">
                      </td>
                      <td data-th="Price" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_price)" disabled></td>
                      <td data-th="Excise" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_excise)" disabled></td>
                      <td data-th="Discount" *ngIf="itemTableHeaders[5]?.show" class="stats-cell"><input type="text"
                          class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_discount_amount)" disabled>
                      </td>
                      <td data-th="Net" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_net)" disabled>
                      </td>
                      <td data-th="Vat" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_vat)" disabled>
                      </td>


                      <td data-th="Total" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_grand_total)" disabled></td>
                      <td data-th="Action"> <button class="trash-btn" (click)="deleteItemRow(i)"><i
                            class="fa fa-close"></i></button></td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>
            </div>
            <button mat-button class="mat-inherit-line-height add-item-btn" [disabled]="isDeliveryForm"
              (click)="addItem()">
              Add Item
            </button>
            <button mat-button class="mat-inherit-line-height add-item-btn" style="margin-left: 5px;"
              (click)="openBulkItemSelectionPopup()">
              Add Bulk Item
            </button>
          </div>

        </div>
        <div class=" footer-overview-container">
          <div class="row justify-content-between">
            <div class="form-group col-sm-5">
              <label for="note">Customer Note</label>
              <textarea class="form-control form-control-sm" [formControl]="noteFormControl" id="note"></textarea>
            </div>
            <div class=" col-sm-4">
              <div class="overall-stats-table">
                <div *ngFor="let stat of objectValues(orderFinalStats)">
                  <span class="stat-label">{{ stat.label }}</span>
                  <!-- <span
                    class="stat-value">{{ number_format(stat.value) | currency: currencyCode:"symbol" }}</span> -->
                  <span class="stat-value">{{ numberFormatWithSymbol(stat.value) }}</span>
                </div>
                <div>
                  <span class="stat-label final-total">{{ orderFinalStats?.grand_total.label }}</span>
                  <span class="stat-value final-total">{{ numberFormatWithSymbol(orderFinalStats?.grand_total.value)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="actions">
    <button class="btn btn-primary" [disabled]="isDeliveryForm" (click)="postFinalOrder('order')">Save & Submit</button>
  </div>
</section>