import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preference-right-panel',
  templateUrl: './preference-right-panel.component.html',
  styleUrls: ['./preference-right-panel.component.scss']
})
export class PreferenceRightPanelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {}

}
