<mat-tab-group>
    <mat-tab label="Overview">
        <app-portal-info-overview [customer]="customer" [lobInfo]="lobInfo"></app-portal-info-overview>
    </mat-tab>
    <mat-tab label="Sales">
        <app-portal-info-sales [customer]="customer" [lobInfo]="lobInfo"></app-portal-info-sales>
    </mat-tab>
    <mat-tab label="Statement">
        <app-portal-info-statement [customer]="customer" [lobInfo]="lobInfo"></app-portal-info-statement>
    </mat-tab>
</mat-tab-group>