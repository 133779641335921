<div class="payment-confir-box">
  <div class="payment-confirm">
    <div class="confirm-title">
      <h3>Payment Confirmation</h3>
    </div>
    <div class="payment-select-option">
      <h4>Payment Type:-</h4>
      <!-- <mat-form-field appearance="outline">
                <mat-select placeholder="Payment" [(ngModel)]="modeselect" (selectionChange)="changeInPaymentType($event)">
                  <mat-option value='0'>Online Payment</mat-option>
                  <mat-option value='1'>Cash On Delivery</mat-option>
                </mat-select>
              </mat-form-field> -->
      <!-- <form>
                <ul class="select-custome-box">
                  <li>
                    <input class="select_close" type="radio" name="awesomeness" id="awesomeness-close" value=""/>
                    <span class="select_label select_label-placeholder">Awesomeness Level</span>
                  </li>
                </ul>
                <ul class="select">
                 
                  
                  <li class="select_items">
                    <input class="select_expand" type="radio" name="awesomeness" id="awesomeness-opener"/>
                    <label class="select_closeLabel" for="awesomeness-close"></label>
                    
                    <ul class="select_options">
                      <li class="select_option">
                        <input class="select_input" type="radio" name="awesomeness" id="awesomeness-ridiculous"/>
                        <label class="select_label" for="awesomeness-ridiculous">ridiculous</label>
                      </li>
              
                      <li class="select_option">
                        <input class="select_input" type="radio" name="awesomeness" id="awesomeness-reasonable"/>
                        <label class="select_label" for="awesomeness-reasonable">reasonable</label>
                      </li>
              
                     
                    </ul>
                    
                    <label class="select_expandLabel" for="awesomeness-opener"></label>
                  </li>
                </ul>
                
              </form> -->
      <div class="form-option">
        <div class="custom-select-option">
          <div class="custome-selectbox-box" (click)="onClick()">
            <!-- <span>{{modeselect === 0 ?'Online Payment':'Cash On delivery'}}</span> -->
            <span>{{modeselect === 0 ?'Card On Delivery':'Cash On Delivery'}}</span>
            <a><mat-icon *ngIf="hideShow === false">keyboard_arrow_down</mat-icon></a>
            <a><mat-icon *ngIf="hideShow === true">keyboard_arrow_up</mat-icon></a>
          </div>
          <div class="option-menu-select" *ngIf="hideShow">
            <ul>
              <!-- <li><a type="button" (click)="selectedOption(0)">Online Payment</a></li> -->
              <li><a type="button" (click)="selectedOption(1)">Cash On Delivery</a></li>
              <li><a type="button" (click)="selectedOption(0)">Card On Delivery</a></li>
            </ul>
          </div>
        </div>

      </div>




    </div>
    <div class="payment-confirm-btn">
      <button (click)="cancel()">
        Cancel
      </button>
      <button (click)="confirm()">
        Confirm
      </button>
    </div>
  </div>
</div>