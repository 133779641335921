import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { Customer } from 'src/app/components/main/transaction/collection/collection-models';
import { customerData } from 'src/app/features/shared/mock-data/mock-data.component';
import { PortalInfoService } from '../../portal-info.service';

@Component({
  selector: 'app-portal-info-statement',
  templateUrl: './portal-info-statement.component.html',
  styleUrls: ['./portal-info-statement.component.scss']
})
export class PortalInfoStatementComponent implements OnInit {
  @Input() public customer: any;

  public statusSelected: any;
  public showStatus: boolean = false;

  public FILTER_WEEK = 'week';
  public FILTER_MONTH = 'month';
  public FILTER_QUARTER = 'quarter';
  public FILTER_YEAR = 'year';
  public FILTER_PREVIOUS_MONTH = 'previous';

  public salesOptions: any = [
    { id: this.FILTER_WEEK, label: 'This Week' },
    { id: this.FILTER_MONTH, label: 'This Month' },
    { id: this.FILTER_QUARTER, label: 'This Quarter' },
    { id: this.FILTER_YEAR, label: 'This Year' },
    { id: this.FILTER_PREVIOUS_MONTH, label: 'Previous Month' },
  ];
  public filterSelected: any;
  public showFilter: boolean = false;
  public statement;
  public startOfMonth;
  public endOfMonth;
  public dateFilter: FormControl = new FormControl();
  template: any;
  //customer = customerData;
  @Input() public lobInfo: any;
  constructor(private sanitizer: DomSanitizer, private infoService: PortalInfoService) { }

  ngOnInit(): void {
    this.dateFilter.setValue(this.FILTER_MONTH);
    this.getStartAndEndDate(this.FILTER_MONTH);
    this.getStatementData();
    this.onChangeDateFilter();
  }

  downloadPdf() {
    this.infoService
      .downloadPdf({
        customer_id: this.customer?.user_id,
        startdate: this.startOfMonth,
        enddate: this.endOfMonth,
        lob_id: this.lobInfo?.lob_id || 0,
        status: 'pdf',
      })
      .subscribe((res: any) => {
        if (res.status) {
          const link = document.createElement('a');
          link.setAttribute('target', '_blank');
          link.setAttribute('href', `${res.data.file_url}`);
          link.setAttribute('download', `statement.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      });
  };

  getStatementData = () => {
    this.infoService
      .getStatement({
        customer_id: this.customer?.user_id,
        startdate: this.startOfMonth,
        enddate: this.endOfMonth,
        lob_id: this.lobInfo?.lob_id || 0,
      })
      .subscribe((res: any) => {
        if (res.status) {
          this.statement = res.data;
          this.customer.trn_no = res.data.userDetails?.customer_info?.trn_no;
          this.template = this.sanitizer.bypassSecurityTrustHtml(
            this.statement.html_string
          );
        }
      });
  };

  onChangeDateFilter = () => {
    this.dateFilter.valueChanges.subscribe((item) => {
      this.getStartAndEndDate(item);
      this.getStatementData();
    });
  };

  getStartAndEndDate = (type) => {
    console.log(type);
    if(type == 'previous'){
      const date = new Date();
      this.startOfMonth = moment(date).startOf('months').subtract(1, 'months').format('YYYY-MM-DD');
      this.endOfMonth = moment(date).endOf('months').subtract(1, 'months').format('YYYY-MM-DD');
    }else{
      this.startOfMonth = moment().startOf(type).format('YYYY-MM-DD');
      this.endOfMonth = moment().endOf(type).format('YYYY-MM-DD');
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.customer.firstChange) {
      this.dateFilter.setValue(this.FILTER_MONTH);
      this.getStartAndEndDate(this.FILTER_MONTH);
      this.getStatementData();
    }
  }
}