<div class="container p-0 m-0" style="width:100%;">
    <!-- <h2>Payment</h2> -->
    <div class="panel panel-default">
      <div class="panel-heading">Enter Card Details</div>
      <div class="panel-body">
        <div class="right-payment-sec">
          <form class="payment-details-form" [formGroup]="paymentDetailForm">        
            <div class="row px-3 pt-4 pb-0">
                <div class="col-lg-12 col-xs-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1 pb-1">Name On Card</label>
                        <input type="text" class="form-control mt-1" formControlName="nameOnCard"  placeholder="Name On Card">        
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('nameOnCard')?.touched || isSubmitted) && paymentDetailForm.get('nameOnCard')?.hasError('required')">
                            Please Enter Name On Card
                        </div>           
                    </div>
                </div>
                <div class="col-lg-7 col-xs-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Card Number</label>
                        <input type="text" class="form-control mt-1" formControlName="cardNumber" 
                        [textMask]="{mask: cardNumber, guide: false, showMask: true}"
                        placeholder="XXXX-XXXX-XXXX-XXXX">
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('cardNumber')?.touched || isSubmitted)">
                            <span *ngIf="paymentDetailForm.get('cardNumber')?.hasError('required')">Please Enter Card Number</span>
                            <span *ngIf="paymentDetailForm.get('cardNumber')?.hasError('validateCard') && !paymentDetailForm.get('cardNumber')?.hasError('required')">Please Enter Valid Card number</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 xol-sm-5 col-xs-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">CVV</label>
                        <input type="text" #input1 class="form-control mt-1" formControlName="cvv" placeholder="CVV"
                        [textMask]="{mask: cvvNumber, guide: false, showMask: false}" (input)="onInputEntry($event, input2, 3)">   
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('cvv')?.touched || isSubmitted) && paymentDetailForm.get('cvv')?.hasError('required')">
                            Please Enter CVV
                        </div>                
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 xol-sm-6 col-xs-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Expiration Month</label>
                        <input type="text" #input2 class="form-control mt-1" formControlName="month" placeholder="MM" 
                        [textMask]="{mask: yearNumber, guide: false, showMask: false}"  (input)="onInputEntry($event, input3, 2)">
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('month')?.touched || isSubmitted) && paymentDetailForm.get('month')?.hasError('required')">
                            Please Enter Expiration Month
                        </div>
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('month')?.touched || isSubmitted) && paymentDetailForm.get('month')?.hasError('validateMonth') && !paymentDetailForm.get('month')?.hasError('required')">
                            Please Enter Valid Expiration Month
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 xol-sm-6 col-xs-12">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Expiration Year</label>
                        <input type="text" #input3 class="form-control mt-1" formControlName="year" placeholder="YY"
                        [textMask]="{mask: yearNumber, guide: false, showMask: false}">
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('year')?.touched || isSubmitted) && paymentDetailForm.get('year')?.hasError('required')">
                            Please Enter Expiration Year
                        </div>
                        <div class="text-danger" *ngIf="(paymentDetailForm.get('year')?.touched || isSubmitted) && paymentDetailForm.get('year')?.hasError('validateYear') && !paymentDetailForm.get('year')?.hasError('required')">
                            Please Enter Valid Expiration Year
                        </div>
                    </div>
                </div> 
                
    
                <div class="col-lg-12 col-md-12 xol-sm-12 col-xs-12  pt-3 mt-3">
                  <div class="form-group opt-btn d-flex justify-content-between px-5">
                    <button type="submit" class="btn btn-primary" (click)="cancel()">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="confirmPayment()">Confirm</button>
                  </div>
                </div>
              </div>
            </form>
        </div>
      </div>
    </div>
  </div>