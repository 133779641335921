<div style="padding:10px;">

    <form [formGroup]="emailForm" (ngSubmit)="onSendEmail()">
        <div class="form-group row">
            <label for="inputEmail3" class="col-sm-3 col-form-label">To:</label>
            <div class="col-sm-9">
                <input type="text" formControlName="to_email" class="form-control">
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-3 col-form-label">Subject:</label>
            <div class="col-sm-9">
                <textarea formControlName="subject" class="form-control" cols="30" rows="1"
                    style="    min-height: 64px;"></textarea>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPassword3" class="col-sm-3 col-form-label"></label>
            <div class="col-sm-9">
                <textarea formControlName="message" class="form-control" cols="30" rows="4"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <div class="offset-sm-3 col-sm-9" style="display: flex;">
                <button [disabled]="emailForm.invalid" style="width:60px;border-radius: 2px;" type="submit"
                    class="btn btn-primary">Send</button>
                &nbsp;
                <button style="width:60px;border-radius: 2px;" type="button" class="btn btn-warning"
                    (click)="onClose()">Close
                </button>
            </div>
        </div>
    </form>

</div>