<mat-drawer-container class="formdrawer-container" hasBackdrop="false">
  <mat-drawer id="formDrawer" mode="over" position="end" #formDrawer class="border">
      <div style="min-width: 300px;">
          <app-invite-user  [roleData]="roleDataSource.filteredData" *ngIf="drawerName === 'invite-user'" (isReload)="reloadPage($event)"></app-invite-user>
          <app-role-access *ngIf="drawerName === 'role-access'"></app-role-access>
      </div>
  </mat-drawer>
<section>
    <div class="table-box position-relative" [ngClass]="{ 'collapsed': isDetailVisible }">
        <mat-tab-group (selectedTabChange)="tabChange($event)">
            <mat-tab label="All Users">
                <div>
                    <!-- User Detail -->

                    <div class="table-responsive" style="height: 550px;">
                      <table class="table table-hover" mat-table [dataSource]="userDataSource">
                        <ng-container matColumnDef="user_details">
                          <th mat-header-cell *matHeaderCellDef  class="user-details"> User Details </th>
                          <td mat-cell *matCellDef="let element"  class="user-details">
                            <div class="d-flex">
                              <div class="p-2">
                                <img src="assets/images/user.png" alt="" class="img-rounded img-sm">
                              </div>
                              <div class="p-2">
                                <span> {{element?.firstname}}
                                  (Active)</span><br>
                                <span>{{element?.email}}</span>
                              </div>
                            </div>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="role">
                          <th mat-header-cell *matHeaderCellDef> Role </th>
                          <td mat-cell *matCellDef="let element"> {{element?.role_name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let element"><a (click)="$event.stopPropagation()"><i class="fa fa-lg fa-cog"
                                aria-hidden="true"></i></a> </td>
                        </ng-container>
                    
                        <tr mat-header-row *matHeaderRowDef="userTableColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: userTableColumns;" (click)="openDetailView(row)"></tr>
                      </table>
                    </div>
                    
                    <div [ngClass]="{'hidden':userDataSource.data.length < 10}">
                      <mat-paginator pageSize=10 [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
                    </div>

                </div>
            </mat-tab>


            <mat-tab label="Roles">
                <!-- User Role Detail -->

                <div class="table-responsive">
                  <table class="table table-hover" mat-table [dataSource]="roleDataSource">
                  <ng-container matColumnDef="role_name">
                    <th mat-header-cell *matHeaderCellDef> Role Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef> Description</th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> Actions </th>
                    <td mat-cell *matCellDef="let element">
                      <button *ngIf="element.id !== 61" (click)="openCloneRole(element)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="roleTableColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: roleTableColumns;"></tr>
                </table>
                </div>
                <div class="hidden">
                  <mat-paginator pageSize=10 [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
                </div>

            </mat-tab>
        </mat-tab-group>
        
        <!-- <button *ngIf="!isDetailVisible && roles" class="btn btn-p invite-btn">New
            Role</button> -->
    </div>
    <button *ngIf="!isDetailVisible" class="btn btn-p invite-btn" (click)="openAddUser()">Invite User</button>
    <div class="details-box" [ngClass]="{ 'expanded': isDetailVisible }">
      <app-user-details  (detailsClosed)="closeClicked()" [isDetailVisible]="isDetailVisible" [user]="user">
      </app-user-details>
    </div>
</section>
</mat-drawer-container>