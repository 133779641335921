import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { DataEditor } from 'src/app/services/data-editor.service';

@Component({
  selector: 'sync-form-dailog-dialog',
  templateUrl: './sync-form-dailog.component.html',
  styleUrls: ['./sync-form-dailog.component.scss'],
  providers: [DatePipe]
})
export class SyncFormDialogComponent implements OnInit {

  pipe = new DatePipe('en-US');
  public exportForm: FormGroup;
  public export: any = {};
  private apiService: ApiService;
  private datePipe: DatePipe
  public title: any;
  typevalue: any;
  constructor(datePipe: DatePipe,
    apiService: ApiService,
    private dataEditor: DataEditor) {
    Object.assign(this, { apiService });
  }

  ngOnInit(): void {

    this.dataEditor.syncDialogTitle.subscribe(x => {
      this.title = x;
    });

    this.exportForm = new FormGroup({
      type: new FormControl('1'),
      start_date: new FormControl(''),
      end_date: new FormControl('')
    });
    this.exportForm.controls.type.valueChanges.subscribe(res => {
      console.log(res)
      this.typevalue = res;
      if (res == '0') {
        this.exportForm.controls.start_date.setValidators([Validators.required]);
        this.exportForm.controls.end_date.setValidators([Validators.required])
      } else {
        this.exportForm.controls.start_date.setValidators([]);
        this.exportForm.controls.end_date.setValidators([]);
      }
    });
  }

  syncForm() {
    console.log(this.exportForm);
    this.apiService
      .syncRecords(this.title, this.exportForm.value)
      .subscribe(
        (result: any) => {
          if (result.status) {
            location.reload();
            //console.log(result);	
            // this.apiService.syncRecords(result.data.file_url, type);
          }
        }
      );
  }

}
