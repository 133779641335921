<div class="card" style="width: 550px;">
  <div class="card-header pt-4">
    <span class="h6 font-weight-light">{{formType}} Expense</span>
    <a (click)="close()"><i class="fa fa-times fa-lg text-secondary float-right" aria-hidden="true"></i></a>
  </div>
  <div class="form-box">
    <form [formGroup]="expenseFormGroup">
      <div class="card-body">
        <div class="">
          <div class="row mb-4">
            <div class="col-md-4 markred">Date*</div>
            <div class="col-md-8">
              <input class="form-control form-control-sm" type="Date" [formControl]="DateFormControl">
              <!-- <a (click)="open()" class="ml-2 settingIcon"><i class="os-icon os-icon-ui-46" aria-hidden="true"></i></a> -->
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4 markred">Expense Category*</div>
            <div class="col-md-8">
              <!-- <input class="form-control form-control-sm" type="text" [formControl]="categoryFormControl"> -->
              <mat-select [formControl]="categoryFormControl" class="form-control form-control-sm">
                <mat-option *ngFor="let item of expenseCategiry" [value]="item.id">
                  {{item.name}}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4 markred">Customer*</div>
            <div class="col-md-8">
              <!-- <input class="form-control form-control-sm" type="text" [formControl]="categoryFormControl"> -->
              <mat-select [formControl]="customerFormControl" class="form-control form-control-sm">
                <mat-option *ngFor="let item of customer" [value]="item.user_id">
                  {{item.user?.firstname}} - {{ item.customer_code }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4 markred">Amount*</div>
            <div class="col-md-8">
              <input class="form-control form-control-sm" type="text" [formControl]="amoounteFormControl">
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4">Reference</div>
            <div class="col-md-8">
              <input class="form-control form-control-sm" type="text" [formControl]="referenceFormControl">
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-md-4">Note</div>
            <div class="col-md-8">
              <input class="form-control form-control-sm" style="height: 86px;" type="text"
                [formControl]="noteFormControl">
            </div>
          </div>

        </div>
      </div>
    </form>

  </div>


  <div class="card-footer">
    <div class="d-inline float-right">
      <button class="btn btn-sm btn-light mr-2" (click)="cancel()">Cancel</button>
      <button class="btn btn-sm btn-p" (click)="saveBankData()">Save</button>
    </div>
  </div>
</div>