<section class="scrolly">
  <ng-container *ngIf="advanceSearchRequest.length>0">
    <app-search-criteria [data]="advanceSearchRequest" (change)="onChangeCriteria()" (close)="onCloseCriteria()">
    </app-search-criteria>
  </ng-container>
  <div class="table-responsive">
    <table class="table table-hover" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <button class="btn btn-sm filter-btn" *ngIf="!isDetailVisible" [matMenuTriggerFor]="menu"
            style="height: 30px;">
            <i class="fa fa-table" aria-hidden="true"></i>
          </button>
          <mat-menu #menu="matMenu">
            <div class="card-body p-2" style="min-width: 200px;">
              <ul style="list-style: none; padding-left: 7px;">
                <li *ngFor="let column of filterColumns">
                  <input type="checkbox" [(ngModel)]="column.show" class="m-2" (click)="$event.stopPropagation()" />
                  {{ column.title }}
                </li>
              </ul>
              <div class="float-right mb-2">
                <button class="btn btn-p bg-secondary">Cancel</button>
              </div>
            </div>
          </mat-menu>
          <mat-checkbox class="head-checkbox" (change)="$event ? toggleSelection() : null" (change)="getSelectedRows()"
            [checked]="selections.hasValue() && isAllSelected()"
            [indeterminate]="selections.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
            (change)="$event ? selections.toggle(row) : null" (change)="getSelectedRows()"
            [checked]="selections.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Credit Note Date" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="date" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('date')"
              placeholder="Enter Credit Note Date" type="date">
          </app-column-filter>

        </th>
        <td mat-cell *matCellDef="let element">{{ element.created_at | date :"d MMM y h:mm a" }}</td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Code" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="credit_note_number" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('credit_note_number')"
              placeholder="Enter Code" type="text">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.credit_note_number }}
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <!-- Customer -->
          <app-column-filter title="Customer Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="customer_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_name')"
                placeholder="Enter Customer Name" type="text" />
            </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{
          element.customer
          ? element.customer.firstname + " " + element.customer.lastname
          : "N/A"
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customerCode">
        <th mat-header-cell *matHeaderCellDef>
          <!-- Customer Code -->
          <app-column-filter title="Customer Code" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="customer_code" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_code')"
              placeholder="Enter Customer Code" type="text" />
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{element.customer?.customer_info?.customer_code}}
        </td>
      </ng-container>
      <!--  Route Code-->
      <ng-container matColumnDef="route_code">
        <th mat-header-cell *matHeaderCellDef>

          <app-column-filter title="Route Code" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="route_code" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('route_code')"
              placeholder="Enter Route Code" type="text">
          </app-column-filter>

        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.route?.route_code }}</td>
      </ng-container>
      <!--  End Route code -->
      <!-- Route Name -->
      <ng-container matColumnDef="route_name">
        <th mat-header-cell *matHeaderCellDef>

          <app-column-filter title="Route Name" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="route_name" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('route_name')"
              placeholder="Enter Route Name" type="text">
          </app-column-filter>

        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.route?.route_name }}</td>
      </ng-container>
      <!-- End Route Name -->
      <!-- Salesman Code -->
      <ng-container matColumnDef="salesman_code">
        <th mat-header-cell *matHeaderCellDef>

          <app-column-filter title="Salesman Code" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="salesman_code" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('salesman_code')"
              placeholder="Enter Salesman Code" type="text">
          </app-column-filter>

        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.salesman?.salesman_info?.salesman_code }}</td>
      </ng-container>
      <!-- End Salesman Code   -->
      <!-- Salesman Name -->
      <ng-container matColumnDef="salesman_name">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Salesman Name" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="salesman_name" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" [formControl]="filterForm.get('salesman_name')"
              placeholder="Enter Salesman Name" type="text">
          </app-column-filter>

        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            {{
            element?.salesman == null ? '' :
            element.salesman?.firstname + " " + element.salesman?.lastname
            }}
          </ng-container>
        </td>
      </ng-container>
      <!-- End Salesman Name -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Credit Amount</th>
        <td mat-cell *matCellDef="let element">
          {{ numberFormatWithSymbol(element.grand_total) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="approval">
        <th mat-header-cell *matHeaderCellDef>approval
          <!-- <app-column-filter title="Approval" controlName="approval" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" [formControl]="filterForm.get('approval')"
            (close)="onColumnFilter($event)">
            <mat-select class="form-control form-control-sm">
              <mat-option value="1">Pending</mat-option>
              <mat-option value="0">Active</mat-option>
            </mat-select>
          </app-column-filter> -->
        </th>
        <td mat-cell *matCellDef="let element">
          <span style="color: #0f8801;" *ngIf="element?.status">Approved</span>
          <span style="color: #a5621b;" *ngIf="!element?.status">Pending</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status
          <!-- <app-column-filter title="Status" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="current_stage" (close)="onColumnFilter($event)">
             <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('current_stage')">
              <mat-option value="1">Pending</mat-option>
              <mat-option value="0">Active</mat-option>
            </mat-select>
          </app-column-filter> -->
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.approval_status;">
            <span>
              {{ element.approval_status }}
            </span>
          </ng-container>

        </td>
      </ng-container>
      
      <ng-container matColumnDef="payment_status">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Payment Status" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" controlName="payment_status" (close)="onColumnFilter($event)">
            <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('payment_status')">       
              <mat-option value="0">Paid</mat-option>
              <mat-option value="1">Un-paid</mat-option>
              <mat-option value="2">Partial-paid</mat-option>
            </mat-select>
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{getPaymentStatus(element)}}
        </td>
      </ng-container>
      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef style="display: none;">Detail</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex; justify-content: space-between;">
            <strong>{{
              element.customer
              ? element.customer.firstname + " " + element.customer.lastname
              : "N/A"
              }}</strong>
            <span>
              {{ numberFormatWithSymbol(element.grand_total) }}</span>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <span style="color: #2485e8;">{{ element.credit_note_number }}</span>
            <span style="color: #2485e8;">
              {{ element.credit_note_date | date }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="ERP_status">
        <th class="erp-status" mat-header-cell *matHeaderCellDef>
          <app-column-filter title="ERP Status" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)" controlName="erp_status">
            <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('erp_status')">
              <mat-option value="Not Posted">Not Posted</mat-option>
              <mat-option value="Posted">Posted</mat-option>
              <mat-option value="Failed">Failed</mat-option>
            </mat-select>
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.erp_id == null || element.erp_id == 0 && !element.erp_status && element.is_sync == 0? 'Not
          Posted': ''}}
          {{ element.erp_status =='Pass' && element.is_sync == 1 ? 'Posted': ''}}
          {{ element.erp_status && element.erp_status != 'Pass' ? 'Failed': ''}}

          <i *ngIf="element.erp_status &&  element.erp_status  != 'Pass'" class="fa fa-info-circle" aria-hidden="true"
            (click)="filterOdooMessageData(element.erp_status);isOdooMessageOpen=true">
          </i>
          <ng-container *ngIf="!isSyncDialogShow">
            <i class="fa fa-refresh" style="margin-left: 4px;"
              *ngIf="element.is_sync == 0 && element.current_stage ==='Approved'"
              (click)="postOdoo(element);isOdooMessageOpen=true"></i>
          </ng-container>


        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" [ngClass]="{ 'misc-row': isDetailVisible }"
        (click)="openDetailView(row);isOdooMessageOpen=false"></tr>
    </table>
    <mat-paginator [hidden]="apiResponse.pagination?.total_records < 10" (page)="onPageFired($event)"
      [length]="apiResponse.pagination?.total_records" [pageSize]="pageSize" [pageSizeOptions]="paggingArray">
    </mat-paginator>
  </div>


</section>
<ng-template #dialogRef let-data>
  <div class="card">
    <div class="card-header pt-4">
      <span class="h6 font-weight-bold">ERP failed</span>
      <a [mat-dialog-close]="true" class="closeicon settingclose"><i class="os-icon os-icon-x"></i></a>
    </div>
    <div class="card-body">
      <p>{{data}}</p>
    </div>
  </div>
</ng-template>