<section>
  <div class="heading-row clearfix">
    <h2 class="h4 font-weight-light d-md-inline">
      <i class="os-icon os-icon-shopping-cart mr-2"></i>Order
      <span class="order-number">#{{ orderData?.order_number }}</span>
    </h2>
    <div class="d-md-inline float-md-right">
      <div class="btn-group btnrow mr-2">
        <button  class="btn" type="button" appPermission
          [permissions]="userPermissions" [type]="'edit'" [routerLink]="['/portal/order/re-order', orderData?.uuid]">
          <i class="os-icon os-icon-clock"></i>
        </button>
        <button class="btn" type="button" [autoClose]="'outside'" triggers="manual" #email="ngbPopover"
          placement="bottom" (click)="email.open();" [ngbPopover]="emailPopContent" [popoverTitle]="emailPopTitle">
          <i class="os-icon os-icon-mail"></i>
        </button>
        <button *ngIf="orderData?.approval_status=='Draft'" class="btn" type="button" appPermission
          [permissions]="userPermissions" [type]="'edit'" [routerLink]="['/portal/order/edit', orderData?.uuid]">
          <i class="os-icon os-icon-edit-32"></i>
        </button>
        <button class="btn" type="button" (click)="getDocument('pdf')">
          <mat-icon [inline]="true">picture_as_pdf</mat-icon>
        </button>
        <button class="btn" type="button" printSectionId="print-section" printTitle="Statement" [printStyle]="{
          h1: { color: 'red' },
          h2: { border: 'solid 1px' }
        }" [useExistingCss]="true" ngxPrint>
          <i class="os-icon os-icon-printer"></i>
        </button>
        <button class="btn" type="button" *ngIf="orderData?.approval_status === 'Draft'" appPermission
          [permissions]="userPermissions" [type]="'delete'">
          <mat-icon (click)="openDeleteBox()">delete</mat-icon>
        </button>

        <!-- <button class="btn" type="button">
          <mat-icon [inline]="true">attach_file</mat-icon>
        </button> -->
        <button (click)="onToggleHistory()" class="btn" type="button">
          <mat-icon [inline]="true">textsms</mat-icon>
        </button>
      </div>
      <span>

        <!-- <button mat-button class="btn btn-sm btn-white dropdown-toggle" [matMenuTriggerFor]="menu2">
          More
        </button>
        <mat-menu #menu2="matMenu" xPosition="before">
          <button *ngIf="orderData?.current_stage !='Approved'" appPermission [permissions]="userPermissions"
            [type]="'edit'" mat-menu-item class="btn btn-sm" (click)="editOrder()">
            Edit
          </button>
          <button *ngIf="orderData?.current_stage !='Approved'" mat-menu-item class="btn btn-sm" appPermission
            [permissions]="userPermissions" [type]="'delete'" (click)="openDeleteBox()">
            Delete
          </button>
          <button *ngIf="orderData?.approval_status !='Delivered'" mat-menu-item class="btn btn-sm"
            (click)="startDelivery()" disabled>
            Convert to Delivery
          </button>
        </mat-menu> -->
      </span>
      <button class="btn" type="button" (click)="closeDetailView()">
        <i class="os-icon os-icon-close"></i>
      </button>
      <!-- <button mat-button class="btn btn-sm back-btn btn-secondary ml-2" (click)="goToOrders()"><i class="os-icon os-icon-arrow-left2"></i><span class=""> Back</span></button> -->
    </div>
  </div>
  <div class="separator"></div>

  <div class="approval-holder" *ngIf="hasApprovalPending">
    <div class="approval-box">
      <div class="approve-icon">
        <i class="fa fa-check-square-o"></i>
      </div>
      <div class="approve-content">
        <h2>Approve this order</h2>
        <p>
          This order has been submitted for approval. Verify and approve the
          order.
        </p>
      </div>
      <div class="approve-actions">
        <button mat-button class="approve" (click)="approve()">Approve</button>
        <button mat-button class="reject" (click)="reject()">Reject</button>
      </div>
    </div>
  </div>

  <form class="order-form custom-mat square customertab">
    <div class="card-header active-form overview" [hidden]="ordertypehidden">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <div class="form-group">
            <label>Order Type</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderTypeTitle" disabled />
          </div>
        </div>
      </div>
    </div>

    <div class="card-body overview">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <div class="form-group" *ngIf="!isDepotOrder">
            <label>Customer</label>
            <input type="text" class="form-control form-control-sm non-editable"
              [value]="orderData?.customer ? orderData?.customer?.firstname +' ' +orderData?.customer?.lastname : ''"
              disabled />
          </div>
          <div class="form-group" *ngIf="!isDepotOrder && orderData?.customer">
            <label>Customer Code</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
                orderData?.customer?.customer_info?.customer_code" disabled />
          </div>
          <div class="form-group" *ngIf="!isDepotOrder && orderData?.lob?.name">
            <label for="">Customer Lob</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
            orderData?.lob?.name || ''" disabled />
          </div>

          <div class="form-group" *ngIf="orderData?.storageocation?.code">
            <label for="">Warehouse</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
            orderData?.storageocation?.code +'-'+ orderData?.storageocation?.name || ''" disabled />
          </div>
          <div class="form-group" *ngIf="isDepotOrder">
            <label>Depot</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.depot?.depot_name"
              disabled />
          </div>
          <!-- <div class="form-group" *ngIf="!isDepotOrder">
            <label>{{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
                orderData?.salesman ? orderData?.salesman?.firstname + orderData?.salesman?.lastname : ''
              " disabled />
          </div> -->
          <div class="form-group" *ngIf="orderData?.customer_lop">
            <label>Customer LPO</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.customer_lop"
              disabled />
          </div>
          <div class="form-group">
            <label>Receiving Location</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.receiving_location"
              disabled />
          </div>
        </div>
        <div class="col-md-4">
          <!-- <div class="form-group">
            <label>Order Number</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.order_number"
              disabled />
          </div> -->
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label>Delivery Date</label>
                <input type="date" class="form-control form-control-sm non-editable" [value]="orderData?.delivery_date"
                  disabled />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="">Receiving Time</label>
                <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.receiving_time"
                  disabled name="appt" id="input-pickuptime" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Order Number</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.order_number"
              disabled />
          </div>
          <div class="form-group" *ngIf="orderData?.customer?.customer_info?.customer_type_id === 1">
            <label>Payment Terms</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="orderData?.payment_term?.name"
              disabled />
          </div>
          <div class="form-group">
            <label>Due Date</label>
            <input type="date" class="form-control form-control-sm non-editable" [value]="orderData?.due_date"
              disabled />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="item-container custom-mat custom-mat-no-border">
        <div class="table-responsive">
          <table class="  cardtable item-table">
            <thead>
              <tr>
                <th class="head" *ngFor="let head of itemTableHeaders">
                  {{ head.label }}
                </th>
              </tr>
            </thead>
            <tbody class="form-body">
              <tr class="item-row" *ngFor="let item of orderData?.order_details; let i = index">
                <td data-th="#">{{ i + 1 }}</td>
                <td data-th="Item Code" class="item-name" style="width: 150px;">
                  <!-- <input type="text" class="form-control form-control-sm non-editable" [value]="item.item.item_name"
                    disabled /> -->
                  <span style="word-break: break-word; font-size: 11px;">{{item?.item?.item_code}}</span>
                </td>
                <td data-th="Item Name" class="item-name" style="width: 150px;">
                  <!-- <input type="text" class="form-control form-control-sm non-editable" [value]="item.item.item_name"
                    disabled /> -->
                  <span style="word-break: break-word; font-size: 11px;">{{item?.item?.item_name}}</span>
                </td>
                <td data-th="Quantity">
                  <input type="number" min="0" class="form-control non-editable" [value]="numberFormat(item.item_qty)"
                    disabled />
                </td>
                <!-- <td data-th="UOM" class="uom-td">
                  <input type="text" class="form-control form-control-sm non-editable" [value]="item.item_uom.name"
                    disabled />
                </td> -->

                <td data-th="Price">
                  <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_price)"
                    disabled />
                </td>
                <td data-th="Excise">
                  <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_excise)"
                    disabled />
                </td>
                <!-- <td data-th="Discount">
                  <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_discount_amount)"
                    disabled />
                </td> -->
                <td data-th="Net">
                  <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_net)" disabled />
                </td>
                <td data-th="Vat">
                  <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_vat)" disabled />
                </td>
                <td data-th="Total" style="width:10px;">
                  <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_grand_total)"
                    disabled />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="footer-overview-container">
        <div class="row justify-content-between">
          <div class="form-group col-sm-5">
            <label for="note">Customer Note</label>
            <textarea class="form-control form-control-sm" id="note"
              [value]="orderData?.any_comment ? orderData.any_comment : ''" disabled></textarea>
          </div>
          <div class="col-sm-4">
            <div class="overall-stats-table">
              <div *ngFor="let stat of orderStats.slice(0, orderStats.length - 1)">
                <span class="stat-label">{{ stat.label }}</span>
                <span class="stat-value">{{
                  orderData && numberFormatWithSymbol(orderData[stat.key])
                  }}</span>
                <!-- <span class="stat-value">{{
                  orderData && orderData[stat.key]
                    
                }}</span> -->
              </div>
              <div>
                <span class="stat-label final-total">Total</span>
                <span class="stat-value final-total">{{
                  numberFormatWithSymbol(orderData?.grand_total)
                  }}</span>
                <!-- <span class="stat-value final-total">{{
                  orderData?.grand_total 
                }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- <div class="footer-block"></div> -->
</section>
<ng-container *ngIf="showSpinner">
  <mat-progress-spinner class="example-margin" [color]="color" [mode]="mode" strokeWidth="3">
  </mat-progress-spinner>
</ng-container>
<ng-template #emailPopTitle>
  <span>Send Order {{orderData.order_number}}</span>
</ng-template>
<ng-template #emailPopContent>
  <app-send-email [data]="emailData" (close)="email.close()"></app-send-email>
</ng-template>

<div class="card border" id="print-section">
  <div class="card-body" [innerHTML]="orderTemplate"></div>
</div>