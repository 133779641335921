<section>
  <div class="modal-header"> 
    <h2 class="m-0">
      <span>Add New Order Type</span>
     
    </h2>
    <span class="close-btn"><button (click)="closeModal()"><i class="fa fa-close"></i></button></span>
  </div>

  <div class="modal-body">
    <form [formGroup]="formGroup">
      <div class="form-group row">
        <label for="name" class="col-sm-3 col-form-label">Type Name</label>
        <div class="col-sm-8">
          <input type="text" class="form-control form-control-sm" required formControlName="name" id="name">
        </div>
      </div>
      <div class="form-group row">
        <label for="desc" class="col-sm-3 col-form-label">Description</label>
        <div class="col-sm-8">
          <input type="text" class="form-control form-control-sm" required formControlName="description" id="desc">
        </div>
      </div>
      <div class="form-group row">
        <label for="use" class="col-sm-3 col-form-label">Use For</label>
        <div class="col-sm-8">
          <input type="text" class="form-control form-control-sm" required formControlName="use_for" id="use">
        </div>
      </div>
      <div class="form-group row">
        <label for="code" class="col-sm-3 col-form-label">Prefix Code</label>
        <div class="col-sm-8">
          <input type="text" class="form-control form-control-sm" required formControlName="prefix_code" id="code">
        </div>
      </div>
      <div class="form-group row">
        <label for="from" class="col-sm-3 col-form-label">Select Range</label>
        <div class="col-sm-4">
          <input type="number" class="form-control form-control-sm" required min="0" formControlName="start_range" id="from" placeholder="from">
        </div>
        <div class="col-sm-4">
          <input type="number" class="form-control form-control-sm" required min="1" formControlName="end_range" placeholder="to">
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="postTypeData()">Save</button>
        <button class="cancel-btn" (click)="closeModal()">Cancel</button>
      </div>
    </form>
  </div>
</section>
