import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataEditor {

  private dataSource: BehaviorSubject<any> = new BehaviorSubject<any>({});
  public newData: Observable<any> = this.dataSource.asObservable();

  public nextSubjectData = new Subject<any>();
  public nextSubjectDataObj = this.nextSubjectData.asObservable();
  private notificationCountSource: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  notificationCount = this.notificationCountSource.asObservable();

  private depotIdSource: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  depotId = this.depotIdSource.asObservable();

  private syncDialogTitleSource: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  syncDialogTitle = this.syncDialogTitleSource.asObservable();

  private nextCustomerPricingPlanData: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  nextCustomerPricingPlan = this.nextCustomerPricingPlanData.asObservable();


  private subjectName = new Subject<any>(); //need to create a subject
    

  constructor() { }

  
  sendSideNavUpdate(message: any[], data: any[]) { //the component that wants to update something, calls this fn
    this.subjectName.next({ text: message, data: data }); //next() will feed the value in Subject
  }

  getSideNavUpdate(): Observable<any> { //the receiver component calls this function 
    return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
  }

  public sendData(newData: any): void {
    this.dataSource.next(newData);
  }

  public nextSourceData(data: any): void {
    this.nextSubjectData.next(data);
  }
  public updateNotificationCount(count: number): void {
    this.notificationCountSource.next(count);
  }

  public updateDepotId(id: any): void {
    this.depotIdSource.next(id);
  }

  public updateSyncDialog(title: any): void {
    this.syncDialogTitleSource.next(title);
  }

  public updatePricingPlan(title: any, data: any): void {
    this.nextCustomerPricingPlanData.next({ title, data });
  }
}
