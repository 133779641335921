import { Component, Input, OnInit } from '@angular/core';
import { Customer } from 'src/app/components/main/transaction/collection/collection-models';

@Component({
  selector: 'app-portal-info-tabs',
  templateUrl: './portal-info-tabs.component.html',
  styleUrls: ['./portal-info-tabs.component.scss']
})
export class PortalInfoTabsComponent implements OnInit {
  @Input() public customer: Customer;
  @Input() public lobInfo: any;

  constructor() { }

  ngOnInit(): void {
    console.log("cust-tab", this.customer);
  }

}
