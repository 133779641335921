import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MasterService } from 'src/app/components/main/master/master.service';

@Injectable({ providedIn: 'root' })
export class AdvanceSearchService {
  obj = {
    "list_data": ["country", "region", "sales_organisation", "area",
      "channel", "customer_category", "route", "salesman", "customer", "merchandiser", "item", "brand", "brand_list", "major_category", "major_category_list", "customer_type", "payment_term", "promotional_item"],
    "function_for": "customer"
  }
  constructor(private masterService: MasterService) { }

  getCustomerMasterData(module: any = null) {
    switch (module) {
      case 'invoice':
        this.obj = {
          "list_data": ["salesman"],
          "function_for": "customer"
        }
        break;
      case 'salesman-target-load':
        this.obj = {
          "list_data": ["salesman", "route"],
          "function_for": "customer"
        }
        break;

    }
    return this.masterService.masterList(this.obj).pipe(map(result => result));
  }

}