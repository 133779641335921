<!-- <app-payment-gateway (payment)="100"></app-payment-gateway> -->
<section class="">

  <div class="heading-row">

    <h2 class="d-inline "><i class="os-icon os-icon-shopping-cart mr-2"></i>{{ pageTitle }}</h2>

    <div class="d-inline float-right  pl-0">
      <!-- <button mat-button class="btn btn-sm btn-white mr-2 dropdown-toggle" [matMenuTriggerFor]="menu2">More</button>
      <mat-menu #menu2="matMenu" xPosition="before">
        <button *ngIf="showConvertToDelivery" mat-menu-item class="height-40"
          (click)="postFinalOrder('orderDelivery')">Convert
          to Delivery</button>
        <button mat-menu-item class="height-40" (click)="goToOrder()">Go to Orders</button>
      </mat-menu> -->
      <button mat-button class="btn btn-sm btn-secondary" (click)="goBackToOrdersList()"><i
          class="fa fa-angle-left mr-1"></i><span class="back-btn"> Back</span></button>
    </div>
  </div>

  <div class="separator"></div>
  <form [formGroup]="orderFormGroup" class="order-form custom-mat square">
    <!-- <div class="card">
      <div class="card-header overview">
        <div class="row">
          <div class="col-md-4">
            <label for="">Company</label>
            <div class="form-group smmatinput">
              <mat-form-field>
                <mat-label>Select Company</mat-label>
                <mat-select class="no-disabled-text " id="typeFormField" required [formControl]="companyFormControl"
                  [ngModelOptions]="{ standalone: true }">
                  <mat-option *ngFor="let comp of companyList" [value]="comp.id">{{ comp.name }}</mat-option>
                  <hr class="orderHr">
                  <mat-option id="typeBtn" class="in-opt-btn"><button><i class="os-icon os-icon-plus"></i> Add
                      Type</button></mat-option>
                </mat-select>
                <mat-error *ngIf="companyFormControl.hasError('required')">Please select a company</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div> -->


    <!-- <div [ngClass]="{ 'inactive-form': companyFormControl.invalid }" class="card"> -->
    <div class="card">
      <div class="card-body overview">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div *ngIf="!isDepotOrder" class="form-group smmatinput">
              <label for="">Customer <i class="text-red">*</i></label>
              <div *ngIf="isDeliveryForm"><input type="text" class="form-control form-control-sm non-editable"
                  [value]="orderData.customer.customer_name" disabled></div>

              <div *ngIf="!isDeliveryForm">
                <!--
                <mat-form-field class="example-full-width" id="customerFormField">
                  <input type="text" placeholder="Search Customer" matInput [formControl]="customerFormControl"
                    [matAutocomplete]="auto" #autoCompleteInput (input)="filterSearchCustomer($event)"
                    #trigger="matAutocompleteTrigger" (click)="trigger.openPanel()">
                  <div class="customer-option-box">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onCustomerSelect($event)">
                      <mat-option
                        [ngClass]="(customerFormControl.value.includes(option.customer_code))?'mat-selecte-default':''"
                        *ngFor="let option of filterCustomer" [value]="option">
                        {{option.customer_code +' - '+ option.firstname +' '+ option.lastname}}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                  <div style="margin-top:12px;">
                    <mat-error *ngIf="customerFormControl.hasError('required')">Please select a customer</mat-error>
                  </div>
                </mat-form-field> -->

                <div class="col-12 p-0 customer-name-select">
                  <div class="customer-option-box">
                    <app-multiautocomplete isSingle="true" [options]="filterCustomer" [control]="customerFormControl"
                      text="name" id="id" placeholder="Select Customer" (selectionchanged)="onCustomerSelect($event)">
                    </app-multiautocomplete>

                  </div>
                  <div style=" margin-top:12px;">
                    <mat-error *ngIf="customerFormControl.hasError('required')">Please select a customer</mat-error>
                  </div>
                </div>

                <div class="row mt-3">
                  <div *ngIf="is_lob" class="col">
                    <div class="form-group row mb-3 m-0 p-0">
                      <label> Customer Lob:</label>
                      <div class="col-11 p-0">
                        <app-multiautocomplete isSingle="true" [options]="customerLobList"
                          [control]="customerLobFormControl" text="name" id="lob_id" placeholder="Select Lob"
                          objectName="lob" param1="name" param2="">
                        </app-multiautocomplete>
                        <mat-error *ngIf="customerLobFormControl.hasError('required')">Please select a Customer Lob
                        </mat-error>
                      </div>
                      <!-- <div class="col-1 p-2 mt-2">
                              <a (click)="openItemLob()" class="ml-2 settingIcon"><i
                                  class="os-icon os-icon-ui-46" aria-hidden="true"></i></a>
                          </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group smmatinput" *ngIf="isCustomerlobShow">
              <label for="">Warehouse</label>
              <div>
                <app-multiautocomplete isSingle="true" [options]="storageLocationList" [control]="warehouseFormControl"
                  text="name" id="id" placeholder="Select Warehouse" objectName="customer-root-ddl" [param1]="'code'"
                  [param2]="'name'">
                </app-multiautocomplete>
              </div>
            </div>
            <!-- <div *ngIf="isDepotOrder" class="form-group smmatinput">
              <label for="">Depot</label>
              <div>
                <mat-form-field id="depotFormField">
                  <mat-label>Select Depot</mat-label>
                  <mat-select class="no-disabled-text" (blur)="checkFormValidation('order')" required
                    (ngModelChange)="depotChanged($event)" [formControl]="depotFormControl"
                    [(ngModel)]="selectedDepotId" [disabled]="isDeliveryForm">
                    <mat-option *ngFor="let depot of depots" [value]="depot.id">{{ depot.depot_name }}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="depotFormControl.hasError('required')">Please select a depot</mat-error>
                </mat-form-field>
              </div>
            </div> -->

            <div class="form-group smmatinput">
              <!-- <label for="">{{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}</label> -->
              <!-- <div>
                <mat-form-field id="salesmanFormField">
                  <mat-label>Select {{domain == 'nfpc' || domain == 'merchandising' ? 'Merchandiser' : 'Salesman'}}
                  </mat-label>
                  <mat-select class="no-disabled-text" (blur)="checkFormValidation('order')"
                    [formControl]="salesmanFormControl">
                    <mat-option *ngFor="let sman of salesmen" [value]="sman.user.id">
                      {{ sman.user ? sman.user.firstname + ' ' + sman.user.lastname : '' }} - {{ sman.salesman_code }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="salesmanFormControl.hasError('required')">Please select a salesman</mat-error>
                </mat-form-field>
                <app-multiautocomplete isSingle="true" [options]="salesmen" [control]="salesmanFormControl" text="user"
                  id="user_id" placeholder="Select Salesman" objectName="user" param1="firstname" param2="lastname">
                </app-multiautocomplete>
              </div> -->
            </div>
            <div class="form-group smmatinput">
              <label for="">Receiving Location <i class="text-red">*</i></label>
              <div>
                <app-multiautocomplete isSingle="true" [options]="locationsData" [control]="customerLocationFormControl"
                  placeholder="Select Receiving Location" text="address" id="id" param1="name" objectName="root">
                </app-multiautocomplete>


                <!-- <input type="text" id="customeLocation" class="form-control form-control-sm"
                  [formControl]="customerLocationFormControl" /> -->
                <mat-error *ngIf="customerLocationFormControl.hasError('required')">Please enter a receiving
                  location</mat-error>
              </div>
            </div>
            <div class="form-group smmatinput">
              <label for="">Customer LPO</label>
              <div>
                <input type="text" id="customeLPO" class="form-control form-control-sm"
                  [formControl]="customerLPOFormControl" />
              </div>
            </div>

          </div>

          <div class="col-md-4">
            <!-- <div class="form-group">
              <label for="">Order Number</label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="orderNumber"
                  [ngModelOptions]="{standalone: true}" (change)="setOrderNumber($event.target.value)" maxlength="10"
                  [disabled]="(isEditForm || this.isDeliveryForm || nextCommingNumberofOrderCode != '')" />
                <span class="setting-btn float-right"><button (click)="openNumberSettings()"><i
                      class="os-icon os-icon-settings"></i></button></span>
              </div>

            </div> -->
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="">Delivery Date <i class="text-red">*</i></label>
                  <input [min]="todayDate" type="date" class="form-control form-control-sm" required
                    [formControl]="deliveryDateFormControl" id="deliveryDate" (change)="changeDelivery()">
                  <mat-error *ngIf="deliveryDateFormControl.hasError('required')">Please select a delivery
                    date</mat-error>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="">Receiving Time <i class="text-red">*</i></label>
                  <!-- <input class="form-control form-control-sm" type="time" required
                    [formControl]="deliveryTimeFormControl" name="appt" id="input-pickuptime"> -->
                  <!-- <mat-select class="form-control form-control-sm" [formControl]="deliveryTimeFormControl">
                    <mat-option value="" disabled>Selected Receiving Time</mat-option>
                    <mat-option value="6:00 am to 10:00 am">6:00 am to 10:00 am</mat-option>
                    <mat-option value="4:00 pm to 8:00 pm">4:00 pm to 8:00 pm</mat-option>
                  </mat-select> -->
                  <app-multiautocomplete isSingle="true" [options]="timeslot" [control]="deliveryTimeFormControl"
                    text="name" id="id" param1="name" objectName="root" placeholder="Select Receiving Time">
                  </app-multiautocomplete>
                  <mat-error *ngIf="deliveryTimeFormControl.hasError('required')">Please select a receiving
                    time</mat-error>
                </div>
              </div>
            </div>
            <div class="form-group smmatinput" *ngIf="customer?.customer_type?.customer_type_name !== 'Cash'">
              <label for="">Payment Terms <i class="text-red">*</i></label>
              <input type="text" [value]="customerDueText" class="form-control form-control-sm"
                [formControl]="paymentTermFormControl" readonly>
              <!-- <mat-form-field>
                <mat-label>Select Payment Term</mat-label>
                <mat-select class="no-disabled-text" required id="termFormField"
                  (ngModelChange)="payTermChanged($event)" [(ngModel)]="selectedPaymentTermId"
                  [ngModelOptions]="{ standalone: true }" [disabled]="isDeliveryForm">
                  <mat-option *ngFor="let term of terms" [value]="term.id">{{ term.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentTermFormControl.hasError('required')">Please select a payment term</mat-error>
              </mat-form-field> -->
              <mat-error *ngIf="paymentTermFormControl.hasError('required')">Please select a payment term</mat-error>
            </div>
            <div class="form-group">
              <label for="">Due Date <i class="text-red">*</i></label>
              <input type="date" [min]="dueDateSet" [formControl]="dueDateFormControl" required
                class="form-control form-control-sm" id="dueDate" readonly>
              <mat-error *ngIf="dueDateFormControl.hasError('required')">Please select a due date</mat-error>
            </div>
          </div>



        </div>
      </div>
      <div class="item-table-div">
        <div class="bottom-form">
          <div class="item-container custom-mat-no-border cform1">

            <button class="btn btn-sm filter-btn" [matMenuTriggerFor]="menu" style="height: 30px;"><i
                class="fa fa-filter"></i></button>

            <mat-menu #menu="matMenu">
              <div class="card-body p-2" style="min-width: 200px;">
                <ul style="list-style:none; padding-left: 7px; margin-bottom: 2px;" class="filter-option-list"
                  filterCheck>
                  <li *ngFor="let check of filterCheck">
                    <input type="radio" [value]="check.name" name="items" [checked]="check.name === currentCheck"
                      (change)="handleChange(check.name)">{{check.label}}
                  </li>
                  <!-- <li><input type="radio" value="all" name="items" [checked]="filterCheck" (change)="handleChange('all')">All Items</li>
                  <li><input type="radio" value="selected" name="items" [checked]="!filterCheck" (change)="handleChange('selected')">Selected
                    Items</li> -->
                </ul>
                <div class="float-right mb-2">
                  <button mat-button class="btn mat-inherit-line-height filter-cancel-btn">Cancel</button>
                </div>
              </div>
            </mat-menu>



            <div class="table-responsive">
              <div class="mat-elevation-z8 item-table-row">
                <table mat-table [dataSource]="customerItemsData" class="item-table">

                  <!-- Position Column -->
                  <ng-container matColumnDef="sr">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element; let i = index">{{element.index}}</td>
                  </ng-container>

                  <!-- Name Column -->
                  <ng-container matColumnDef="itemCode">
                    <th mat-header-cell *matHeaderCellDef>
                      <div class="d-flex">
                        Item Code
                        <mat-icon class="advance-search-icon" [ngClass]="{'selected':selectedItemcode}"
                          (click)="filter('code')" class="search">search</mat-icon>
                      </div>
                      <div class="s008" *ngIf="searchItemCode">
                        <div class="inner-form">
                          <div class="row search-box">
                            <div class="col-8" style="position: absolute;">
                              <div class="advance-search">
                                <div class="d-flex">
                                  <form [formGroup]="searchForm" class="advance-searce-box-form">
                                    <input type="text" placeholder="Search" formControlName="search">
                                  </form>
                                </div>
                                <div class="d-flex action justify-content-end advance-searce-box-form advance-frm-btn">
                                  <mat-icon class="btn-action" (click)="closeSearchInput()">close</mat-icon>
                                  <mat-icon class="btn-action" (click)="checkSearchInput('item_code')">check</mat-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.item_code}} </td>
                  </ng-container>

                  <!-- Weight Column -->
                  <ng-container matColumnDef="itemName">
                    <th mat-header-cell *matHeaderCellDef>
                      <div class="d-flex">
                        Item Name
                        <mat-icon [ngClass]="{'selected':selectedItemname}" (click)="filter('name')"
                          class="search">search</mat-icon>
                      </div>
                      <div class="s008" *ngIf="searchItemName">
                        <div class="inner-form">
                          <div class="row search-box">
                            <div class="col-8" style="position: absolute;">
                              <div class="advance-search">
                                <div class="d-flex">
                                  <form [formGroup]="searchForm" class="advance-searce-box-form">
                                    <input type="text" placeholder="Search" formControlName="search">
                                  </form>
                                </div>
                                <div class="d-flex action justify-content-end advance-searce-box-form advance-frm-btn">
                                  <mat-icon class="btn-action" (click)="closeSearchInput()">close</mat-icon>
                                  <mat-icon class="btn-action" (click)="checkSearchInput('item_name')">check</mat-icon>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.item_name}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="quantity" class="quantity-col">
                    <th mat-header-cell *matHeaderCellDef> Quantity </th>
                    <td mat-cell *matCellDef="let element">
                      <input type="number" min="0" class="form-control" placeholder="Quantity"
                        oninput="this.value = Math.abs(this.value)" [value]="element.item_quantity"
                        (input)="changeInQuantity($event ,element)">
                    </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> Price </th>
                    <td mat-cell *matCellDef="let element" [class]="element.is_contrast===1?'price-list':''">
                      <!-- <span *ngIf="element.is_contrast===0"><input type="number" class="form-control" step="0.1"
                          placeholder="Price" [value]="element.price" (input)="changeInPrice($event ,element)" (focusout)="formatNumber($event.target)"
                          (keydown)="check($event)"></span> -->
                      <!-- <span *ngIf="element.is_contrast===1" class="item-price">{{element.price}}</span> -->
                      <span class="item-price">{{element.price}}</span>
                    </td>
                  </ng-container>

                  <!-- Excise Value -->
                  <ng-container matColumnDef="excise">
                    <th mat-header-cell *matHeaderCellDef> Excise </th>
                    <td mat-cell *matCellDef="let element"> {{element.item_excise | number :'1.2-2'}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="net">
                    <th mat-header-cell *matHeaderCellDef> Net </th>
                    <td mat-cell *matCellDef="let element"> {{element.item_Net | number :'1.2-2'}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="vat">
                    <th mat-header-cell *matHeaderCellDef> VAT (5%) </th>
                    <td mat-cell *matCellDef="let element"> {{element.item_VAT | number :'1.2-2'}} </td>
                  </ng-container>

                  <!-- Symbol Column -->
                  <ng-container matColumnDef="total">
                    <th mat-header-cell *matHeaderCellDef> Total </th>
                    <td mat-cell *matCellDef="let element"> {{element.item_total | number :'1.2-2'}} </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row;columns: displayedColumns;"></tr>
                </table>

                <mat-paginator [pageSizeOptions]="[10, 25, 50]" [length]="customerItemsData?.filteredData.length">
                </mat-paginator>
              </div>


              <!-- <table class="item-table cardtable table ">
                <thead>
                  <tr>
                    <th class="head" *ngFor="let head of filteredTableHeaders">{{ head.label }}</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="form-body">
                  <tr formArrayName="items" class="item-row" *ngFor="let item of itemFormControls; let i = index;">
                    <ng-container [formGroupName]="i">
                      <td data-th="#" class="item-sequence" *ngIf="isEditForm">
                        <span>{{ i + 1 }}</span>
                        <input [ngClass]="getItemStatus(item) ? 'notAvaliable' : ''"
                          (change)="itemDidSelected($event, payloadItems[i])" [disabled]="getItemStatus(item)"
                          type="checkbox" name="item">
                      </td>
                      <td class="item-sequence" *ngIf="!isEditForm">
                        <span>{{ i + 1 }}</span>
                        <input (change)="itemDidSelected($event, payloadItems[i])" type="checkbox" name="item">
                      </td>
                      <td data-th="Item Code" *ngIf="isDeliveryForm"><input type="text"
                          class="form-control form-control-sm non-editable item-name"
                          [value]="payloadItems[i].item.name" disabled></td>
                      <td data-th="Item Code" class="item-name" *ngIf="!isDeliveryForm">
                        <mat-form-field class="item-name-field">
                          <input type="text" required placeholder="Search an item" matInput formControlName="item"
                            [matAutocomplete]="auto" (keyup)="fiterItems($event.target.value)">
                          <mat-autocomplete appOptionScroll #auto="matAutocomplete"
                            (opened)="checkFormValidation('order')"
                            (optionSelected)="itemDidSearched($event.option.value, i)"
                            [displayWith]="itemsControlDisplayValue">
                            <mat-option class="mat-option" *ngFor="let item of filteredItems"
                              [value]="itemControlValue(item)">
                              {{item.item_code}} - {{ item.item_name }} </mat-option>
                            <mat-option *ngIf="isLoading">
                              <div class="select-placeholder-container">
                                <mat-spinner class="spinner" diameter="20"></mat-spinner>
                              </div>
                            </mat-option>
                            <hr class="customerHr">
                            <mat-option id="custBtn" class="in-opt-btn"><button (click)="redirectToItem()"><i
                                  class="os-icon os-icon-plus"></i> Add Item</button></mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </td>
                      <td data-th="Item Name" *ngIf="isDeliveryForm"><input type="text"
                          class="form-control form-control-sm non-editable item-name" formControlName="item_name"
                          disabled></td>
                      <td data-th="Item Name" class="item-name" *ngIf="!isDeliveryForm">
                        <input type="text" class="form-control form-control-sm non-editable item-name"
                          formControlName="item_name" disabled>
                      </td>
                      <td class="uom-td" *ngIf="isDeliveryForm"><input type="text" class="form-control form-control-sm non-editable" [value]="getUomValue(payloadItems[i])" disabled></td>
                      <td data-th="UOM" *ngIf="isDeliveryForm"><input type="text"
                          class="form-control form-control-sm non-editable uom-td"
                          [value]="item.value.item_uom_list[i]?.name" disabled></td>
                      <td data-th="UOM" class="uom-td" *ngIf="!isDeliveryForm">
                        <select class="custom-select" [attr.disabled]="item?.value?.is_free || null" required
                          formControlName="item_uom_id">
                          <option [value]="uom?.id" *ngFor="let uom of item?.value?.item_uom_list">{{ uom?.name }}
                          </option>
                        </select>
                      </td>
                      <td data-th="Quantity" class="stats-cell">
                        <input type="number" min="0" class="form-control" placeholder="Quantity"
                          [readonly]="item?.value?.is_free" formControlName="item_qty" (change)="changeInQuantity($event,item)">
                      </td>
                      <td data-th="Price" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_price)" disabled></td>
                      <td data-th="Excise" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_excise)" disabled></td>
                      <td data-th="Discount" *ngIf="itemTableHeaders[5]?.show" class="stats-cell"><input type="text"
                          class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_discount_amount)" disabled>
                      </td>
                      <td data-th="Net" class="stats-cell">
                        <input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_net)" disabled>
                      </td>
                      <td data-th="Vat" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_vat)" disabled>
                      </td>


                      <td data-th="Total" class="stats-cell"><input type="text" class="form-control non-editable"
                          [value]="numberFormat(payloadItems[i]?.item_grand_total)" disabled></td>
                      <td data-th="Action"> <button class="trash-btn" (click)="deleteItemRow(i)"><i
                            class="fa fa-close"></i></button></td>
                    </ng-container>
                  </tr>
                </tbody>
              </table> -->
            </div>
            <!-- <button mat-button class="mat-inherit-line-height add-item-btn" [disabled]="isDeliveryForm"
              (click)="addItem()">
              Add Item
            </button>
            <button mat-button class="mat-inherit-line-height add-item-btn" style="margin-left: 5px;"
              (click)="openBulkItemSelectionPopup()">
              Add Bulk Item
            </button> -->
          </div>

        </div>
        <div class=" footer-overview-container">
          <div class="row justify-content-between">
            <div class="form-group col-sm-5">
              <label for="note">Customer Note</label>
              <textarea class="form-control form-control-sm" [formControl]="noteFormControl" id="note"></textarea>
            </div>
            <div class=" col-sm-4">
              <div class="overall-stats-table">
                <div>
                  <span class="stat-label">Gross Total</span>
                  <span class="stat-value">{{numberFormatWithSymbol(cutomerTotalNET)}}</span>
                  <span class="stat-label">Net Total</span>
                  <span class="stat-value">{{numberFormatWithSymbol(cutomerTotalNET)}}</span>
                  <span class="stat-label">Excise</span>
                  <span class="stat-value">{{numberFormatWithSymbol(customerTotalExcise)}}</span>
                  <span class="stat-label">VAT Total</span>
                  <span class="stat-value">{{numberFormatWithSymbol(customerTotalVAT)}}</span>
                </div>
                <!-- <div *ngFor="let stat of objectValues(orderFinalStats)">
                  <span class="stat-label">{{ stat.label }}</span>
                  <span class="stat-value">{{ numberFormatWithSymbol(stat.value) }}</span>
                </div> -->
                <div>
                  <span class="stat-label final-total">Total</span>
                  <span class="stat-value final-total">{{ numberFormatWithSymbol(customerTotal)
                    }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="form-group col-sm-12 d-flex justify-content-center mt-2">
              <p class="text-bold m-1">Disclaimer : Prices may vary based on stock availability
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="actions">
    <!-- <button class="btn btn-primary" (click)="saveAsDraft()">Save as Draft</button> -->
    <button class="btn btn-primary" (click)="saveAsDraft()">Save as Draft</button>
    <button class="btn btn-primary" (click)="openDialog()">Save & Submit</button>
    <!-- <button class="btn btn-primary" (click)="pay()">Save & Submit</button> -->
    <!-- <button class="btn btn-primary" (click)="post()">Save & Submit</button> -->
  </div>
</section>
