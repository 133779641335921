import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  TemplateRef,
} from '@angular/core';
import { FormDrawerService } from './services/form-drawer.service';
import { MatDrawer } from '@angular/material/sidenav';
import { slideInAnimation } from './route-animation';
import {
  RouterOutlet,
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
} from '@angular/router';
import { CommonSpinnerService } from './components/shared/common-spinner/common-spinner.service';
import { registerLocaleData } from '@angular/common';
import { setCurrency } from './services/constants';
import { ApiService } from 'src/app/services/api.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from './services/auth.service';
import { SidenavService } from './services/sidenav.service';
import { DataEditor } from './services/data-editor.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'SFA-SAAS';
  formName = '';
  isCashierReceiptReload: boolean = false;
  @ViewChild('formDrawer') fromDrawer: MatDrawer;
  @ViewChild("dialogRef") dialogRef: TemplateRef<any>;
  sidebarConfig: any;
  constructor(
    private fds: FormDrawerService,
    private router: Router,
    private commonSpinnerService: CommonSpinnerService,
    public apiService: ApiService,
    private deleteDialog: MatDialog,
    private bnIdle: BnNgIdleService,
    private authService: AuthService,
    private sService: SidenavService,
    private dataEditorService: DataEditor

  ) {
    this.router.events.subscribe((event: Event) => {
      // console.log(event)
      switch (true) {
        case event instanceof NavigationStart: {
          this.commonSpinnerService.show();
          let appName = sessionStorage.getItem('appName')
          // let token = sessionStorage.getItem('token')
          // if(token){
          //   sessionStorage.setItem('token', token);
          // }
          if (appName == 'LoyalityManagement') {
            sessionStorage.setItem('appName', appName);
            //this.sService.getLoyalitySideBar().subscribe((res) => {
            //this.sidebarConfig = res;
            //this.setSideBar()
            //})
          }
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.commonSpinnerService.hide();
          break;
        }
      }
    });

    // this.bnIdle.startWatching(780).subscribe((res) => {
    //   if (res) {
    //     var response = this.deleteDialog.open(this.dialogRef, {});
    //     response.afterClosed().subscribe(res => {
    //       this.authService.logout().subscribe((res) => {
    //         localStorage.setItem('isLoggedIn', 'false');
    //         localStorage.removeItem('token');
    //         window.location.reload();
    //       });
    //     });
    //     setTimeout(() => {
    //       response.close();
    //     }, 2000);
    //   }
    // });

  }

  ngOnInit() {
    // debugger;
    const themeName = localStorage.getItem('selected-theme');
    let currency = localStorage.getItem('currency');
    if (currency && currency !== 'null') {
      currency = JSON.parse(currency);
      setCurrency(currency['code']);
    }
    if(localStorage.getItem('isPortalLoggedIn') == 'true' && window.location.pathname.includes('portal')) {
      this.getPortalPermission();
    }
    document.documentElement.setAttribute('data-theme', themeName);
    this.fds.formName.subscribe((x) => (this.formName = x, console.log(x)));
    if (localStorage.getItem('isLoggedIn') == 'true' && !window.location.pathname.includes('portal')) {
      this.getPermissions();
    } 
    
  }

  portalCustomer() {
    if(localStorage.getItem("portalLoggedInUser")){
      let portalUser = JSON.parse(localStorage.getItem("portalLoggedInUser"))
      let model = {
        avatarImage: this.authService.avatar_img ? this.authService.avatar_img : '',
        firstname: portalUser.firstname,
        lastname: portalUser.lastname,
        email: portalUser.email,
        id: portalUser.id,
      }
      return model;
    }
  }

  userCustomer() {
    let model = {
      avatarImage: this.authService.avatar_img ? this.authService.avatar_img : '',
      firstname: localStorage.getItem("firstname"),
      lastname: localStorage.getItem("lastname"),
      email: localStorage.getItem("email"),
      id: localStorage.getItem("id")
    }
    return model;
  }

  setSideBar() {
    let sidebar = [];
    this.sService.appSidebar = null
    // sidebar.push({
    //   active: "/dashboard/board1",
    //   icon: "os-icon os-icon-bar-chart-stats-up",
    //   label: "Dashboard", routeTo: "/dashboard/board1"
    // },
    //   { label: "Customer", routeTo: "masters/customer", active: "/customer", icon: "os-icon os-icon-user" });
    sidebar = this.sidebarConfig;
    localStorage.removeItem('appSidebar');
    let sidebarSetting = JSON.parse(localStorage.getItem('sidebar'));
    //let loyalitySettingSidebar = JSON.parse(localStorage.getItem('appLoyalitySettingSidebar'));
    localStorage.removeItem('sidebar');
    let sideBar = []
    sidebarSetting['sidebar'].map(x => {
      if (x.feature_name == 'Dashboard') {
        sideBar.push(x);
      }
      if (x.feature_name == 'Customer') {
        x.heading = 'Loyality'
        sideBar.push(x)
      }
    })
    //let sidebarLoyalitySetting = []
    // sidebarSetting['setting'].map(x => {
    //   loyalitySettingSidebar.map(y => {
    //       if (x.feature_name == y.feature_name) {
    //         sidebarLoyalitySetting.push(x);
    //       }
    //   })
    // })
    localStorage.setItem(
      'sidebar',
      JSON.stringify({
        sidebar: sideBar,
        setting: sidebarSetting.setting,
      })
    );
    localStorage.setItem('appSidebar', JSON.stringify(sidebar));
    // this.sService.getSideBar().subscribe((res) => {
    //   this.sidebarConfig = res;
    //   let sideBarConfig = res;
    //   // for (let menu of this.sidebarConfig) {
    //   //   menu.show = this.featureCheck(menu.label);
    //   // }
    //   // localStorage.setItem('appSidebar', JSON.stringify(this.sidebarConfig));
    //   for (let menu of this.sidebarConfig) {
    //     this.sService.featureCheck(menu.label);
    //   }

    // });
    // send message to subscribers via observable subject
    this.dataEditorService.sendSideNavUpdate(sideBar, sidebar);
  }

  checkPortalSidebar() {
    const value = JSON.parse(localStorage.getItem('isPortalLoggedIn'))
    if (value && value === true && this.router.url.includes('portal')) {
      return true;
    } else {
      return false;
    }
  }

  getPermissions() {
    if(!this.checkPortalSidebar()){
      this.apiService.getLoggedInPermissions().subscribe((res) => {
        // console.log(res);
        localStorage.setItem(
          'permissions',
          JSON.stringify(res)
        );
      })
    }
  }

  getPortalPermission() {
    this.apiService.getLoggedInPortalPermissions().subscribe((res) => {
      // console.log(res);
      localStorage.setItem(
        'portalPermissions',
        JSON.stringify(res)
      );
    })
  }

  ngAfterViewInit(): void {
    this.fds.setNavDrawer(this.fromDrawer);
  }
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animation']
    );
  }
  isLoggedIn(): boolean {
    if (((localStorage.getItem('isLoggedIn') == 'true') && !window.location.pathname.includes('portal')) 
    || ((localStorage.getItem('isPortalLoggedIn') == 'true') && window.location.pathname.includes('portal'))) {
      return true;
    } else {
      return false;
    }
  }

  isSideBar(): boolean {
    if (JSON.parse(localStorage.getItem('sidebar')) == null) {
      return false;
    } else {
      return true;
    }
  }
}
