import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { endpoints } from 'src/app/api-list/api-end-points';
import { DeliveryPayload } from './order-models';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) { }

  portalOrderPermission(){
    const portalPermission = JSON.parse(localStorage.getItem('portalPermissions'));
    const orderPermission = portalPermission.find(order=>order.moduleName == 'Order').permissions;
    orderPermission.forEach(order=> order.name = order.name.split('-')[1]);
    return orderPermission;
  }

  public customer_items(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.customer_items.list, model);
  }
  public child_customer(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.customer_items.childCustomerList, model);
  }
  public customerInfo(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.customer_items.customerInfo, model);
  }
  public orderDashboard(customerId): Observable<any> {
    return this.http.get<any>(endpoints.portalapiendpoint.order.orderDashboard(customerId));
  }

  public addorderList(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.customer_items.add, model);
  }

  public orderList(model): Observable<any> {
    return this.http.post<any>(endpoints.apiendpoint.order.list, model);
  }

  public portalOrderList(model): Observable<any> {
    let statToken = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiOGQ0Y2EwNGFmZDM2OWE4NjhiMDJiZGIzMWNmODMwNWRiOGUyYjc3NDQ4OTIyMzNhNzFkZmM2NzNhM2Y5NGVjNzMyY2MwY2U4MDhhZTkwYzQiLCJpYXQiOjE2NDE4MjYxMjMuOTc1MDI4LCJuYmYiOjE2NDE4MjYxMjMuOTc1MDMyLCJleHAiOjE2NzMzNjIxMjMuOTY5NTY1LCJzdWIiOiI4MzIxIiwic2NvcGVzIjpbXX0.JNhOyGaPzxdCOXH9BfPmAQHJvH1CnIFqUE-sQReeDUkvbNqY22h0FmaBFZPPueIn7Lp4itm-F6QJMnz_UUaNFjGcm2Mp8Ez44iT39F9XGs2HYAc1yWPeVhaa-2z--7eoyt_FZns7GO0-ilCBiXxjmvR7kfmgndJxVVcnVb-8tZwBfr_lpsXHuREhqgtcuuw_lYawrsfAQhIgSLc6VHYpgtbfwDqRU3Ffdbge-UTsyhwXvMEd2yhNvHpLRgKMkNkQXDTcq-lCx9AME0jJjvjq9X4eo3TNV81xYBnvAmKtIGEXdJ7MNrd6O6nHf5SH1Orfencw_nT5JS8lSmUYRinYBDDd5uwZN7lMu6cQe0Y6WKIh7dC0rfnxYqXifbqYgx6ikpQZYOaQ-OH-RhmQWBMNeijbe449RF0veZrX_cTtE8hL3WG2ACXGA_hFIotApY5jU6HoFCWy1MsKgSEc_30f7gMZyg31N08JeiycdNcBppomaYeBhMIuiDv2jSZQ8Yr2iVxCUB8KMJCSHLcimm6ScAgoA_iudKyCttruC6ZhwEuSqEjBhMKLzvCzsBm6nsxmF9KHgHoBHCCp1sp9A4TSt0pTyMEw2e0cUimFg9RZ7iv54HGzFaxvrK9jWizvfCmv7CdfZplgtXhb1r-QuEnhmwzlOKjWG7ECwsWEzDzvvaA'
    // const headers= new HttpHeaders()
    // .set('Authorization', `Bearer ${localStorage.getItem('portalAccessToken')}`);
    const headers = new HttpHeaders()
      .set('Authorization', statToken);

    return this.http.post<any>(endpoints.portalapiendpoint.order.list, model, { headers: headers });
  }

  public getDocument(model): Observable<any> {
    return this.http.post(endpoints.portalapiendpoint.order.download, model);
  }

  public getOrderById(uuid: string): Observable<any> {
    return this.http.get<any>(endpoints.portalapiendpoint.order.edit(uuid));
  }

  public getDeleteOrderById(uuid: string): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.order.delete(uuid),null);
  }

  public addOrderType(orderTypeData: any): Observable<any> {
    return this.http.post<any>(
      endpoints.apiendpoint.orderType.addType,
      orderTypeData
    );
  }

  public orderTypeList(): Observable<any> {
    return this.http.get<any>(endpoints.apiendpoint.orderType.list);
  }

  public getOrderItemStats(itemData: any): Observable<any> {
    return this.http.post<any>(endpoints.apiendpoint.order.priceDetail,itemData
    );
  }

  public addNewOrder(order: any): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.order.add, order);
  }

  public editOrder(uuid: string, order: any): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.order.edit(uuid), order);
  }

  public getNextCommingCode(code: any): Observable<any> {
    return this.http.post<any>(
      endpoints.apiendpoint.nextUpCommingCode.code,
      code
    );
  }

  public getPortalNextCommingCode(code: any): Observable<any> {
    return this.http.post<any>(
      endpoints.portalapiendpoint.nextUpCommingCode.code,
      code
    );
  }

  public postDelivery(
    deliveryData: DeliveryPayload
  ): Observable<DeliveryPayload> {
    return this.http.post<DeliveryPayload>(
      endpoints.apiendpoint.delivery.add,
      deliveryData
    );
  }

  public getPaymentTerm(): Observable<any> {
    return this.http.get<any>(endpoints.apiendpoint.paymentTerms.term);
  }

  public deleteOrder(uuid: string): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.order.delete(uuid),null);
  }
  public getPromotionItems(Items: any): Observable<any> {
    return this.http.post<any>(
      endpoints.apiendpoint.order.promotionsItems,
      Items
    );
  }

  public exportOrder(payload) {
    return this.http.post<any>(endpoints.apiendpoint.item.export(), payload);
  }

  public importOrder(payload) {
    return this.http.post<any>(
      endpoints.apiendpoint.item.import('import'),
      payload
    );
  }
}
