import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { customerData } from 'src/app/features/shared/mock-data/mock-data.component';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-portal-info',
  templateUrl: './portal-info.component.html',
  styleUrls: ['./portal-info.component.scss']
})
export class PortalInfoComponent implements OnInit {
  public customer : any;
  customerLobList = [];
  lobs: any;
  name: any;

  constructor(private route : ActivatedRoute, private apiService: ApiService) { }

  ngOnInit(): void {
    let customer = JSON.parse(localStorage.getItem('portalCustomer'));
    console.log("cust", customer);
    this.customer = customer;
    this.apiService.getLobs().subscribe(lobs => {
      console.log("resonseLobs", lobs.data);
      this.lobs = lobs.data;
      this.name = lobs.data.name;
    })
   }

   getLobById(id) {
    return this.customerLobList.find((x) => x.id == id) || "N/A";
  }

}
