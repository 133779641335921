import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionModel } from '../collection-models';
import { ColumnConfig } from 'src/app/interfaces/interfaces';
import {
  getCurrency,
  getCurrencyDecimalFormat,
} from 'src/app/services/constants';
import {
  APP_CURRENCY_CODE,
  CompDataServiceType,
} from 'src/app/services/constants';
import { ApiService } from 'src/app/services/api.service';
import { DataEditor } from 'src/app/services/data-editor.service';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from 'src/app/services/utils';
import { CollectionService } from '../collection.service';
import { EventBusService } from 'src/app/services/event-bus.service';
import { EmitEvent, Events } from 'src/app/models/events.model';
import { DeliveryModel } from '../../delivery/delivery-model';
import { PAGE_SIZE_10 } from 'src/app/app.constant';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TemplateRef } from '@angular/core';
import { MasterService } from 'src/app/components/main/master/master.service';

@Component({
  selector: 'app-collection-data-table',
  templateUrl: './collection-data-table.component.html',
  styleUrls: ['./collection-data-table.component.scss'],
})
export class CollectionDataTableComponent implements OnInit, OnDestroy {
  @Input() public isDetailVisible: boolean;
  @Output() public itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() public detailsClosed: EventEmitter<any> = new EventEmitter<any>();
  @Input() public newCollectionData: any;
  public dataSource: MatTableDataSource<CollectionModel>;
  public collections: CollectionModel[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public selections = new SelectionModel(true, []);
  public displayedColumns: ColumnConfig[] = [];
  public filterColumns: ColumnConfig[] = [];
  advanceSearchRequest: any[] = [];
  public currencyCode = getCurrency();
  public currencyDecimalFormat = getCurrencyDecimalFormat();

  public filterObjectId: any = null;

  private router: Router;
  private collectionService: CollectionService;
  private dataEditor: DataEditor;
  private subscriptions: Subscription[] = [];
  filterForm: FormGroup;
  private allColumns: ColumnConfig[] = [
    { def: 'select', title: 'Select', show: true },
    { def: 'date', title: 'Date', show: true },
    { def: 'code', title: 'Refrence Number', show: true },
    { def: 'customerCode', title: 'Customer Code', show: true },
    { def: 'name', title: 'Customer Name', show: true },
    // { def: 'route_code', title: 'Route Code', show: true },
    // { def: 'route_name', title: 'Route Name', show: true },
    { def: 'cheque_date', title: 'Cheque Date', show: true },
    { def: 'cheque_number', title: 'Cheque Number', show: true },
    { def: 'mode', title: 'Payment Mode', show: true },
    { def: 'amount', title: 'Amount', show: true },
    { def: 'current_stage', title: 'Approval', show: true },
    // { def: 'approval_status', title: 'Status', show: true },
    { def: 'ERP_status', title: 'Odoo Status', show: true }
  ];
  private collapsedColumns: ColumnConfig[] = [
    { def: 'expand', title: 'Detail', show: true },
  ];
  selectedColumnFilter: string;

  public allResData = [];
  public apiResponse = {
    pagination: {
      total_records: 0,
      total_pages: 0,
    },
  };
  page = 1;
  pageSize = PAGE_SIZE_10;
  requestOriginal: any;
  isOdooMessageOpen: boolean = false;
  @ViewChild("dialogRef") dialogRef: TemplateRef<any>;
  isSyncDialogShow: boolean;
  paggingArray: any[] = [];

  constructor(
    public apiService: ApiService,
    collectionService: CollectionService,
    private masterService: MasterService,
    dataEditor: DataEditor,
    fds: FormDrawerService,
    public fb: FormBuilder,
    private deleteDialog: MatDialog,
    private eventService: EventBusService,
    router: Router,
    private routerParam: ActivatedRoute
  ) {
    Object.assign(this, {
      collectionService,
      dataEditor,
      fds,
      deleteDialog,
      router,
    });
    this.dataSource = new MatTableDataSource<CollectionModel>();
    this.isSyncDialogShow = localStorage.getItem('organisation_id') == '3';
  }

  public ngOnInit(): void {
    let customerDetails = JSON.parse(localStorage.getItem('portalCustomer'));
    this.filterForm = this.fb.group({
      date: [''],
      customer_info_id : [customerDetails.id],
      collection_code: [''],
      customer_code: [''],
      customer_name: [''],
      route_code: [''],
      route_name: [''],
      cheque_date: [''],
      cheque_number: [''],
      payemnt_type: [''],
      current_stage: [''],
      erp_status: [''],
      approval_status: [''],
      page: [this.page],
      page_size: [this.pageSize]
    })

    this.displayedColumns = this.allColumns;
    this.filterColumns = [...[...this.allColumns].splice(1)];
    const portalUsersString = localStorage.getItem('portalCustomer');
    const portalUsers = JSON.parse(portalUsersString);
    // this.filterForm.get('customer_id').setValue(portalUsers.id);
    this.getCollections();

    this.subscriptions.push(
      this.dataEditor.newData.subscribe((value) => {

        if (value.type === CompDataServiceType.GET_NEW_DATA) {
          this.filterObjectId = value.data.id;
          this.getCollections();
        };
        if (value.type === CompDataServiceType.CLOSE_DETAIL_PAGE) {
          this.closeDetailView();
        }
      })
    );
    this.subscriptions.push(
      // this.eventService.on(Events.SEARCH_COLLECTIONS, ({ request, requestOriginal, response }) => {
      //   this.advanceSearchRequest = [];
      //   this.requestOriginal = requestOriginal;
      //   if (request) {
      //     Object.keys(request).forEach(item => {
      //       this.advanceSearchRequest.push({ param: item, value: request[item] })
      //     })
      //   }
      //   this.apiResponse = response;
      //   this.apiResponse.pagination.total_records = response?.pagination?.total_records ?  response?.pagination?.total_records : null;
      //   this.allResData = response.data;
      //   this.updateDataSource(response.data);
      //   //this.dataSource = new MatTableDataSource<CollectionModel>(response.data);
      //   //this.dataSource.paginator = this.paginator;
      // })
      this.eventService.on(Events.SEARCH_COLLECTIONS, ({ request, requestOriginal, response }) => {
        this.advanceSearchRequest = [];
        this.requestOriginal = requestOriginal;
        this.page = 1;
        this.pageSize = 10;
        if (request) {
          Object.keys(request).forEach(item => {
            this.advanceSearchRequest.push({ param: item, value: request[item] })
          })
        }
        this.filterForm = this.fb.group({
          date: [''],
          collection_code: [''],
          customer_code: [''],
          customer_name: [''],
          route_code: [''],
          route_name: [''],
          cheque_date: [''],
          cheque_number: [''],
          payemnt_type: [''],
          current_stage: [''],
          erp_status: [''],
          approval_status: [''],
          collection_status: [''],
          page: [this.page],
          page_size: [this.pageSize],
        })
        if (requestOriginal) {
          Object.keys(requestOriginal).forEach(element => {
            switch (element) {
              case 'customerName':
                this.filterForm.controls['customer_name'].setValue(requestOriginal[element + '']);
                break;
              case 'payment_method':
                this.filterForm.controls['payemnt_type'].setValue(requestOriginal[element + '']);
                break;
              case 'collection_no':
                this.filterForm.controls['collection_code'].setValue(requestOriginal[element + '']);
                break;
              case 'check_number':
                this.filterForm.controls['cheque_number'].setValue(requestOriginal[element + '']);
                break;
              case 'current_stage':
                this.filterForm.controls['approval_status'].setValue(requestOriginal[element + '']);
                break;

              default:
                if (requestOriginal[element + '']) {
                  this.filterForm.addControl(element, new FormControl(requestOriginal[element + '']))
                } else {
                  this.filterForm.addControl(element, new FormControl())
                }
                break;
            }

          });
        }
        this.apiResponse = response;
        this.apiResponse.pagination.total_records = response?.pagination?.total_records ? response?.pagination?.total_records : null;
        this.allResData = response.data;
        this.setPaginationPerPageList(this.apiResponse.pagination.total_records);
        this.updateDataSource(response.data);
      })
    );

    // this.filterForm = new FormGroup({
    //   date: new FormControl(''),
    //   collection_code: new FormControl(''),
    //   customer_code: new FormControl(''),
    //   customer_name: new FormControl(''),
    //   payemnt_type: new FormControl(''),
    //   amount: new FormControl(''),
    //   approval: new FormControl('')
    // })
    this.checkCollectionParamUuid();
  }

  setPaginationPerPageList(total_records) {
    //this.paggingArray = [10, 25, 50, 100, 200, 500];
    if (total_records) {
      this.paggingArray = [];
      if (total_records > 10) this.paggingArray.push(10)
      if (total_records > 25) this.paggingArray.push(25)
      if (total_records > 50) this.paggingArray.push(50)
      if (total_records > 100) this.paggingArray.push(100)
      if (total_records > 200) this.paggingArray.push(200)
      if (total_records > 500) this.paggingArray.push(500)
      this.paggingArray.push(total_records)
    }
    //console.log("pag-arr", this.paggingArray);
  }


  checkCollectionParamUuid() {
    this.routerParam.queryParams.subscribe(res => {
      this.isDetailVisible = false;
      let uuid = res["uuid"];
      if (uuid) {
        this.detailsClosed.emit();
        this.closeDetailView();
        this.collectionService.getCollectionByKey(uuid).subscribe((res) => {
          var dataObj = res.data;
          this.openDetailView(dataObj);
        })
      }
    });
  }

  onColumnFilterOpen(item) {
    this.selectedColumnFilter = item;
  }
  onColumnFilter(status) {
    if (!status) {
      // Find the selected control and reset its value only (not others)
      // this.filterForm.patchValue({ date: null })
      this.filterForm.get(this.selectedColumnFilter).setValue("");
      // return;
    }
    this.filterForm.get('page').setValue(1);
    this.page = 1;
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.paginator = this.paginator;
    //Filter only if he click on yes button
    //add the validation here if selected control has the value 
    this.getCollections();
  }
  filterData() {
    let form = this.filterForm.value;
    const pipe = new DatePipe('en-US');
    if (form.date == "" && form.collection_number == "" && form.customer_code == "" && form.customer_name == ""
      && form.payment_mode == "" && form.amount == "" && form.approval == "") {
      this.dataSource = new MatTableDataSource<CollectionModel>(
        this.collections
      );
      return false;
    }
    let collection = [];
    let column = [];
    Object.keys(form).forEach((keys) => {
      //console.log(keys);
      if (form[keys] !== "") {
        // console.log(keys);
        column.push(keys);
      }
    })
    if (column.length > 1) {
      collection = this.collections.filter((x) => {
        return (x.collection_number.toLocaleLowerCase().includes(form.collection_number)) &&
          (x?.customer['customer_info']['customer_code'].toLocaleLowerCase().includes(form.customer_code)) &&
          (pipe.transform(x['created_at'], 'dd-MM-y') == pipe.transform(form.date, 'dd-MM-y')) &&
          (x?.customer?.firstname.toLocaleLowerCase().includes(form.customer_name) || x?.customer?.lastname.toLocaleLowerCase().includes(form.customer_name)) &&
          (x.payemnt_type == form.payment_mode) &&
          (x['current_stage'].toLowerCase().includes(form.approval))
      })
    } else {
      if (form.collection_number !== "") {
        collection = this.collections.filter((x) => { return x.collection_number.toLocaleLowerCase().includes(form.collection_number) });
      } else if (form.customer_code !== "") {
        collection = this.collections.filter((x) => { return x?.customer['customer_info']['customer_code'].toLocaleLowerCase().includes(form.customer_code) });
      } else if (form.date !== "") {
        collection = this.collections.filter((x) => { return pipe.transform(x['created_at'], 'dd-MM-y') == pipe.transform(form.date, 'dd-MM-y') })
      } else if (form.customer_name !== "") {
        collection = this.collections.filter((x) => { return x?.customer?.firstname.toLocaleLowerCase().includes(form.customer_name) || x?.customer?.lastname.toLocaleLowerCase().includes(form.customer_name) });
      } else if (form.payment_mode !== "") {
        collection = this.collections.filter((x) => { return x.payemnt_type == form.payment_mode });
      } else if (form.approval !== "") {
        collection = this.collections.filter((x) => { return x['current_stage'].toLowerCase().includes(form.approval) });
      } else if (form.amount !== "") {
        collection = this.collections.filter((x) => { return x['invoice_amount'].toLowerCase().includes(form.amount) });
      }
    }
    this.dataSource = new MatTableDataSource<CollectionModel>(collection);
  }
  getCollections() {
    this.subscriptions.push(
      this.masterService.portalCollectionList(this.filterForm.value)
        .subscribe((result) => {
          this.collections = result.data;
          this.apiResponse = result;
          this.allResData = result.data;
          this.setPaginationPerPageList(this.apiResponse.pagination.total_records);
          this.dataSource = new MatTableDataSource<any>(result.data);
          if (this.filterObjectId != null) {
            let filterData = result.data.find(x => x.id === this.filterObjectId);
            this.openDetailView(filterData)
            this.filterObjectId = null;
          };
          // this.dataSource.paginator = this.paginator;
        })
    );
  }

  onPageFired(data) {
    this.page = data['pageIndex'] + 1;
    this.pageSize = data['pageSize'];
    this.filterForm.patchValue({
      page: this.page,
      page_size: this.pageSize
    });
    this.getCollections();
  }

  public getSelectedRows() {
    this.selectedRows.emit(this.selections.selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        changes.newCollectionData &&
        Object.keys(changes.newCollectionData.currentValue).length > 0
      ) {
        let currentValue = changes.newCollectionData.currentValue;
        this.newCollectionData = currentValue;
        this.updateAllData(this.newCollectionData);
      }
    }
  }

  updateAllData(data) {
    this.getCollections();
    this.selections = new SelectionModel(true, []);
    if (data.delete !== undefined && data.delete == true) {
      this.closeDetailView();
    }
    return false;
  }

  updateDataSource(data) {
    this.dataSource = new MatTableDataSource<any>(data);
    // this.dataSource.paginator = this.paginator;
  }

  public ngOnDestroy() {
    Utils.unsubscribeAll(this.subscriptions);
  }

  public openDetailView(data: CollectionModel): void {
    if (this.isOdooMessageOpen) {
      return;
    }
    this.isDetailVisible = true;
    this.updateCollapsedColumns();
    this.itemClicked.emit(data);
  }
  getPaymentType(type) {
    switch (type) {
      case '1':
        return 'Cash';
      case '2':
        return 'Cheque';
      case '3':
        return 'Advance Payment';
      default:
        return 'Unknown';
    }
  }
  public closeDetailView(): void {
    this.isDetailVisible = false;
    this.updateCollapsedColumns();
  }

  public getDisplayedColumns(): string[] {
    return this.displayedColumns
      .filter((column) => column.show)
      .map((column) => column.def);
  }

  public isAllSelected(): boolean {
    return this.selections.selected.length === this.dataSource.data.length;
  }

  public toggleSelection(): void {
    this.isAllSelected()
      ? this.selections.clear()
      : this.dataSource.data.forEach((row) => this.selections.select(row));
  }

  public checkboxLabel(row?: CollectionModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selections.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }

  private updateCollapsedColumns(): void {
    this.displayedColumns = this.isDetailVisible
      ? this.collapsedColumns
      : this.allColumns;

    this.getCollections();
  }

  numberFormat(number) {
    return this.apiService.numberFormatType(number);
  }

  numberFormatWithSymbol(number) {
    return this.apiService.numberFormatWithSymbol(number);
  }

  filterOdooMessageData(data: any) {
    try {
      this.isOdooMessageOpen = false;
      if (data) {
        var obj: any = JSON.parse(data);
        if (obj.data)
          this.deleteDialog.open(this.dialogRef, { data: obj.data.message });

        if (obj.response) {
          var text = "";
          obj.response.forEach(element => {
            element.products.forEach(product => {
              text = `${text} ${product},`
            });
          });
          this.deleteDialog.open(this.dialogRef, { data: text });

        }
      }

    } catch (e) {
      this.deleteDialog.open(this.dialogRef, { data: data });
    }
  }

  postOdoo(collectionData) {
    this.apiService.postCollectionOdooData(collectionData.uuid).subscribe(res => {
      // console.log(res);
      this.getCollections();
    });
  }

  onCloseCriteria() {
    this.advanceSearchRequest = []
    this.eventService.emit(new EmitEvent(Events.CHANGE_CRITERIA, { reset: true, module: Events.SEARCH_COLLECTIONS, route: '/transaction/collection' }));
  }
  onChangeCriteria() {
    this.eventService.emit(new EmitEvent(Events.CHANGE_CRITERIA, { route: '/transaction/collection' }));
  }
}
