import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { CommonToasterService } from 'src/app/services/common-toaster.service';
import { DataEditor } from 'src/app/services/data-editor.service';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { PortalSettingService } from '../portal-setting.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss']
})
export class InviteUserComponent implements OnInit,OnChanges {
  @Input() roleData:any;
  @Output() isReload= new EventEmitter<string>()
  isEdit: boolean;
  userData: any;
  parentCustomers: any[] = [];
  inviteUserFormGroup: FormGroup;
  isSubmitted: boolean = false;
  localData = JSON.parse(localStorage.getItem('portalCustomer'));


  constructor(private fds: FormDrawerService, private portalService :PortalSettingService ,private toaster: CommonToasterService) {
    this.loadForm();
  }
 
  ngOnInit(): void {
    this.inviteUserFormGroup.reset();
    this.inviteUserFormGroup.get('role_id').setValue(62)
  }

  loadForm(){
    this.inviteUserFormGroup = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobile: new FormControl('', Validators.required),
      role_id: new FormControl(62, Validators.required),
      parent_id: new FormControl(''),
      status: new FormControl(1)
    });
  }
  ngOnChanges(){
    // console.log(this.roleData)
  }


  inviteUser() {
    const currentDate = new Date();
    this.isSubmitted = true;
    // this.close();
    // this.reloadInviteUser();
    if (this.inviteUserFormGroup.valid) {
        const requestPayload = {
          firstname : this.inviteUserFormGroup.get('firstname')?.value,
          lastname : this.inviteUserFormGroup.get('lastname')?.value,
          email : this.inviteUserFormGroup.get('email')?.value,
          // password : currentDate.getTime(),
          password : '',
          status : 1,
          mobile : this.inviteUserFormGroup.get('mobile')?.value,
          parent_user_id: this.localData.user_id,
          organisationrole_id: Number(this.inviteUserFormGroup.get('role_id')?.value),
        }
      this.toaster.showSuccess('Success','Succcess'),this.close();
      this.portalService.inviteUser(requestPayload).subscribe(res=>{
        if(res.status=== true){
          this.toaster.showSuccess('Success',res.message),this.close(),this.reloadInviteUser();
        }else{
          this.toaster.showWarning('Warning',res.message),this.close(),this.reloadInviteUser();
        }
      })
    }
    else {
    }
  }

  reloadInviteUser(){
    this.isReload.emit('true');
  }


  close() {
    this.inviteUserFormGroup.reset();
    this.fds.close();
    this.fds.setFormType('user');
  }

}
