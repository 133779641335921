import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Statement } from '@angular/compiler';
import { RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(authService: AuthService) {
    Object.assign(this, { authService });
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const statment = req.url.includes('balance_statement.pdf');
    let url_string = window.location.href;
    if (statment) {
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/pdf',
          Accept: 'application/pdf',
          Authorization: `Bearer ${this.authService.getToken()}`,
          'x-domain': window.location.host,
        },
      });
    } else if (req.url.includes('import')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`,
          'x-domain': window.location.host,
        },
      });
    } else if (req.url.includes('ZGETMIGO_DETAILS_SRV')) {
      req = req.clone({
        setHeaders: {
          'accept': '',

        }
      });
    }
    else if (req.url.includes('http://139.162.41.99/development/dev-msfa/public/api/auth/portal/login')) {
      req = req.clone({
        setHeaders: {
          'x-domain': window.location.host,
        }
      });
    }
    else if (req.url.includes('portal')) {
      // console.log(this.authService.getPortalToken());
      if (req.url.includes('signup') || req.url.includes('login') || req.url.includes('portal/detail') || req.url.includes('portal/detail/update')) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`,
            'x-domain': window.location.host,
          },
        });
      }
      else {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getPortalToken()}`,
            'x-domain': window.location.host,
          },
        });
      }
    } else if (req.url.includes('notifications')) {
      if (this.authService.getPortalToken() && window.location.pathname.includes('portal')) {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getPortalToken()}`,
            'x-domain': window.location.host,
          },
        });
      } else {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getToken()}`,
            'x-domain': window.location.host,
          },
        });
      }
    }
    // else if (req.url.includes('portal') && !req.url.includes('portal/detail') && !req.url.includes('portal/detail/update')) {
    //   req = req.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${localStorage.getItem('portalAccessToken')}`,
    //       'x-domain': window.location.host,
    //     },
    //   });
    // }
    // else if (req.url.includes('portal')) {
    //   if (req.url.includes('signup') || req.url.includes('login')) {
    //     req = req.clone({
    //       setHeaders: {
    //         Authorization: `Bearer ${this.authService.getToken()}`,
    //         'x-domain': window.location.host,
    //       },
    //     });
    //   }
    //   else {
    //     req = req.clone({
    //       setHeaders: {
    //         Authorization: `Bearer ${this.authService.getPortalToken()}`,
    //         'x-domain': window.location.host,
    //       },
    //     });
    //   }
    // }
    else {
      let domain = window.location.host;
      if (domain.split(':')[0] == 'localhost' || domain.split('.')[0] == 'mobiato-msfa') {
        let url_string = window.location.href;
        let appName = sessionStorage.getItem('appName')
        //domain = 'vansales.mobiato-msfa.com'
        if (appName == 'LoyalityManagement' || url_string.includes('Loyality')) {
          domain = 'loyality-management.engear-msfa.com'
        }
        else {
          domain = 'vansales.mobiato-msfa.com'
        }
      }
      // else if(domain.split(':')[0] == 'development') {
      //   let url_string = window.location.href.toLowerCase();
      //   let appName = sessionStorage.getItem('appName')
      //   //domain = 'vansales.mobiato-msfa.com'
      //   if(appName == 'LoyalityManagement' || url_string.includes('loyality')){         
      //   domain = 'loyality-management.engear-msfa.com'
      //   }
      //   else{
      //     domain = 'development.engear-msfa.com'
      //   }
      // }
      // let url_string = window.location.href;
      // if(url_string.includes('my-portal') && req.url.includes('portal/login')){
      //   req = req.clone({
      //     setHeaders: {
      //       'Content-Type': 'application/json; charset=utf-8',
      //       Accept: 'application/json',
      //       'x-domain': domain,
      //       Authorization: `Bearer ${this.authService.getSessionToken()}`,
      //     },
      //   });
      // }
      // else{
      req = req.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf-8',
          Accept: 'application/json',
          'x-domain': domain,
          Authorization: `Bearer ${this.authService.checkPortal() ? this.authService.getPortalToken() : this.authService.getToken()}`,
        },
      });
      //}
    }
    return next.handle(req);
  }
}
