import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { formatDate, DatePipe } from '@angular/common';
import { Subscription, Subject, of } from 'rxjs';
import { CommonToasterService } from 'src/app/services/common-toaster.service';
import {
  OrderModel,
  ItemAddTableHeader,
  OrderType,
  OrderItemsPayload,
} from '../order-models';
import {
  getCurrency,
  getCurrencyDecimalFormat,
} from 'src/app/services/constants';
import { PaymentTerms } from 'src/app/components/dialogs/payementterms-dialog/payementterms-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { DataEditor } from 'src/app/services/data-editor.service';
import { MatDialog } from '@angular/material/dialog';
import { Utils } from 'src/app/services/utils';
import { OrderService } from '../order.service';
import * as moment from 'moment';
import { ItemUoms } from 'src/app/components/main/settings/item/item-uom/itemuoms-dt/itemuoms-dt.component';
import { BranchDepotMaster } from 'src/app/components/main/settings/location/branch/branch-depot-master-dt/branch-depot-master-dt.component';
import { SalesMan } from 'src/app/components/main/master/salesman/salesman-dt/salesman-dt.component';
import { MasterService } from 'src/app/components/main/master/master.service';
import { PAGE_SIZE_10 } from 'src/app/app.constant';
import { Customer } from 'src/app/components/main/master/customer/customer-dt/customer-dt.component';
import { Item } from 'src/app/components/main/master/item/item-dt/item-dt.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { environment } from 'src/environments/environment';
import { PaymentConfirmComponent } from '../../payment-pages/payment-confirm/payment-confirm.component';
@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
})
export class OrderFormComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  handler: any = null;
  displayedColumns: string[] = [
    'sr',
    'itemCode',
    'itemName',
    'quantity',
    'price',
    'excise',
    'net',
    'vat',
    'total',
  ];
  public customerDetails: any;
  public customerItemsData: any;
  public tempcustomerItemsData: any;
  public selectedItems: any[] = [];
  public customerDueText: string;
  public customerDueDate: any;
  public cutomerTotalNET: number = 0;
  public customerTotalVAT: number = 0;
  public customerTotalExcise: number = 0;
  public customerTotal: number = 0;
  public customerSelectedItemTotalQuantity: number = 0;
  public searchForm: FormGroup;
  public searchItemCode = false;
  public searchItemName = false;
  public selectedItemcode = false;
  public selectedItemname = false;
  public paymentInitiated = false;
  public displayPaymentConfirmModal = false;

  public todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  public dueDateSet: any;
  public pageTitle: string;
  public isEditForm: boolean;
  public isDeliveryForm: boolean;
  public uuid: string = '';
  public isDepotOrder: boolean;
  public orderNumber: string = '';
  public filterCustomer: any = [
    // {name:'jay Jawarkar' ,id:1},
    // {name:'tim cook' ,id:2},
    // {name:'arpit' ,id:3},
    // {name:'ajay' ,id:4},
  ];
  public formSubmit = false;
  public tempfilterCustomer: any = [];
  public selectedCustomerId = 0;
  public selectedCustomer: any;
  public parentCustomer = [];
  public isPayment = false;
  public filterCheck = [
    { name: 'all', checked: true, label: 'All Items' },
    { name: 'selected', checked: false, label: 'Selected Items' },
  ];
  public currentCheck: string = 'all';
  public lookup$: Subject<any> = new Subject();
  public itemlookup$: Subject<any> = new Subject();
  domain = window.location.host.split('.')[0];
  public orderData?: OrderModel;
  public objectValues = Object.values;
  public currencyCode = getCurrency();
  public currencyDecimalFormat = getCurrencyDecimalFormat();
  public orderFinalStats: {
    [key: string]: { label: string; value: number };
  } = {
    total_gross: { label: 'Gross Total', value: 0 },
    // total_discount_amount: { label: 'Discount', value: 0 },
    total_net: { label: 'Net Total', value: 0 },
    // total_excise: { label: 'Excise', value: 0 },
    total_vat: { label: 'Vat', value: 0 },
    grand_total: { label: 'Total', value: 0 },
  };
  public deliveryFinalStats: {
    [key: string]: { label: string; value: number };
  } = {
    total_gross: { label: 'Gross Total', value: 0 },
    total_vat: { label: 'Vat', value: 0 },
    total_excise: { label: 'Excise', value: 0 },
    total_net: { label: 'Net Total', value: 0 },
    total_discount_amount: { label: 'Discount', value: 0 },
    grand_total: { label: 'Invoice Total', value: 0 },
  };
  public isEditChangeFirst = true;
  public orderFormGroup: FormGroup;
  public orderTypeFormControl: FormControl;
  public customerFormControl: FormControl;
  public itemControls: FormControl;
  public customerLobFormControl: FormControl;
  public depotFormControl: FormControl;
  public salesmanFormControl: FormControl;
  public warehouseFormControl: FormControl;
  public noteFormControl: FormControl;
  public paymentTermFormControl: FormControl;
  public dueDateFormControl: FormControl;
  public deliveryDateFormControl: FormControl;
  public deliveryTimeFormControl: FormControl;
  public customerLPOFormControl: FormControl;
  public customerLocationFormControl: FormControl;
  // public companyFormControl: FormControl;
  public currentDate: any;
  public companyList: any = [
    { id: '1101', name: 'Barakat Quality Plus' },
    { id: '1102', name: 'Barakat Vegetables & Fruits Co' },
  ];
  public itemTableHeaders: ItemAddTableHeader[] = [];

  public orderTypes: OrderType[] = [];
  public items: Item[] = [];
  public filteredItems: Item[] = [];
  public uoms: ItemUoms[] = [];
  public depots: BranchDepotMaster[] = [];
  public salesmen: SalesMan[] = [];
  public terms: PaymentTerms[] = [];
  public payloadItems: OrderItemsPayload[] = [];
  public selectedPayloadItems: OrderItemsPayload[] = [];

  public customers: Customer[] = [];

  public selectedOrderTypeId: number;
  public selectedOrderType: OrderType;
  public selectedDepotId: number;
  public selectedSalesmanId: number;
  public selectedPaymentTermId: number;
  public showConvertToDelivery: boolean = true;
  private router: Router;
  private apiService: ApiService;
  private masterService: MasterService;
  private dataService: DataEditor;
  private subscriptions: Subscription[] = [];
  private itemNameSubscriptions: Subscription[] = [];
  private itemControlSubscriptions: Subscription[] = [];
  private route: ActivatedRoute;
  private formBuilder: FormBuilder;
  private dialogRef: MatDialog;
  private elemRef: ElementRef;
  private finalOrderPayload: any = {};
  private finalDeliveryPayload: any = {};
  public nextCommingNumberofOrderCode: string;
  public is_lob: boolean = false;
  public selectedUOMs: number;
  orderNumberPrefix: any;
  customerLobList = [];
  creditLimit;
  filterValue = '';
  itemfilterValue = '';
  addressList: any = [];
  public page = 0;
  public pageEndNumber = 0;
  public itempage = 1;
  public page_size = PAGE_SIZE_10;
  public total_pages = 0;
  public item_total_pages = 0;
  public freeItems = false;
  private noFirstReqInEdit = false;
  public isLoading: boolean;
  isCustomerlobShow: boolean = false;
  keyUp = new Subject<string>();
  keyUpItem = new Subject<string>();
  storageLocationList: any[] = [];
  nextCommingDeliveryCode: any;
  deliveryNumberPrefix: any;
  deliveryNumber: any;
  customer: any;
  currentTime: any;
  timeslot: any = [
    { id: '1', name: '6:00 am to 10:00 am' },
    { id: '2', name: '4:00 pm to 8:00 pm' },
  ];
  constructor(
    private toaster: CommonToasterService,
    private datePipe: DatePipe,
    private orderService: OrderService,
    apiService: ApiService,
    public dialog: MatDialog,
    dataService: DataEditor,
    dialogRef: MatDialog,
    elemRef: ElementRef,
    formBuilder: FormBuilder,
    router: Router,
    route: ActivatedRoute,
    masterService: MasterService,
    private CommonToasterService: CommonToasterService
  ) {
    Object.assign(this, {
      apiService,
      masterService,
      dataService,
      dialogRef,
      elemRef,
      formBuilder,
      router,
      route,
      dialog,
    });
  }

  async ngOnInit() {
    // window.addEventListener('scroll', this.scrollEvent, true);
    // For Getting Tommorows date

    // const todayDate = new Date();
    // todayDate.setDate(todayDate.getDate() + 1);
    // this.currentDate = moment(todayDate).format('YYYY-MM-DD');

    const currentDateandTime = new Date();
    const timestamp = currentDateandTime.getHours();
    const todayDate = new Date();
    if (timestamp >= 17) {
      todayDate.setDate(todayDate.getDate() + 2);
      this.currentDate = moment(todayDate).format('YYYY-MM-DD');
    } else {
      todayDate.setDate(todayDate.getDate() + 1);
      this.currentDate = moment(todayDate).format('YYYY-MM-DD');
    }

    // For Getting Current Time
    const currentDate = new Date();
    this.currentTime = moment(currentDate).format('HH:mm');

    this.customerDetails = JSON.parse(localStorage.getItem('portalCustomer'));
    // First instance
    this.loadForm();
    this.loadSearchForm();

    this.isEditForm =
      this.router.url.includes('portal/order/edit/') ||
      this.router.url.includes('portal/order/re-order/');
    if (!this.isEditForm) {
      this.loadCutomerDetails(this.customerDetails.id);
      console.log(this.customerDetails)
      this.apiService.callLocation({CustomerAccountNumber:this.customerDetails.customer_code}).subscribe((res:any)=>{
        let locData = []
        res.map((loc:any)=>
        {
          locData.push({
           id:loc,
           name:loc
          })
      })

      this.locationsData = locData


    })
    }
    this.apiService.callLocation({CustomerAccountNumber:this.customerDetails.customer_code}).subscribe((res:any)=>{
      let locData = []
      res.map((loc:any)=>
      {
        locData.push({
         id:loc,
         name:loc
        })
    })

    this.locationsData = locData


  })
    this.orderData = this.route.snapshot.data['order'];
    this.itemTableHeaders = ITEM_ADD_FORM_TABLE_HEADS;
    // this.orderTypeFormControl = new FormControl(this.selectedOrderTypeId, [
    //   Validators.required,
    // ]);

    this.orderFormGroup = this.formBuilder.group({
      order_type_id: 2,
      payment_term_id: this.paymentTermFormControl,
      depot_id: this.depotFormControl,
      salesman_id: this.salesmanFormControl,
      any_comment: this.noteFormControl,
      due_date: this.dueDateFormControl,
      delivery_date: this.deliveryDateFormControl,
      delivery_time: this.deliveryTimeFormControl,
      receiving_location: this.customerLocationFormControl,
      customer_name: this.customerFormControl,
      items: this.initItemFormArray(),
      // company: this.companyFormControl
    });

    const model = {
      customer_info_id: this.customerDetails.id,
      // customer_id: 46,
    };
    this.orderService.customerInfo(model).subscribe((res) => {
      const customer = res.data.customer;
      this.selectedCustomer = res.data.customer;
      this.addressList = customer.customeraddress?.map((i) => ({
        id: i.address,
        name: i.address,
      }));
      if (!this.isEditForm) {
        // let customer = JSON.parse(localStorage.getItem('portalCustomer'));
        // this.customerFormControl.setValue(customer.customer_code + ' ' + customer.user.firstname + '' + customer.user.lastname);
        this.customerFormControl.setValue([
          {
            id: customer?.id,
            itemName:
              customer.customer_code +
              ' ' +
              customer.user.firstname +
              ' ' +
              customer.user.lastname,
          },
        ]);

        // this.addressList.push({ id: '1', name: customer.customer_address_1 });
        // this.customerLocationFormControl.setValue(customer.customer_address_1);
        const findAddress = customer.customeraddress?.find((i) =>
          i.address.includes(customer.customer_address_1)
        );
        // this.customerLocationFormControl.setValue([
        //   {
        //     id: findAddress?.id,
        //     itemName: findAddress?.address,
        //   },
        // ]);
        this.customerLocationFormControl.setValue(
          findAddress
            ? [
                {
                  id: findAddress?.address,
                  itemName: findAddress?.address,
                },
              ]
            : [{ id: customer.customer_address_1 , itemName: customer.customer_address_1 }]
        );
        this.customer = customer;
        this.selectedPaymentTermId = customer.payment_term_id;

        // this.getCustomerLobList(this.customer);
        if (customer?.customer_type?.customer_type_name !== 'Cash') {
          this.customerDates(customer);
          this.paymentTermFormControl.setValue(customer.payment_term?.name);
        } else {
          this.paymentTermFormControl.clearValidators();
          this.paymentTermFormControl.updateValueAndValidity();
          this.dueDateFormControl?.setValue(this.deliveryDateFormControl.value);
        }
      }

      //Get Parent
      const filterModel = {
        firstname: customer.user.firstname,
        lastname: customer.user.lastname,
        role_id: customer.user.role_id,
        status: customer.user.status,
        id: customer.id,
        usertype: customer.user.usertype,
        uuid: customer.uuid,
        country_id: customer.user.country_id,
        customer_code: customer.customer_code,
        customer_address_1: customer.customer_address_1,
        payment_name: customer.payment_term?.name,
        customer_type_id: customer.customer_type_id,
        payment_term_id: customer.payment_term_id,
      };
      this.parentCustomer.push(filterModel);
      this.getChildCustomer();
      //
      if (this.isEditForm) {
        this.noFirstReqInEdit = true;
        this.uuid = this.route.snapshot.params.uuid;
        this.pageTitle = this.isEditForm ? 'Edit Order' : 'Customize Delivery';
        this.orderData = this.route.snapshot.data['order'];
        this.setupEditFormControls(this.orderData);
      } else {
        this.pageTitle = 'Add Order';
      }
    });
    console.log(this.locationsData,"jkl")




}
  // scrollEvent = (event: any): void => {
  //   if (this.autoComplete.panelOpen) {
  //     // this.autoComplete.closePanel();
  //     this.autoComplete.updatePosition();
  //   }
  // }
  loadForm() {
    this.depotFormControl = new FormControl(this.selectedDepotId);
    this.salesmanFormControl = new FormControl(this.selectedSalesmanId);
    this.warehouseFormControl = new FormControl('');
    this.paymentTermFormControl = new FormControl(this.selectedPaymentTermId, [
      Validators.required,
    ]);
    this.customerFormControl = new FormControl('', [Validators.required]);
    this.itemControls = new FormControl('');
    this.customerLobFormControl = new FormControl('');
    this.noteFormControl = new FormControl('');
    this.dueDateFormControl = new FormControl('', [Validators.required]);
    this.deliveryDateFormControl = new FormControl(this.currentDate, [
      Validators.required,
    ]);
    this.deliveryTimeFormControl = new FormControl('', [Validators.required]);
    this.customerLPOFormControl = new FormControl('');
    this.customerLocationFormControl = new FormControl('', [
      Validators.required,
    ]);
    // this.companyFormControl = new FormControl('', [Validators.required]);
  }

  changesinTime(data) {
    if (data !== null) {
      if (data.split(':').length > 2) {
        return data.split(':')[0] + ':' + data.split(':')[1];
      } else {
        return data;
      }
    }
  }

  dateInFormate(date) {
    const dateChanges = new Date(date);
    return moment(dateChanges).format('YYYY-MM-DD');
  }
  findTimeSlot(value) {
    if (value) {
      return this.timeslot.find((i) => i.name.includes(value))?.id;
    }
  }
  public setupEditFormControls(editData: any): void {
    const name =
      editData.customerObj.user.customer_info.customer_code +
      ' ' +
      editData.customerObj.user.firstname +
      ' ' +
      editData.customerObj.user.lastname;
    this.deliveryTimeFormControl.setValue([
      {
        id: this.findTimeSlot(editData.receiving_time),
        itemName: editData.receiving_time,
      },
    ]);
    this.deliveryDateFormControl.setValue(
      this.dateInFormate(editData.delivery_date)
    );
    this.customerLPOFormControl.setValue(editData.customer_lop);
    const findAddress = this.addressList?.find((i) =>
      i.name.includes(editData.receiving_location)
    );
    console.log(editData.receiving_location,"findAddress?.name")
    this.customerLocationFormControl.setValue([
      {
        id: editData.receiving_location,
        itemName: editData.receiving_location,
      },
    ]);
    // this.customerLocationFormControl.setValue([
    //   { id: '1', itemName: editData.receiving_location },
    // ]);
    this.noteFormControl?.setValue(editData.any_comment);
    this.customerFormControl.setValue([
      {
        id: editData?.customerObj?.id,
        itemName:
          editData.customerObj.user.customer_info.customer_code +
          ' ' +
          editData.customerObj.user.firstname +
          ' ' +
          editData.customerObj.user.lastname,
      },
    ]);
    this.dueDateFormControl.setValue(this.dateInFormate(editData.due_date));
    if (editData?.customerObj?.user?.customer_info?.customer_type_id !== 2) {
      this.paymentTermFormControl.setValue(editData.payment_term_info.name);
    } else {
      this.paymentTermFormControl.clearValidators();
      this.paymentTermFormControl.updateValueAndValidity();
    }
    this.cutomerTotalNET = editData.total_net;
    this.customerTotalVAT = editData.total_vat;
    this.customerTotal = editData.grand_total;
    this.customerTotalExcise = editData.total_excise;
    this.loadCutomerDetails(editData.customerObj.id);
    // console.log(editData)
    // const model = {
    //   customer_info_id: editData,
    //   // customer_id: 46,
    // };
    // this.orderService.customerInfo(model).subscribe(res => {

    // })
  }

  //
  locationsData:any = []
  onCustomerSelect(event: any) {
    this.isEditChangeFirst = false;
    const selectedCustomer = event;
    console.log(selectedCustomer)
    this.apiService.callLocation({CustomerAccountNumber:event.itemName.split(" ")[0]}).subscribe((res)=>{
      let lockData = []
      res.map((loc:any)=>{
        lockData.push({
          id:loc,
          name:loc
         })
       })
       this.locationsData = lockData

      console.log(this.locationsData,res,"jkll")
})
    console.log(this.tempfilterCustomer,"mlk")
    // this.customerFormControl.setValue(data.customer_code + ' ' + data.firstname + ' ' + data.lastname);
    const data = this.tempfilterCustomer.find(
      (i) => i.id == selectedCustomer.firstname
    );
    this.customerFormControl.setValue([
      {
        id: data?.id,
        itemName:
          data.customer_code + ' ' + data.firstname + ' ' + data.lastname,
      },
    ]);
    this.selectedCustomerId = data.id;
    this.selectedCustomer = data;
    const findAddress = this.addressList.find((i) =>
      i.name.includes(data.customer_address_1)
    );
    this.customerLocationFormControl.setValue([
      {
        id: findAddress.id,
        itemName: findAddress.name,
      },
    ]);
    // this.customerLocationFormControl.setValue([
    //   { id: '1', itemName: data.customer_address_1 },
    // ]);
    const model = {
      customer_id: data.id,
      is_contrast: 1,
    };
    this.loadCustomerItems(model);
    if (data.customer_type_id === 1) {
      let paymentModel = {
        payment_term: { id: data.payment_term_id, name: data.payment_name },
      };
      this.customerDates(paymentModel);
    } else {
      this.paymentTermFormControl.clearValidators();
      this.paymentTermFormControl.updateValueAndValidity();
      this.paymentTermFormControl.setValue('');
      this.dueDateFormControl.setValue(this.deliveryDateFormControl.value);
    }
  }

  loadCutomerDetails(customerId) {
    this.selectedCustomerId = customerId;
    const model = {
      customer_id: customerId,
      is_contrast: 1,
    };
    this.loadCustomerItems(model);
  }

  loadCustomerItems(model) {
    this.orderService.customer_items(model).subscribe((res) => {
      this.customerItemsData = new MatTableDataSource<any>(res.data);
      this.customerItemsData.paginator = this.paginator;
      // this.customerItemsData.filteredData = this.customerItemsData.filteredData.filter((value, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.item_id === value.item_id
      //   ))
      // );
      this.customerItemsData.filteredData.forEach((item: any, index: any) => {
        item.index = index + 1;
        item.item_quantity = 0;
        item.item_Net = 0;
        item.item_VAT = 0;
        item.item_total = 0;
        item.item_excise = +item.item_excise;
        item.price = Number(item.price).toFixed(2);
        if (this.isEditForm) {
          this.orderData?.items.forEach((element) => {
            if (element.item_id == item.item_id) {
              item.price = Number(element.item_price).toFixed(2);
              item.item_quantity = Number(element.item_qty);
              item.item_Net = element.item_net;
              item.item_VAT = element.item_vat;
              item.item_total = element.item_grand_total;
            }
          });
        }
      });
      if (this.router.url.includes('portal/order/re-order/')) {
        this.currentCheck = 'selected';
        let selected = this.customerItemsData.filteredData.filter(
          (item) => item.item_quantity > 0
        );
        this.customerItemsData = new MatTableDataSource<any>(selected);
        this.customerItemsData.paginator = this.paginator;
        this.customerItemsData.filteredData.forEach((item: any, index: any) => {
          item.index = index + 1;
        });
      }
      this.tempcustomerItemsData = res.data;
      //  }
    });
  }

  getChildCustomer() {
    const model = {
      customer_id: this.customerDetails.id,
      // customer_id: 46,
    };
    this.orderService.child_customer(model).subscribe((res) => {
      // console.log(res.data)
      let customer = res.data;
      customer = res.data.map((i) => ({
        id: i.id,
        name: i.customer_code + ' - ' + i.firstname + ' ' + i.lastname,
      }));
      const parentCustomer = this.parentCustomer.map((i) => ({
        id: i.id,
        name: i.customer_code + ' - ' + i.firstname + ' ' + i.lastname,
      }));
      this.filterCustomer = [...parentCustomer, ...customer];
      this.tempfilterCustomer = [...this.parentCustomer, ...res.data];
    });
  }

  filterSearchCustomer(event: any) {
    const firstname = event.target.value;
    const searchedValue = firstname ? firstname.toLowerCase().trim() : '';
    this.filterCustomer = this.tempfilterCustomer.filter(
      (state) =>
        state.customer_code.toLowerCase().trim().includes(searchedValue) ||
        state.firstname.toLowerCase().trim().includes(searchedValue) ||
        state.lastname.toLowerCase().trim().includes(searchedValue)
    );
  }

  loadSearchForm() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      searchedTerm: new FormControl(''),
    });
  }

  filter(value: string) {
    if (value === 'code') {
      this.searchItemCode = !this.searchItemCode;
      this.searchItemName = false;
      if (this.searchForm.get('searchedTerm').value !== 'item_code') {
        this.searchForm.get('search').setValue('');
      }
    } else {
      this.searchItemName = !this.searchItemName;
      this.searchItemCode = false;
      if (this.searchForm.get('searchedTerm').value !== 'item_name') {
        this.searchForm.get('search').setValue('');
      }
    }
  }

  closeSearchInput() {
    this.selectedItemcode = false;
    this.selectedItemname = false;
    this.filterCheck[0].checked = true;
    this.filterCheck[1].checked = false;
    this.currentCheck = 'all';
    this.searchForm.get('search').setValue('');
    this.searchItemCode = false;
    this.searchItemName = false;
    this.customerItemsData = new MatTableDataSource<any>(
      this.tempcustomerItemsData
    );
    this.customerItemsData.paginator = this.paginator;
    this.customerItemsData.filteredData.forEach((item: any, index: any) => {
      item.index = index + 1;
    });
  }

  checkSearchInput(value: string) {
    this.searchItemCode = false;
    this.searchItemName = false;
    this.searchForm.get('searchedTerm').setValue(value);
    this.customerItemsData = new MatTableDataSource<any>(
      this.switchSearchInput(value)
    );
    this.customerItemsData.paginator = this.paginator;
    this.customerItemsData.filteredData.forEach((item: any, index: any) => {
      item.index = index + 1;
    });
    if (value === 'item_code') {
      this.selectedItemcode = true;
      this.selectedItemname = false;
    } else {
      this.selectedItemcode = false;
      this.selectedItemname = true;
    }
  }

  switchSearchInput(value) {
    switch (value) {
      case 'item_code': {
        return this.tempcustomerItemsData.filter((item) =>
          item.item_code.startsWith(this.searchForm.get('search')?.value)
        );
      }
      case 'item_name': {
        return this.tempcustomerItemsData.filter((item) =>
          item.item_name
            .toLocaleLowerCase()
            .includes(this.searchForm.get('search')?.value?.toLocaleLowerCase())
        );
      }
      default: {
        return this.tempcustomerItemsData;
      }
    }
  }

  handleChange(value: string) {
    this.currentCheck = value;
    if (value === 'all') {
      this.customerItemsData = new MatTableDataSource<any>(
        this.tempcustomerItemsData
      );
      this.customerItemsData.paginator = this.paginator;
      this.customerItemsData.filteredData.forEach((item: any, index: any) => {
        item.index = index + 1;
      });
    } else {
      let selected = this.customerItemsData.filteredData.filter(
        (item) => item.item_quantity > 0
      );
      this.customerItemsData = new MatTableDataSource<any>(selected);
      this.customerItemsData.paginator = this.paginator;
      this.customerItemsData.filteredData.forEach((item: any, index: any) => {
        item.index = index + 1;
      });
    }
  }

  customerTotals(customer: any) {
    // console.log(customer);
    const filterByQunatity = customer.filter((item) => item.item_quantity > 0);
    this.cutomerTotalNET = customer
      .map((a) => a.item_Net)
      .reduce((a, b) => {
        return a + b;
      });
    this.customerTotalVAT = customer
      .map((a) => a.item_VAT)
      .reduce((a, b) => {
        return a + b;
      });
    this.customerTotalExcise =
      filterByQunatity.length > 0
        ? filterByQunatity
            .map((a) => a.item_excise)
            ?.reduce((a, b) => {
              return Number(a) + Number(b);
            })
        : 0.0;
    this.customerSelectedItemTotalQuantity = customer
      .map((a) => +a.item_quantity)
      .reduce((a, b) => {
        return a + b;
      });
    this.customerTotal =
      this.cutomerTotalNET + this.customerTotalVAT + this.customerTotalExcise;
  }
  changeDelivery() {
    if (this.isEditChangeFirst === true && this.isEditForm === true) {
      if (
        this.orderData?.customerObj?.user?.customer_info?.customer_type_id === 1
      ) {
        const payment = {
          payment_term: {
            name: this.paymentTermFormControl.value,
          },
        };
        this.customerDates(payment);
      } else {
        this.paymentTermFormControl.clearValidators();
        this.paymentTermFormControl.updateValueAndValidity();
        this.paymentTermFormControl.setValue('');
        this.dueDateFormControl.setValue(this.deliveryDateFormControl.value);
      }
    } else {
      if (this.selectedCustomer.customer_type_id === 1) {
        const payment = {
          payment_term: {
            name: this.paymentTermFormControl.value,
          },
        };
        this.customerDates(payment);
      } else {
        this.paymentTermFormControl.clearValidators();
        this.paymentTermFormControl.updateValueAndValidity();
        this.paymentTermFormControl.setValue('');
        this.dueDateFormControl.setValue(this.deliveryDateFormControl.value);
      }
    }
  }

  customerDates(customer) {
    // console.log(customer)
    if (customer.payment_term) {
      this.customerDueText = customer.payment_term?.name;
      const num = this.customerDueText?.replace(/[^0-9]/g, '');
      let date = new Date(this.deliveryDateFormControl.value);
      date.setDate(date.getDate() + Number(num));
      this.customerDueDate = moment(date).format('YYYY-MM-DD');
      this.paymentTermFormControl.setValue(customer.payment_term?.name);
      this.dueDateFormControl?.setValue(moment(date).format('YYYY-MM-DD'));
    } else {
      this.paymentTermFormControl.setValue('');
      let defaultdate = new Date(this.deliveryDateFormControl.value);
      defaultdate.setDate(defaultdate.getDate() + 1);
      this.dueDateFormControl?.setValue(
        moment(defaultdate).format('YYYY-MM-DD')
      );
    }
  }

  check(event: any) {
    const specialKeys: Array<string> = ['Backspace', 'Tab'];
    if (specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    let regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    const current: string = event.target.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  changeInPrice(event: any, element: any) {
    let value = event.target.value;
    element.price = +value;
    if (element.item_quantity > 0) {
      element.item_Net = +this.natChange(element.price, element.item_quantity);
      element.item_VAT = +this.vatChange(element.item_Net);
      element.item_total = +this.totalChange(
        element.item_Net,
        element.item_VAT,
        +element.item_excise
      );
      this.customerTotals(this.customerItemsData.filteredData);
    }
  }

  formatNumber(event) {
    let value = event.value;
    event.value = Number(value).toFixed(2);
  }

  changeInQuantity(event: any, item: any) {
    let value = event.target.value;
    item.item_quantity = +value;
    item.item_Net = +this.natChange(item.price, value);
    item.item_VAT = +this.vatChange(item.item_Net);
    item.item_total = +this.totalChange(
      item.item_Net,
      item.item_VAT,
      +item.item_excise
    );
    this.customerTotals(this.customerItemsData.filteredData);
  }

  loadSelectedItems() {
    this.selectedItems = [];
    for (let i = 0; i < this.customerItemsData.filteredData.length; i++) {
      if (
        this.customerItemsData.filteredData[i].item_quantity > 0 &&
        Number(this.customerItemsData.filteredData[i].price) > 0
      ) {
        const customerItem = this.customerItemsData.filteredData[i];
        const model = {
          item: {
            id: customerItem.item_id,
            name: customerItem.item_name,
            item_code: customerItem.item_code,
          },
          item_id: customerItem.item_id,
          item_code: customerItem.item_code,
          item_qty: customerItem.item_quantity,
          item_uom_id: customerItem.uoms_id,
          discount_id: null,
          promotion_id: null,
          is_free: false,
          is_item_poi: false,
          item_price: Number(customerItem.price),
          item_discount_amount: 0,
          item_vat: customerItem.item_VAT,
          item_net: customerItem.item_Net,
          total_net: 0,
          item_excise: customerItem.item_excise,
          total_excise: 0,
          item_grand_total: customerItem.item_total,
          item_gross: customerItem.item_total,
        };
        this.selectedItems.push(model);
      }
    }
  }

  confirmPayment() {
    this.displayPaymentConfirmModal = true;
  }

  openDialog(): void {
    this.formSubmit = true;
    this.loadSelectedItems();
    if (this.orderFormGroup.valid) {
      if (this.selectedItems.length === 0) {
        this.CommonToasterService.showError(
          'Error',
          'Please add atleast one items'
        );
      } else if (this.customerTotal < 125) {
        this.toaster.showError(
          'Error',
          'The minimum order value must be AED 125 to proceed'
        );
      }

      // if ((addmodel || editModel) && this.selectedItems.length !== 0) {
      else if (this.selectedItems.length !== 0) {
        let addmodel = {
          customer_id: this.selectedCustomerId,
          userName: this.customerFormControl.value,
          lob_id: '4',
          storage_location_id: '',
          order_type_id: 2,
          payment_term_id: this.selectedCustomer.payment_term_id,
          depot_id: null,
          salesman_id: null,
          any_comment: this.noteFormControl?.value,
          due_date: new DatePipe('en-US').transform(
            this.dueDateFormControl.value,
            'yyyy-MM-dd'
          ),
          delivery_date: new DatePipe('en-US').transform(
            this.deliveryDateFormControl.value,
            'yyyy-MM-dd'
          ),
          receiving_time: this.deliveryTimeFormControl.value[0].itemName,
          receiving_location: this.customerLocationFormControl.value[0]
            .itemName,
          items: this.selectedItems,
          total_gross: this.cutomerTotalNET,
          total_discount_amount: 0,
          total_net: this.cutomerTotalNET,
          total_excise: this.customerTotalExcise,
          total_vat: this.customerTotalVAT,
          grand_total: this.customerTotal,
          order_number: this.isEditForm
            ? this.orderData.order_number
            : 'abc12377',
          source: 2,
          total_qty: this.selectedItems.length,
          customer_lop: this.customerLPOFormControl.value,
          // company: this.companyFormControl.value
        };
        let editModel = {
          customer_id: this.selectedCustomerId,
          userName: this.customerFormControl.value,
          lob_id: '4',
          storage_location_id: '',
          order_type_id: 2,
          payment_term_id: this.selectedCustomer.payment_term_id,
          depot_id: null,
          salesman_id: null,
          any_comment: this.noteFormControl?.value,
          due_date: new DatePipe('en-US').transform(
            this.dueDateFormControl.value,
            'yyyy-MM-dd'
          ),
          delivery_date: new DatePipe('en-US').transform(
            this.deliveryDateFormControl.value,
            'yyyy-MM-dd'
          ),
          receiving_time: this.deliveryTimeFormControl.value[0].itemName,
          receiving_location: this.customerLocationFormControl.value[0]
            .itemName,
          items: this.selectedItems,
          total_gross: this.cutomerTotalNET,
          total_discount_amount: 0,
          total_net: this.cutomerTotalNET,
          total_excise: this.customerTotalExcise,
          total_vat: this.customerTotalVAT,
          grand_total: this.customerTotal,
          order_number: this.isEditForm
            ? this.orderData.order_number
            : 'abc12377',
          source: 2,
          total_qty: this.selectedItems.length,
          customer_lop: this.customerLPOFormControl.value,
          uuid: this.isEditForm == true ? this.orderData.uuid : null,
          // approval_status: 'created'
          // company: this.companyFormControl.value
        };
        this.dialog.open(PaymentConfirmComponent, {
          width: '500px',
          data: {
            originalData: this.isEditForm == true ? editModel : addmodel,
            isEditData: this.isEditForm,
            type: 'order',
          },
        });
      }
    }
  }

  pay() {
    this.formSubmit = true;
    if (this.orderFormGroup.valid) {
      var handler = (<any>window).StripeCheckout.configure({
        key: environment.payment_key,
        locale: 'auto',
        currency: 'inr',
        token: (token: any) => {
          // You can access the token ID with `token.id`.
          // Get the token ID to your server-side code for use.
          console.log(token);
          this.post();
          // alert('Token Created!!');
        },
      });

      handler.open({
        name: 'Demo Site',
        description: '2 widgets',
        amount: this.customerTotal * 100,
      });
    }
  }

  post() {
    this.formSubmit = true;
    this.loadSelectedItems();
    if (this.orderFormGroup.valid) {
      if (this.selectedItems.length === 0) {
        this.CommonToasterService.showError(
          'Error',
          'Please add atleast one items'
        );
      } else {
        let model = {
          customer_id: this.selectedCustomerId,
          lob_id: '4',
          storage_location_id: '',
          order_type_id: 2,
          payment_term_id: this.selectedCustomer.payment_term_id,
          depot_id: null,
          salesman_id: null,
          any_comment: this.noteFormControl?.value,
          due_date: new DatePipe('en-US').transform(
            this.dueDateFormControl.value,
            'yyyy-MM-dd'
          ),
          delivery_date: new DatePipe('en-US').transform(
            this.deliveryDateFormControl.value,
            'yyyy-MM-dd'
          ),
          receiving_time: this.deliveryTimeFormControl.value[0].itemName,
          receiving_location: this.customerLocationFormControl.value[0]
            .itemName,
          items: this.selectedItems,
          total_gross: this.cutomerTotalNET,
          total_discount_amount: 0,
          total_net: this.cutomerTotalNET,
          total_excise: this.customerTotalExcise,
          total_vat: this.customerTotalVAT,
          grand_total: this.customerTotal,
          order_number: this.isEditForm
            ? this.orderData.order_number
            : 'abc12377',
          source: 2,
          total_qty: this.selectedItems.length,
          customer_lop: this.customerLPOFormControl.value,
          // approval_status: 'created',
          // company: this.companyFormControl.value
        };
        if (this.isEditForm == true) {
          this.orderService
            .editOrder(this.orderData.uuid, model)
            .subscribe((res) => {
              if (res.status === true) {
                this.toaster.showSuccess('Success', res.message);
                this.router.navigate(['portal/order']);
              } else {
                this.toaster.showError('Error', res.message);
              }
            });
        } else {
          this.orderService.addorderList(model).subscribe((res) => {
            if (res.status === true) {
              // this.paymentInitiated=true;
              this.toaster.showSuccess('Success', res.message);
              this.router.navigate(['portal/order']);
            } else {
              this.toaster.showError('Error', res.message);
            }
          });
        }
      }
    }
  }

  saveAsDraft() {
    this.loadSelectedItems();
    this.formSubmit = true;
    if (this.orderFormGroup.valid) {
      if (this.selectedItems.length === 0) {
        this.CommonToasterService.showError(
          'Error',
          'Please add atleast one items'
        );
      } else {
        const model: any = {
          customer_id: this.selectedCustomerId,
          lob_id: '4',
          storage_location_id: '',
          order_type_id: 2,
          payment_status: 'Not Paid',
          payment_term_id: this.selectedCustomer.payment_term_id,
          depot_id: null,
          salesman_id: null,
          any_comment: this.noteFormControl?.value,
          due_date: new DatePipe('en-US').transform(
            this.dueDateFormControl.value,
            'yyyy-MM-dd'
          ),
          delivery_date: new DatePipe('en-US').transform(
            this.deliveryDateFormControl.value,
            'yyyy-MM-dd'
          ),
          receiving_time: this.deliveryTimeFormControl.value[0].itemName,
          receiving_location: this.customerLocationFormControl.value[0]
            .itemName,
          items: this.selectedItems,
          total_gross: this.cutomerTotalNET,
          total_discount_amount: 0,
          total_net: this.cutomerTotalNET,
          total_excise: this.customerTotalExcise,
          total_vat: this.customerTotalVAT,
          grand_total: this.customerTotal,
          order_number: this.isEditForm
            ? this.orderData.order_number
            : 'abc12377',
          source: 2,
          total_qty: this.selectedItems.length,
          customer_lop: this.customerLPOFormControl.value,
          approval_status: 'draft',
          // company: this.companyFormControl.value
        };
        if (this.isEditForm == true) {
          this.orderService
            .editOrder(this.orderData.uuid, model)
            .subscribe((res) => {
              if (res.status === true) {
                this.toaster.showSuccess('Success', res.message);
                this.router.navigate(['portal/order']);
              } else {
                this.toaster.showError('Error', res.message);
              }
            });
        } else {
          this.orderService.addorderList(model).subscribe((res) => {
            if (res.status === true) {
              this.toaster.showSuccess('Success', res.message);
              this.router.navigate(['portal/order']);
            } else {
              this.toaster.showError('Error', res.message);
            }
          });
        }
      }
    }
  }

  // findSelectedItemPrice(){
  //   if(this.selectedItems.find(item=> item.item_price === 0)){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }

  natChange(price, quantity) {
    return Number(price * quantity).toFixed(2);
  }

  vatChange(total) {
    return Number((5 * total) / 100).toFixed(2);
  }

  totalChange(nat, vat, excise) {
    return Number(nat + vat + excise).toFixed(2);
  }

  numberFormat(number) {
    return this.apiService.numberFormatType(number);
  }

  numberFormatWithSymbol(number) {
    return this.apiService.numberFormatWithSymbol(number);
  }
  //

  // getItemDetailByName(name) {
  //   return this.masterService
  //     .itemDetailListTable({ item_name: name.toLowerCase(), })

  // }

  // onScrollItem() {
  //   if (this.item_total_pages < this.itempage) return;
  //   this.isLoading = true;
  //   this.itemlookup$.next(this.itempage);
  // }

  // getOrderStatus() {
  //   if (this.isEditForm) {
  //     let orderStatus = this.getStatus(this.orderData.current_stage);
  //     orderStatus
  //       ? (this.showConvertToDelivery = false)
  //       : (this.showConvertToDelivery = true);
  //   } else {
  //     this.showConvertToDelivery = true;
  //   }
  // }

  // public get filteredTableHeaders(): ItemAddTableHeader[] {
  //   return [...this.itemTableHeaders].filter((item) => item.show);
  // }

  public ngOnDestroy() {
    Utils.unsubscribeAll(this.subscriptions);
    Utils.unsubscribeAll(this.itemNameSubscriptions);
    Utils.unsubscribeAll(this.itemControlSubscriptions);
  }

  // getOrderCode() {
  //   let nextNumber = {
  //     customer_id:  this.customer.user_id,
  //     function_for: 'order',
  //   };
  //   this.orderService.getPortalNextCommingCode(nextNumber).subscribe((res: any) => {
  //     if (res.status) {
  //       this.nextCommingNumberofOrderCode = res.data.number_is;
  //       this.orderNumberPrefix = res.data.prefix_is;
  //       if (this.nextCommingNumberofOrderCode) {
  //         this.orderNumber = this.nextCommingNumberofOrderCode;
  //       } else if (this.nextCommingNumberofOrderCode == null) {
  //         this.nextCommingNumberofOrderCode = '';
  //         this.orderNumber = '';
  //       }
  //     } else {
  //       this.nextCommingNumberofOrderCode = '';
  //       this.orderNumber = '';
  //     }
  //   });
  // }

  // public openNumberSettings(): void {
  //   let data = {
  //     customer_id: this.customer.user_id,
  //     title: 'Order Code',
  //     functionFor: 'order',
  //     code: this.orderNumber,
  //     prefix: this.orderNumberPrefix,
  //     key: this.orderNumber.length ? 'autogenerate' : 'manual',
  //   };
  //   this.dialogRef
  //     .open(PortalDialogCodeComponent, {
  //       width: '500px',
  //       data: data,
  //     })
  //     .componentInstance.sendResponse.subscribe((res: any) => {
  //       if (res.type == 'manual' && res.enableButton) {
  //         this.orderNumber = '';
  //         this.nextCommingNumberofOrderCode = '';
  //       } else if (res.type == 'autogenerate' && !res.enableButton) {
  //         this.orderNumber = res.data.next_coming_number_order;
  //         this.nextCommingNumberofOrderCode = res.data.next_coming_number_order;
  //         this.orderNumberPrefix = res.reqData.prefix_code;
  //       }
  //     });
  // }
  // getCustomerLobList(customer, editData?) {
  //   this.filterValue = "";
  //   let paymentTermId
  //   if (this.isEditForm && editData) {
  //     paymentTermId = editData?.payment_term_id;
  //   } else {
  //     paymentTermId = customer?.payment_term_id;
  //   }

  //   if (customer?.is_lob == 1 || editData?.lob) {
  //     this.is_lob = true;
  //     this.customerLobFormControl.setValidators([Validators.required]);
  //     this.customerLobFormControl.updateValueAndValidity();
  //     this.selectedPaymentTermId = paymentTermId
  //     this.apiService.getLobsByCustomerId(customer?.user_id).subscribe((result) => {
  //       this.customerLobList = result.data[0] && result.data[0]?.customerlob || [];
  //       if (editData) {
  //         setTimeout(() => {
  //           this.paymentTermFormControl.patchValue(this.selectedPaymentTermId);
  //           let customerLob = [{ id: editData?.lob_id, itemName: editData?.lob?.name }];
  //           this.customerLobFormControl.setValue(customerLob);
  //         }, 1000);
  //       }
  //     });

  //   }
  //   else {
  //     this.is_lob = false;
  //     this.customerLobFormControl.clearValidators();
  //     this.customerLobFormControl.updateValueAndValidity();
  //     this.selectedPaymentTermId = paymentTermId
  //     this.paymentTermFormControl.patchValue(this.selectedPaymentTermId);
  //   }
  // }

  createItemFormGroup(item, isBulk = false) {
    let group;
    if (item && isBulk) {
      group = new FormGroup({
        item: new FormControl({
          id: item.id,
          name: item.name,
          item_code: item.item_code,
        }),
        item_name: new FormControl(item.name),
        item_uom_id: new FormControl(item.selected_item_uom),
        item_qty: new FormControl(item?.quantity, [Validators.required]),
        item_uom_list: new FormControl([item.item_uom_list]),
        is_free: new FormControl(item?.is_free || false),
      });
    } else if (item && !isBulk) {
      group = new FormGroup({
        item: new FormControl({
          id: item.item.id,
          name: item.item.name,
          item_code: item.item.item_code,
        }),
        item_name: new FormControl(item.item.name),
        item_uom_id: new FormControl(item.item_uom_id),
        item_qty: new FormControl(item.item_qty, [Validators.required]),
        item_uom_list: new FormControl([item.uom_info]),
        is_free: new FormControl(item?.is_free || false),
      });
    } else {
      group = new FormGroup({
        item: new FormControl(''),
        item_name: new FormControl(''),
        item_uom_id: new FormControl(undefined),
        item_qty: new FormControl(1, [Validators.required]),
        item_uom_list: new FormControl([]),
        is_free: new FormControl(false),
      });
    }
    // group.valueChanges.pipe(first()).subscribe((response) => {
    //   this.getPromotion(response);
    // });
    return group;
  }
  // public addItemForm(item?: OrderItemsPayload): void {
  //   const itemControls = this.orderFormGroup.controls['items'] as FormArray;
  //   let group = this.createItemFormGroup(item);
  //   itemControls.push(group);
  //   this.addItemFilterToControl(itemControls.controls.length - 1, true);
  // }

  // public getPromotion(data, index?): void {

  //   if (data) {
  //     let items = this.itemFormControls.filter((x) => x?.value?.is_free == false);
  //     let itemsLength = items.length;
  //     let itemids = [];
  //     let itemuomids = [];
  //     let itemqtys = [];
  //     items.forEach((itemgroup) => {
  //       if (itemgroup && !itemgroup?.value?.is_free) {
  //         itemgroup.value.item.id && itemids.push(itemgroup.value.item.id);
  //         itemgroup.value.item_uom_id && itemuomids.push(itemgroup.value.item_uom_id);
  //         itemgroup.value.item_qty && itemqtys.push(itemgroup.value.item_qty);
  //       }
  //     })
  //     const model = {
  //       item_id: itemids,//[data.item.id],
  //       item_uom_id: itemuomids,//[data.item_uom_id],
  //       item_qty: itemqtys,//[data.item_qty],
  //       customer_id: this.customerFormControl.value?.customer_infos_id,
  //     };
  //     if (!model.item_id.length) return;
  //     this.orderService
  //       .getPromotionItems(model)
  //       .pipe()
  //       .subscribe((result) => {
  //         if (
  //           result &&
  //           result.data &&
  //           result.data.itemPromotionInfo.length > 0
  //         ) {
  //           const status = result.data.itemPromotionInfo[0].offer_item_type;
  //           if (status == 'Any') {
  //             this.openPromotionPopup(result.data, itemsLength);
  //           } else {
  //             const itemControls = this.orderFormGroup.get('items') as FormArray;
  //             itemControls.value.map(
  //               (item) => { item.is_free === true && this.deleteItemRow(itemControls.value.findIndex(x => x.is_free === true)) }
  //             );
  //             result.data.offer_items.forEach((element) => {
  //               const added = itemControls.value.find(
  //                 (item) => item.item.id === element.item_id && item.is_free == true
  //               );
  //               this.freeItems = true;
  //               if (added) return;
  //               element.item.name = element.item.item_name;
  //               element.item_price = element?.item?.lower_unit_item_price;
  //               element.discount = Number(element.offered_qty) * Number(element?.item?.lower_unit_item_price);
  //               element.item_qty = Number(element.offered_qty);
  //               element['is_free'] = true;
  //               this.addItemForm(element);
  //               let item = { id: element.item.id, name: element.item.name };
  //               this.itemDidSearched(element, itemControls.value.length - 1, true);
  //               const newFormGroup = itemControls.controls[itemControls.value.length - 1] as FormGroup;
  //               this.payloadItems[itemControls.value.length - 1] = this.setupPayloadItemArray(
  //                 newFormGroup,
  //                 element
  //               );
  //             });
  //           }
  //         } else {
  //           const itemControls = this.orderFormGroup.get('items') as FormArray;
  //           itemControls.value.map(
  //             (item) => { item.is_free === true && this.deleteItemRow(itemControls.value.findIndex(x => x.is_free === true)) }
  //           );
  //         }
  //       });
  //   }
  // }

  // public openPromotionPopup = (promotion, index) => {
  //   this.dialog
  //     .open(PromotionDailogComponent, {
  //       width: '600px',
  //       height: 'auto',
  //       data: {
  //         title: 'Promotions',
  //         data: promotion,
  //       },
  //     })
  //     .componentInstance.sendResponse.subscribe((res: any) => {
  //       const itemControls = this.orderFormGroup.get('items') as FormArray;
  //       // console.log(itemControls);
  //       // itemControls.value.map(
  //       //   (item) => { item.is_free === true && this.deleteItemRow(itemControls.value.findIndex(x => x.is_free === true)) }
  //       // );
  //       if (res && res.length > 0) {
  //         this.dialog.closeAll();
  //         res.forEach((element) => {
  //           const added = itemControls.value.find(
  //             (item) => item.item.id === element.item_id && item.is_free == true
  //           );
  //           this.freeItems = false;
  //           if (added) {
  //             // let indexOf = itemControls.value.indexOf(added);
  //             // let qty = parseInt(added.item_qty) + 1;
  //             // itemControls.at(indexOf).get("item_qty").setValue(qty);
  //             return;
  //           }
  //           element.item.name = element.item.item_name;
  //           element.item_price = element?.item?.lower_unit_item_price;
  //           element.discount = Number(element.offered_qty) * Number(element?.item?.lower_unit_item_price);
  //           element.item_qty = Number(element.offered_qty);
  //           element['is_free'] = true;
  //           this.addItemForm(element);

  //           let item = { id: element.item.id, name: element.item.name };
  //           this.itemDidSearched(element, itemControls.value.length - 1, true);
  //           const newFormGroup = itemControls.controls[itemControls.value.length - 1] as FormGroup;
  //           this.payloadItems[itemControls.value.length - 1] = this.setupPayloadItemArray(
  //             newFormGroup,
  //             element
  //           );
  //         });
  //       }
  //     });
  // };

  // public orderTypeChanged(id: number): void {
  //   if (id) {
  //     // this.orderTypeFormControl.setValue(id);
  //     this.selectedOrderType = this.orderTypes.find((type) => type.id === id);
  //     this.isDepotOrder =
  //       this.selectedOrderType?.use_for.toLowerCase() !== 'customer';
  //   }
  // }

  // public depotChanged(id: number): void {
  //   this.selectedDepotId = id;
  //   this.depotFormControl.setValue(id);
  // }

  // public salesmanChanged(id: number): void {
  //   this.selectedSalesmanId = id;
  //   this.salesmanFormControl.setValue(id);
  // }

  // public payTermChanged(id: number): void {
  //   this.selectedPaymentTermId = id;
  //   this.paymentTermFormControl.setValue(id);
  //   this.setupDueDate();
  // }

  // public redirectToItem(): void {
  //   this.router.navigate(['masters/item'], {
  //     queryParams: { create: true },
  //   });
  // }

  public goBackToOrdersList(): void {
    this.router.navigate(['portal/order']);
  }

  // public goToOrder(): void {
  //   this.postFinalOrder('order');
  // }

  // public addOrderType(): void {
  //   this.dialogRef
  //     .open(OrderTypeFormComponent, {
  //       width: '500px',
  //     })
  //     .afterClosed()
  //     .subscribe((data) => {
  //       if (data) {
  //         this.orderTypes = data;
  //       }
  //     });
  // }

  // public addItem(): void {
  //   this.filteredItems = this.items;
  //   this.addItemForm();
  // }

  // public itemDidSelected(event: any, item: OrderItemsPayload): void {
  //   const isChecked = event.target.checked;
  //   const currentIndex = this.selectedPayloadItems.indexOf(item);

  //   if (isChecked) {
  //     this.selectedPayloadItems.push(item);
  //   } else {
  //     this.selectedPayloadItems.splice(currentIndex, 1);
  //   }

  //   this.generateOrderFinalStats(false, true);
  // }

  // public getUomValue(item: OrderItemsPayload): string {
  //   const selectedUom = this.uoms.find(
  //     (uom) => uom.id.toString() === item.item_uom_id
  //   );
  //   return selectedUom ? selectedUom.name : '';
  // }

  // public get itemFormControls(): AbstractControl[] {
  //   const itemControls = this.orderFormGroup.get('items') as FormArray;
  //   return itemControls.controls;
  // }

  // public itemControlValue(item: Item): { id: string; name: string, item_code: string } {
  //   return { id: item.id, name: item.item_name, item_code: item.item_code };
  // }

  // public itemsControlDisplayValue(item?: {
  //   id: string;
  //   name: string;
  //   item_code: string;
  // }): string | undefined {
  //   return item ? item.item_code ? item.item_code : '' + " " + item.name : undefined;
  // }

  // public customerControlDisplayValue(customer: any): string {
  //   if (customer?.user) {
  //     return `${customer?.user?.customer_info?.customer_code ? customer?.user?.customer_info?.customer_code : ''} ${customer?.user?.firstname ? customer?.user?.firstname + ' ' + customer?.user?.lastname : ''} `

  //   } else
  //     return `${customer?.customer_code ? customer?.customer_code : ''} ${customer?.name ? customer?.name : ''} `
  // }

  // public deleteItemRow(index: number): void {
  //   const itemControls = this.orderFormGroup.get('items') as FormArray;
  //   let selectedItemIndex: number;
  //   let isSelectedItemDelete = false;

  //   if (this.selectedPayloadItems.length) {
  //     const selectedItem = this.selectedPayloadItems.find(
  //       (item: OrderItemsPayload, i: number) =>
  //         item.item_id === itemControls.value[index].item.id
  //     );
  //     selectedItemIndex = this.selectedPayloadItems.indexOf(selectedItem);
  //     if (selectedItemIndex >= 0) {
  //       this.selectedPayloadItems.splice(selectedItemIndex, 1);
  //       isSelectedItemDelete = true;
  //     }
  //   }

  //   itemControls.removeAt(index);

  //   this.itemNameSubscriptions.splice(index, 1);
  //   this.itemControlSubscriptions.splice(index, 1);
  //   this.payloadItems.splice(index, 1);
  //   this.generateOrderFinalStats(true, isSelectedItemDelete);
  // }

  // public itemDidSearched(data: any, index: number, isFromEdit?: boolean): void {

  //   if (isFromEdit) {
  //     const selectedItem: any = data;
  //     this.itemfilterValue = '';
  //     selectedItem['lower_unit_uom_id'] = selectedItem?.item?.item_uom_lower_unit?.id || 0;
  //     selectedItem['item_main_price'] = selectedItem?.item?.item_main_price || [];
  //     const itemFormGroup = this.itemFormControls[index] as FormGroup;
  //     setTimeout(() => {
  //       this.setUpRelatedUom(selectedItem, itemFormGroup, true);
  //     }, 1000);

  //   } else if (!isFromEdit) {
  //     this.getItemDetailByName(data.name).subscribe(res => {
  //       var _items = res.data;
  //       const selectedItem: any = _items.find((res: any) => res.id === data.id);

  //       const itemFormGroup = this.itemFormControls[index] as FormGroup;
  //       const itemnameControl = itemFormGroup.controls.item_name;
  //       if (itemnameControl) itemnameControl.setValue(data.name);
  //       this.setUpRelatedUom(selectedItem, itemFormGroup);
  //     });

  //   }
  // }

  // setUpRelatedUom(selectedItem: any, formGroup: FormGroup, isEdit?: boolean) {
  //   let itemArray: any[] = [];
  //   const uomControl = formGroup.controls.item_uom_id;
  //   const baseUomFilter = this.uoms.filter(
  //     (item) => item.id == parseInt(selectedItem?.lower_unit_uom_id)
  //   );
  //   let secondaryUomFilterIds = [];
  //   let secondaryUomFilter = [];
  //   if (selectedItem?.item_main_price && selectedItem?.item_main_price?.length) {
  //     selectedItem?.item_main_price.forEach((item) => {
  //       secondaryUomFilterIds.push(item.item_uom_id);
  //     });
  //     this.uoms.forEach((item) => {
  //       if (secondaryUomFilterIds.includes(item.id)) {
  //         secondaryUomFilter.push(item);
  //       }
  //     });
  //   }

  //   if (baseUomFilter.length && secondaryUomFilter.length) {
  //     itemArray = [...baseUomFilter, ...secondaryUomFilter];
  //   } else if (baseUomFilter.length) {
  //     itemArray = [...baseUomFilter];
  //   } else if (secondaryUomFilter.length) {
  //     itemArray = [...secondaryUomFilter];
  //   }
  //   if (selectedItem?.is_free) {
  //     itemArray = selectedItem.item_uom ? [selectedItem.item_uom] : [selectedItem.uom_info];
  //   }
  //   formGroup.controls.item_uom_list.setValue(itemArray);
  //   if (isEdit) {
  //     setTimeout(() => {
  //       uomControl.setValue(selectedItem?.item_uom_id);
  //     }, 500);
  //   } else {
  //     if (baseUomFilter.length) {
  //       setTimeout(() => {
  //         uomControl.setValue(selectedItem?.lower_unit_uom_id);
  //       }, 500);
  //     } else {
  //       setTimeout(() => {
  //         uomControl.setValue(secondaryUomFilter[0]?.id);
  //       }, 500);
  //     }
  //   }
  // }

  // public postFinalOrder(target: string): void {
  //   const totalStats = {};
  //   Object.keys(this.orderFinalStats).forEach((key: string) => {
  //     totalStats[key] = this.orderFinalStats[key].value;
  //   });
  //   let body = this.orderFormGroup.value;
  //   body.salesman_id = body.salesman_id[0] && body.salesman_id[0]?.id || '';
  //   const finalPayload = {
  //     customer_id: this.customer.user_id,
  //     lob_id: this.customerLobFormControl.value[0] && this.customerLobFormControl.value[0].id || "",
  //     storage_location_id: this.warehouseFormControl.value[0] && this.warehouseFormControl.value[0].id || "",
  //     ...body,
  //     ...totalStats,
  //     order_number: this.orderNumber,

  //     source: 3,
  //   };
  //   // this.payloadItems.forEach((item) => {
  //   //   item.is_free = false;
  //   //   item.is_item_poi = false;
  //   // });
  //   finalPayload.items = this.payloadItems;
  //   finalPayload['total_qty'] = finalPayload.items.length;

  //   this.finalOrderPayload = { ...finalPayload };
  //   this.finalDeliveryPayload = { ...finalPayload };
  //   this.finalDeliveryPayload.items = this.selectedPayloadItems.length
  //     ? this.selectedPayloadItems
  //     : this.payloadItems;

  //   if (this.selectedPayloadItems.length) {
  //     Object.keys(this.deliveryFinalStats).forEach((key: string) => {
  //       this.finalDeliveryPayload[key] = this.deliveryFinalStats[key].value;
  //     });
  //     this.finalDeliveryPayload['total_qty'] = this.selectedPayloadItems.length;
  //   }
  //   this.finalOrderPayload['customer_lop'] = this.customerLPOFormControl.value;

  //   this.makeOrderPostCall(target);
  // }

  private initItemFormArray(): FormArray {
    const formArray = this.formBuilder.array([]);

    if (this.isEditForm || this.isDeliveryForm) {
      return formArray;
    }
    const group = this.createItemFormGroup(null);
    formArray.push(group);
    return formArray;
  }

  // private addItemFilterToControl(index: number, is_free?): void {
  //   const itemControls = this.orderFormGroup.controls['items'] as FormArray;
  //   const newFormGroup = itemControls.controls[index] as FormGroup;

  //   // this.itemNameSubscriptions.push(
  //   //   newFormGroup.controls['item'].valueChanges
  //   //     .pipe(
  //   //       distinctUntilChanged(
  //   //         (a, b) => JSON.stringify(a) === JSON.stringify(b)
  //   //       )
  //   //     )
  //   //     .pipe(
  //   //       startWith<string | Item>(''),
  //   //       map((value) => (typeof value === 'string' ? value : value.item_name)),
  //   //       map((value: string) => {
  //   //         return value;
  //   //       })
  //   //     ).subscribe((res) => {
  //   //       // this.itemfilterValue = res || "";
  //   //       // this.itempage = 1;
  //   //       // this.items = [];
  //   //       // this.filteredItems = [];
  //   //       // this.isLoading = true;
  //   //       // this.itemlookup$.next(this.itempage)
  //   //     })
  //   // );

  //   this.payloadItems[index] = this.setupPayloadItemArray(newFormGroup);

  //   this.itemControlSubscriptions.push(
  //     newFormGroup.valueChanges
  //       .pipe(
  //         debounceTime(500),
  //         distinctUntilChanged(
  //           (a, b) => JSON.stringify(a) === JSON.stringify(b)
  //         )
  //       )
  //       .subscribe((result) => {
  //         this.getOrderItemStats(itemControls, newFormGroup, result, false);
  //       })
  //   );
  // }

  // getOrderItemStats(itemControls, newFormGroup, result, isBulk = false) {
  //   const groupIndex = itemControls.controls.indexOf(newFormGroup);
  //   if (
  //     newFormGroup.controls['item'].value &&
  //     newFormGroup.controls['item_uom_id'].value
  //   ) {
  //     var body: any;
  //     if (isBulk) {
  //       body = {
  //         item_id: result.id,
  //         item_uom_id: result.selected_item_uom,
  //         item_qty: result.item_qty,
  //         customer_id: this.isDepotOrder
  //           ? ''
  //           : (this.customerFormControl.value?.customer_infos_id),
  //         lob_id: this.isDepotOrder
  //           ? ''
  //           : (this.customerLobFormControl.value[0] && this.customerLobFormControl.value[0].id || ""),
  //         depot_id: this.isDepotOrder ? this.depotFormControl.value : '',
  //       };
  //     }
  //     else {
  //       body = {
  //         item_id: +result.item.id,
  //         item_uom_id: +result.item_uom_id,
  //         item_qty: result.item_qty,
  //         customer_id: this.isDepotOrder
  //           ? ''
  //           : (this.customerFormControl.value?.customer_infos_id),
  //         lob_id: this.isDepotOrder
  //           ? ''
  //           : (this.customerLobFormControl.value[0] && this.customerLobFormControl.value[0].id || ""),
  //         depot_id: this.isDepotOrder ? this.depotFormControl.value : '',
  //       };
  //     }
  //     if (!this.freeItems && !this.noFirstReqInEdit) {
  //       if (body.item_qty > 0) {
  //         if (!body.item_id) return;
  //         this.getPromotion(result);
  //         this.subscriptions.push(
  //           // this.orderService.getOrderItemStats(body).subscribe(
  //           //   (stats) => {
  //           //     this.payloadItems[groupIndex] = this.setupPayloadItemArray(
  //           //       newFormGroup,
  //           //       stats.data
  //           //     );
  //           //     this.generateOrderFinalStats(false, false);
  //           //   },
  //           //   (error) => {
  //           //     console.error(error);
  //           //   }
  //           // )
  //         );
  //       } else {
  //         this.CommonToasterService.showWarning(
  //           'Item QTY should atleast be 1'
  //         );
  //         this.payloadItems[groupIndex] = this.setupPayloadItemArray(
  //           newFormGroup,
  //           this.setupEmptyItemValue
  //         );
  //         this.generateOrderFinalStats(false, false);
  //       }
  //     } else {
  //       setTimeout(() => {
  //         this.freeItems = false;
  //         this.noFirstReqInEdit = false;
  //       }, 1000);
  //     }
  //   } else {
  //     this.payloadItems[groupIndex] = this.setupPayloadItemArray(
  //       newFormGroup
  //     );
  //     this.generateOrderFinalStats(false, false);
  //   }
  // }

  // get setupEmptyItemValue() {
  //   return {
  //     discount: 0,
  //     discount_id: 0,
  //     discount_percentage: 0,
  //     is_free: false,
  //     is_item_poi: false,
  //     item_gross: 0,
  //     item_price: 0,
  //     item_qty: 0,
  //     promotion_id: null,
  //     total: 0,
  //     total_excise: 0,
  //     total_net: 0,
  //     total_vat: 0,
  //   };
  // }

  // fiterItems(value) {
  //   this.itemfilterValue = value.toLowerCase().trim() || "";
  //   this.filteredItems = this.items
  //     .filter(x => x.item_code.toLowerCase().trim().indexOf(this.itemfilterValue) > -1 || x.item_name.toLowerCase().trim().indexOf(this.itemfilterValue) > -1)

  //   // this.itempage = 1;
  //   // this.items = [];
  //   // this.filteredItems = [];
  //   // this.isLoading = true;
  //   // this.itemlookup$.next(this.itempage)
  // }

  private setupDueDate(): void {
    const date = this.deliveryDateFormControl.value;
    const selectedTerm = this.terms.find(
      (term: PaymentTerms) => term.id === this.selectedPaymentTermId
    );
    var new_date = moment(date ? date : new Date())
      .add(selectedTerm?.number_of_days, 'days')
      .format('YYYY-MM-DD');
    this.dueDateFormControl.setValue(new_date);
  }

  public checkFormValidation(target: string = null): boolean {
    // if (this.orderTypeFormControl.invalid) {
    //   Utils.setFocusOn('typeFormField');
    //   return false;
    // }
    if (!this.isDepotOrder && this.customerFormControl.invalid) {
      Utils.setFocusOn('customerFormField');
      return false;
    }
    if (!this.isDepotOrder && this.customerLobFormControl.invalid) {
      return false;
    }
    if (this.isDepotOrder && this.depotFormControl.invalid) {
      Utils.setFocusOn('depotFormField');
      return false;
    }
    if (target == 'orderDelivery') {
      if (
        !this.salesmanFormControl.value ||
        this.salesmanFormControl.value.length == 0
      ) {
        Utils.setFocusOn('salesmanFormField');
        return false;
      }
    }
    if (this.paymentTermFormControl.invalid) {
      Utils.setFocusOn('termFormField');
      return false;
    }
    return true;
  }

  // private generateOrderFinalStats(
  //   isDeleted?: boolean,
  //   isItemSelection?: boolean
  // ): void {
  //   if (isItemSelection) {
  //     Object.values(this.deliveryFinalStats).forEach((item) => {
  //       item.value = 0;
  //     });

  //     this.selectedPayloadItems.forEach((item: OrderItemsPayload) => {
  //       this.sumUpFinalStats(item, true);
  //     });

  //     if (!isDeleted) {
  //       return;
  //     }
  //   }

  //   Object.values(this.orderFinalStats).forEach((item) => {
  //     item.value = 0;
  //   });

  //   this.payloadItems.forEach((item: OrderItemsPayload) => {
  //     this.sumUpFinalStats(item);
  //   });
  // }
  // restrictLength(e) {
  //   if (e.target.value.length >= 10) {
  //     e.preventDefault();
  //   }
  // }

  // setOrderNumber(value) {
  //   //console.log(value);
  //   this.orderNumber = value;
  // }

  // private sumUpFinalStats(
  //   item: OrderItemsPayload,
  //   isForDelivery?: boolean
  // ): void {
  //   console.log(this.deliveryFinalStats);
  //   console.log(item);
  //   if (isForDelivery) {
  //     this.deliveryFinalStats.total_gross.value =
  //       this.deliveryFinalStats.total_gross.value + item.item_gross;
  //     this.deliveryFinalStats.total_vat.value =
  //       this.deliveryFinalStats.total_vat.value + item.item_vat;
  //     this.deliveryFinalStats.total_excise.value =
  //       this.deliveryFinalStats.total_excise.value + item.item_excise;
  //     this.deliveryFinalStats.total_net.value =
  //       this.deliveryFinalStats.total_net.value + item.total_net;
  //     this.deliveryFinalStats.total_discount_amount.value =
  //       this.deliveryFinalStats.total_discount_amount.value +
  //       item.item_discount_amount;
  //     this.deliveryFinalStats.grand_total.value =
  //       this.deliveryFinalStats.grand_total.value + item.item_grand_total;
  //     return;
  //   }

  //   this.orderFinalStats.total_gross.value =
  //     this.orderFinalStats.total_gross?.value + item.item_gross;
  //   this.orderFinalStats.total_vat.value =
  //     this.orderFinalStats.total_vat?.value + item.item_vat;
  //   this.orderFinalStats.total_excise.value =
  //     this.orderFinalStats.total_excise?.value + item.total_excise;
  //   this.orderFinalStats.total_net.value =
  //     this.orderFinalStats.total_net?.value + item.total_net;
  //   this.orderFinalStats.total_discount_amount.value =
  //     this.orderFinalStats.total_discount_amount.value
  //     + item.item_discount_amount;
  //   this.orderFinalStats.grand_total.value =
  //     this.orderFinalStats.grand_total.value + item.item_grand_total;
  // }

  // private setupPayloadItemArray(
  //   form: FormGroup,
  //   result?: any,
  // ): OrderItemsPayload {
  //   return {
  //     item: form.controls.item.value,
  //     item_id: form.controls.item.value.id,
  //     item_code: form.controls.item.value?.item_code || '',
  //     item_qty: form.controls.item_qty.value,
  //     item_uom_id: form.controls.item_uom_id.value,
  //     discount_id: result && result.discount_id ? result.discount_id : null,
  //     promotion_id: result && result.promotion_id ? result.promotion_id : null,
  //     is_free: form.controls.is_free.value || result?.is_free,
  //     is_item_poi: result?.is_item_poi || false,
  //     item_price: result && result.item_price ? Number(result.item_price) : 0,
  //     item_discount_amount:
  //       result && result.discount ? Number(result.discount) : 0,
  //     item_vat: result && result.total_vat ? Number(result.total_vat) : 0,
  //     item_net: result && result.total_net ? Number(result.total_net) : 0,
  //     total_net: result && result.net_gross ? Number(result.net_gross) - Number(result.discount) : 0,
  //     item_excise:
  //       result && result.total_excise ? Number(result.total_excise) : 0,
  //     total_excise:
  //       result && result.net_excise ? Number(result.net_excise) : 0,
  //     item_grand_total: result && result.total ? Number(result.total) : 0,
  //     item_gross: result && result.net_gross ? Number(result.net_gross) : 0,
  //   };
  // }

  // getItemStatus(items): boolean {
  //   let ordStatus: string = '';
  //   this.orderData.items.forEach((item, i) => {
  //     if (item.item.id == items.value.item.id) {
  //       ordStatus = item.order_status;
  //     }
  //   });
  //   return this.getStatus(ordStatus);
  // }

  // getStatus(value: string): boolean {
  //   let status: boolean = false;
  //   switch (value) {
  //     case OrderUpdateProcess.Pending:
  //       status = false;
  //       break;
  //     case OrderUpdateProcess.PartialDeliver:
  //       status = false;
  //       break;
  //     case OrderUpdateProcess.InProcess:
  //       status = false;
  //       break;
  //     case OrderUpdateProcess.Accept:
  //       status = true;
  //       break;
  //     case OrderUpdateProcess.Delivered:
  //       status = true;
  //       break;
  //     case OrderUpdateProcess.Completed:
  //       status = true;
  //       break;
  //   }
  //   return status;
  // }

  // private makeOrderPostCall(target: string): void {
  //   if (!this.checkFormValidation(target)) {
  //     return;
  //   }

  //   if (target === 'delivery') {
  //     this.subscriptions.push(
  //       this.orderService.addNewOrder(this.finalOrderPayload).subscribe(
  //         (result1) => {
  //           this.CommonToasterService.showSuccess(
  //             '',
  //             'Order has been successfuly added. Converting order to delivery.'
  //           );
  //           this.router.navigate([
  //             'portal/order/start-delivery',
  //             result1.data.uuid,
  //           ]);
  //         },
  //         (error) => {
  //           this.CommonToasterService.showError(
  //             'Failed adding order',
  //             'Cannot add order, please try again'
  //           );
  //           this.router.navigate(['portal/order/add']);
  //         }
  //       )
  //     );
  //   } else if (target === 'order' || target === 'orderDelivery') {
  //     if (this.isEditForm) {
  //       this.finalOrderPayload.items.forEach((item) => {
  //         let newitem = JSON.parse(JSON.stringify(item['item']));
  //         // console.log(newitem);
  //         // item.item.item_code = undefined;
  //         // console.log(newitem);
  //         item['item_code'] = newitem.item_code;
  //         item['promotion_id'] = null;

  //       });
  //       this.subscriptions.push(
  //         this.orderService
  //           .editOrder(this.orderData.uuid, this.finalOrderPayload)
  //           .subscribe(
  //             (result) => {
  //               this.CommonToasterService.showSuccess(
  //                 '',
  //                 'Order has been updated successfully'
  //               );
  //               this.router.navigate(['portal/order']);
  //               if (target === 'orderDelivery') {

  //                 this.finalDeliveryPayload.items.forEach(item => {
  //                   var resultItem = result.data.order_details.find(x => x.item_id == item.item_id);
  //                   if (resultItem) {
  //                     item['id'] = resultItem.id;
  //                     item['batch_number'] = null;
  //                   }
  //                 });
  //                 this.finalOrderPayload['grand_total'] = result.data.grand_total
  //                 this.finalOrderPayload['total_discount_amount'] = result.data.total_discount_amount
  //                 this.finalOrderPayload['total_excise'] = result.data.total_excise
  //                 this.finalOrderPayload['total_gross'] = result.data.total_gross
  //                 this.finalOrderPayload['total_net'] = result.data.total_net
  //                 this.finalOrderPayload['total_vat'] = result.data.total_vat

  //                 this.postDelivery(this.finalOrderPayload);
  //               }
  //             },
  //             (error) => {
  //               this.CommonToasterService.showError(
  //                 'Failed updating order',
  //                 'Please try again'
  //               );
  //             }
  //           )
  //       );
  //     } else {
  //       this.finalOrderPayload.source = 5;
  //       this.subscriptions.push(
  //         this.orderService.addNewOrder(this.finalOrderPayload).subscribe(
  //           (result) => {
  //             this.CommonToasterService.showSuccess(
  //               'Order added',
  //               'Order has been added successfully'
  //             );
  //             this.router.navigate(['portal/order']);
  //           },
  //           (error) => {
  //             this.CommonToasterService.showError(
  //               'Failed adding order',
  //               'Please try again'
  //             );
  //             this.router.navigate(['portal/order/add']);
  //           }
  //         )
  //       );
  //     }
  //   }
  // }

  // getDeliveryCode() {
  //   let nextNumber = {
  //     function_for: 'delivery',
  //   };
  //   this.orderService.getNextCommingCode(nextNumber).subscribe((res: any) => {
  //     if (res.status) {
  //       this.nextCommingDeliveryCode = res.data.number_is;
  //       this.deliveryNumberPrefix = res.data.prefix_is;
  //       if (this.nextCommingDeliveryCode) {
  //         this.deliveryNumber = this.nextCommingDeliveryCode;
  //       } else if (this.nextCommingDeliveryCode == null) {
  //         this.nextCommingDeliveryCode = '';
  //         this.deliveryNumber = '';
  //       }
  //     } else {
  //       this.nextCommingDeliveryCode = '';
  //       this.deliveryNumber = '';
  //     }
  //   });
  // }

  // postDelivery(payload: any) {

  //   const totalStats = {};
  //   Object.keys(this.deliveryFinalStats).forEach((key: string) => {
  //     if (key == 'gross_total') {
  //       totalStats['total_gross'] = this.deliveryFinalStats[key].value;
  //     } else {
  //       totalStats[key] = this.deliveryFinalStats[key].value;
  //     }
  //   });
  //   var currentTime = moment(new Date())
  //     .format('hh:mm');
  //   const finalPayload: any = {
  //     order_id: this.orderData.id,
  //     customer_id: payload.customer_id,
  //     lob_id: this.orderData.lob_id,
  //     salesman_id: this.salesmanFormControl.value[0]?.id,
  //     storage_location_id: this.warehouseFormControl.value[0] && this.warehouseFormControl.value[0].id || "",
  //     delivery_number: this.deliveryNumber,
  //     delivery_date: this.orderData.delivery_date,
  //     delivery_time: currentTime,
  //     delivery_due_date: this.orderData.due_date,
  //     delivery_type: this.orderData.order_type_id,
  //     delivery_type_source: ConvertDeliveryType.OrderToDelivery,
  //     delivery_weight: 0,
  //     payment_term_id: this.orderData.payment_term_id,
  //     current_stage_comment: 'pending',
  //     items: undefined,
  //     ...totalStats,
  //     source: 3,
  //     status: 1,
  //   };

  //   finalPayload.items = this.finalDeliveryPayload.items;
  //   finalPayload['total_qty'] = this.finalDeliveryPayload.items.length;
  //   finalPayload['grand_total'] = this.finalDeliveryPayload.grand_total
  //   finalPayload['total_discount_amount'] = this.finalDeliveryPayload.total_discount_amount
  //   finalPayload['total_excise'] = this.finalDeliveryPayload.total_excise
  //   finalPayload['total_gross'] = this.finalDeliveryPayload.total_gross
  //   finalPayload['total_net'] = this.finalDeliveryPayload.total_net
  //   finalPayload['total_vat'] = this.finalDeliveryPayload.total_vat
  //   this.orderService.postDelivery(finalPayload).subscribe(
  //     (res: any) => {
  //       if (res.status) {
  //         this.CommonToasterService.showSuccess(
  //           'Delivery',
  //           'Order Sucessfully converted to Delivery'
  //         );
  //         this.router.navigate(['portal/delivery']);
  //       }
  //     },
  //     (error) => {
  //       this.CommonToasterService.showError(
  //         'Delivery',
  //         'Failed Converting Order to Delivery, Please try again!!!'
  //       );
  //     }
  //   );
  // }

  // openBulkItemSelectionPopup() {
  //   this.dialogRef.open(BulkItemModalComponent, {
  //     width: '1000px',
  //     data: { title: `Are you sure want to delete this Salesman ?` }
  //   }).afterClosed().subscribe(data => {
  //     if (data.length > 0) {
  //       const itemControls = this.orderFormGroup.get('items') as FormArray;
  //       data.forEach(element => {
  //         const added = itemControls.value.find(
  //           (item) => item.item.id === element.item_id && item.is_free == true
  //         );
  //         if (added) return;
  //         element.name = element.item_name;
  //         element.item_price = element?.lower_unit_item_price;
  //         // element.discount = Number(element?.quantity || 0) * Number(element?.lower_unit_item_price);
  //         element.item_qty = Number(element?.quantity || 0);
  //         element['is_free'] = false;
  //         this.addBulkItemForm(element);
  //         let item = { id: element.id, name: element.name };
  //         this.setItemBulk(element, itemControls.value.length - 1, true);
  //         const newFormGroup = itemControls.controls[itemControls.value.length - 1] as FormGroup;
  //         this.payloadItems[itemControls.value.length - 1] = this.setupPayloadItemArray(
  //           newFormGroup,
  //           element
  //         );

  //         this.getOrderItemStats(itemControls, newFormGroup, element, true);

  //       });
  //     }
  //   });
  // }
  // public addBulkItemForm(item?: OrderItemsPayload): void {
  //   const itemControls = this.orderFormGroup.controls['items'] as FormArray;
  //   let group = this.createItemFormGroup(item, true);
  //   itemControls.push(group);
  //   this.addItemFilterToControl(itemControls.controls.length - 1, true);
  // }
  // public setItemBulk(data: any, index: number, isFromEdit?: boolean): void {

  //   this.itemfilterValue = '';
  //   // const selectedItem = this.items.find(
  //   //   (item: Item) => item.id === data.item_id
  //   // );
  //   let selectedItem = data;
  //   selectedItem['lower_unit_uom_id'] = data?.item_uom_lower_unit?.id || 0;
  //   selectedItem['item_main_price'] = data?.item_main_price || [];
  //   const itemFormGroup = this.itemFormControls[index] as FormGroup;
  //   // const uomControl = itemFormGroup.controls.item_uom_id;
  //   // let IsEdit = true;
  //   // if (selectedItem?.is_free) {
  //   //   IsEdit = false
  //   // }
  //   this.setUpRelatedUomBulk(selectedItem, itemFormGroup);

  // }

  // setUpRelatedUomBulk(selectedItem: any, formGroup: FormGroup) {
  //   const uomControl = formGroup.controls.item_uom_id;
  //   const baseUomFilter = this.uoms.filter(
  //     (item) => item.id == parseInt(selectedItem?.lower_unit_uom_id)
  //   );
  //   let secondaryUomFilter = [];
  //   if (selectedItem?.item_uom_list && selectedItem?.item_uom_list?.length) {

  //     formGroup.controls.item_uom_list.setValue(selectedItem?.item_uom_list);
  //     if (selectedItem?.selected_item_uom) {
  //       uomControl.setValue(selectedItem?.selected_item_uom);
  //     } else {
  //       if (baseUomFilter.length) {
  //         uomControl.setValue(selectedItem?.lower_unit_uom_id);
  //       } else {
  //         uomControl.setValue(secondaryUomFilter[0]?.id);
  //       }
  //     }

  //   }
  // }
}

export const ITEM_ADD_FORM_TABLE_HEADS: ItemAddTableHeader[] = [
  { id: 0, key: 'sequence', label: '#', show: true },
  { id: 1, key: 'item', label: 'Item Code', show: true },
  { id: 2, key: 'itemName', label: 'Item Name', show: true },

  // { id: 3, key: 'uom', label: 'UOM', show: true },
  { id: 4, key: 'qty', label: 'Quantity', show: true },
  { id: 5, key: 'price', label: 'Price', show: true },
  { id: 9, key: 'excise', label: 'Excise', show: true },
  // { id: 6, key: 'discount', label: 'Discount', show: true },
  { id: 8, key: 'net', label: 'Net', show: true },
  { id: 7, key: 'vat', label: 'Vat', show: true },
  { id: 10, key: 'total', label: 'Total', show: true },
];
