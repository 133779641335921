<section>
  <div class="heading-row">
    <h2 class="d-inline"><i class="fa fa-file-text-o mr-2"></i>Generate Invoice <span class="order-number">#{{
        deliveryData?.delivery_number }}</span></h2>
    <div class="d-inline float-right">
      <button mat-button class="btn btn-sm btn-white" (click)="goToDeliveries()"><i class="fa fa-chevron-left"></i><span
          class="back-btn"> Back</span></button>
    </div>
  </div>
  <div class="separator"></div>

  <!--  <div class="approval-box" *ngIf="hasApprovalPending">-->
  <!--    <div class="approve-icon">-->
  <!--      <i class="fa fa-check-square-o"></i>-->
  <!--    </div>-->
  <!--    <div class="approve-content">-->
  <!--      <h2>Approve this delivery</h2>-->
  <!--      <p>This delivery has been submitted for approval. Verify and approve the delivery.</p>-->
  <!--    </div>-->
  <!--    <div class="approve-actions">-->
  <!--      <button mat-button class="approve">Approve</button>-->
  <!--      <button mat-button class="reject">Reject</button>-->
  <!--    </div>-->
  <!--  </div>-->

  <form class="order-form custom-mat square" [formGroup]="invoiceFormGroup">
    <div class="card">
      <div class="card-header active-form overview">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Invoice Type</label>
              <input type="text" class="form-control form-control-sm non-editable" [value]="orderTypeTitle" disabled>
            </div>
          </div>

        </div>
      </div>

      <div class="card-body overview">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <div class="form-group" *ngIf="!isDepotOrder">
              <label for="">Customer</label>
              <input type="text" class="form-control form-control-sm non-editable"
                [value]="deliveryData?.customer ? deliveryData?.customer?.firstname +' '+ deliveryData?.customer?.lastname : ''"
                disabled>
            </div>
            <div class="form-group" *ngIf="!isDepotOrder">
              <label for="">Customer Lob</label>
              <input type="text" class="form-control form-control-sm non-editable"
                [value]="deliveryData?.lob ? deliveryData?.lob?.name : ''" disabled>
            </div>
            <div class="form-group" *ngIf="deliveryData?.storageocation?.code">
              <label for="">Warehouse</label>
              <input type="text" class="form-control form-control-sm non-editable" [value]="
              deliveryData?.storageocation?.code +'-'+ deliveryData?.storageocation?.name || ''" disabled />
            </div>
            <div class="form-group" *ngIf="!isDepotOrder">
              <label for="">Salesman</label>
              <input type="text" class="form-control form-control-sm non-editable"
                [value]="deliveryData?.salesman?.firstname + ' ' +  deliveryData?.salesman?.lastname "
                disabled>
            </div>
            <div class="form-group" *ngIf="isDepotOrder">
              <label for="">Depot</label>
              <input type="text" class="form-control form-control-sm non-editable"
                [value]="deliveryData?.depot?.depot_name" disabled>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Invoice Number</label>
              <div class="position-relative">
                <input type="text" class="form-control form-control-sm non-editable" [(ngModel)]="invoiceNumber"
                  [ngModelOptions]="{standalone: true}" [disabled]="nextCommingInvoiceCode != ''">
                <span class="setting-btn"><button (click)="openNumberSettings()"><i
                      class="os-icon os-icon-ui-46"></i></button></span>
              </div>

            </div>
            <div class="form-group">
              <label for="">Invoice Date</label>
              <input type="date" class="form-control form-control-sm non-editable" [value]="deliveryData?.delivery_date"
                disabled>
            </div>
            <div class="form-group">
              <label for="">Invoice Terms</label>
              <input type="text" class="form-control form-control-sm non-editable"
                [value]="paymentTermTitle ? paymentTermTitle : ''" disabled>
            </div>
            <div class="form-group">
              <label for="">Due Date</label>
              <input type="date" class="form-control form-control-sm non-editable"
                [value]="deliveryData?.delivery_due_date" disabled>
            </div>
          </div>

        </div>

      </div>
      <div>
        <div class="item-container custom-mat custom-mat-no-border" *ngIf="deliveryData.delivery_details">
          <table class="item-table cardtable">
            <thead>
              <tr>
                <th>
                  <input class="mt-1 input" formControlName="selectAll" type="checkbox" (click)="selectAll($event)">
                </th>
                <th class="head" *ngFor="let head of itemTableHeaders">{{ head.label }}</th>
              </tr>
            </thead>
            <tbody class="form-body">
              <tr formArrayName="items" class="item-row" *ngFor="let item of itemFormControls; let i = index;">
                <ng-container [formGroupName]="i">
                  <td data-th="select">
                    <span> <input formControlName="checked" (change)="itemDidSelected($event, payloadItems[i])"
                        type="checkbox" name="item"></span>
                  </td>
                  <td data-th="#">
                    {{i + 1}}
                  </td>
                  <td data-th="Item Name	" class="item-name text-center">
                    <span>{{payloadItems && payloadItems[i]?.item?.item_code}}</span>
                  </td>
                  <td data-th="Item Name	" class="item-name text-center">
                    <span>{{payloadItems && payloadItems[i]?.item?.item_name}}</span>
                  </td>
                  <td data-th="UOM" class="uom-td text-center"><span>{{payloadItems &&
                      payloadItems[i]?.item_uom?.name}}</span></td>
                  <td data-th="Quantity" class="stats-cell text-center"><input type="number" min="0"
                      class="form-control" placeholder="Quantity" formControlName="item_qty"></td>
                  <td data-th="Price" class="stats-cell text-center"><span>{{payloadItems &&
                      payloadItems[i]?.item_price}}</span></td>
                  <td data-th="Excise" class="stats-cell text-center"><span>{{payloadItems &&
                      payloadItems[i]?.item_excise}}</span></td>
                  <td data-th="Discount" class="stats-cell text-center">
                    <span>{{payloadItems && payloadItems[i]?.item_discount_amount}}</span>
                  </td>
                  <td data-th="Net" class="stats-cell text-center"><span>{{payloadItems &&
                      payloadItems[i]?.item_net}}</span></td>
                  <td data-th="Vat" class="stats-cell text-center"><span>{{payloadItems &&
                      payloadItems[i]?.item_vat}}</span></td>


                  <td data-th="Total" class="stats-cell text-center"><span>{{payloadItems &&
                      payloadItems[i]?.item_grand_total}}</span>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
        </div>
        <div class=" footer-overview-container">
          <div class="row justify-content-end">
            <!--          <div class="form-group col-sm-5">-->
            <!--            <label for="note">Customer Note</label>-->
            <!--            <textarea class="form-control form-control-sm" id="note" [value]="deliveryData.customer_note ? deliveryData.customer_note : ''" disabled></textarea>-->
            <!--          </div>-->
            <div class="col-sm-4">
              <div class="overall-stats-table">
                <div *ngFor="let stat of orderStats.slice(0, orderStats.length - 1)">
                  <span class="stat-label">{{ stat.label }}</span>
                  <span class="stat-value">{{ deliveryFinalStats[stat.key].value | currency: currencyCode:"symbol":
                    currencyDecimalFormat }}</span>
                </div>
                <div>
                  <span class="stat-label final-total">Total</span>
                  <span class="stat-value final-total">{{ deliveryFinalStats['grand_total'].value | currency:
                    currencyCode:"symbol": currencyDecimalFormat }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="actions">
    <button class="btn btn-primary" (click)="postFinalOrder()">Generate Invoice</button>
  </div>
</section>