import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { InvoiceModel } from '../invoice-models';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import {
  OrderUpdateProcessColor,
  OrderUpdateProcess,
} from 'src/app/components/main/transaction/orders/order-models';
import { ColumnConfig } from 'src/app/interfaces/interfaces';
import {
  APP_CURRENCY_CODE,
  CompDataServiceType,
} from 'src/app/services/constants';
import {
  getCurrency,
  getCurrencyDecimalFormat,
} from 'src/app/services/constants';
import { ApiService } from 'src/app/services/api.service';
import { DataEditor } from 'src/app/services/data-editor.service';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { Utils } from 'src/app/services/utils';
import { InvoiceServices } from '../invoice.service';
import { Events } from 'src/app/models/events.model';
import { EventBusService } from 'src/app/services/event-bus.service';
import { PAGE_SIZE_10 } from 'src/app/app.constant';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TemplateRef } from '@angular/core';
import { object } from '@amcharts/amcharts4/core';
@Component({
  selector: 'app-invoice-data-table',
  templateUrl: './invoice-data-table.component.html',
  styleUrls: ['./invoice-data-table.component.scss'],
})
export class InvoiceDataTableComponent implements OnInit, OnDestroy {
  @Output() public itemClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectedRows: EventEmitter<any> = new EventEmitter<any>();
  @Input() public isDetailVisible: boolean;
  @Input() public newInvoiceData: any;
  @Output() public detailsClosed: EventEmitter<any> = new EventEmitter<any>();
  public dataSource: MatTableDataSource<InvoiceModel>;
  public filterObjectId: any = null;
  filterData: any;
  //public invoiceData: InvoiceModel[] = [];
  invoiceData: any
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public selections = new SelectionModel(true, []);
  public displayedColumns: ColumnConfig[] = [];
  public filterColumns: ColumnConfig[] = [];
  public currencyCode = getCurrency();
  public currencyDecimalFormat = getCurrencyDecimalFormat();
  public orderStatus = {
    color: '',
    label: '',
  };
  private router: Router;
  selectedColumnFilter: string;
  filterForm: FormGroup;

  private dataEditor: DataEditor;
  private subscriptions: Subscription[] = [];
  private allColumns: ColumnConfig[] = [
    { def: 'select', title: 'Select', show: true },
    { def: 'misc', title: 'Invoices', show: false },
    // { def: 'date', title: 'Date', show: true },
    { def: 'code', title: 'Invoice#', show: true },
    { def: 'customer_code', title: 'Customer Code#', show: true },
    // { def: 'name', title: 'Customer Name', show: true },
    // { def: 'route_code', title: 'Route Code', show: true },
    // { def: 'route_name', title: 'Route Name', show: true },
    // { def: 'salesman_code', title: 'Salesman Code', show: true },
    // { def: 'salesman_name', title: 'Salesman Name', show: true },
    { def: 'due', title: 'Due Date', show: true },
    { def: 'amount', title: 'Amount', show: true },
    { def: 'approval', title: 'Approval', show: true },
    { def: 'status', title: 'Status', show: true },
    { def: 'payment_status', title: 'Payment Status', show: false },
    // { def: 'ERP_status', title: 'Odoo Status', show: true },
  ];
  private collapsedColumns: ColumnConfig[] = [
    { def: 'expand', title: 'Detail', show: true },
  ];

  public allResData = [];
  public apiResponse = {
    pagination: {
      total_records: 0,
      total_pages: 0,
    },
  };
  page = 1;
  pageSize = PAGE_SIZE_10;
  @ViewChild("dialogRef") dialogRef: TemplateRef<any>;
  isOdooMessageOpen: boolean = false;
  isSyncDialogShow: boolean;
  constructor(
    public apiService: ApiService,
    dataEditor: DataEditor,
    fds: FormDrawerService,
    private eventService: EventBusService,
    private deleteDialog: MatDialog,
    private invoiceServices: InvoiceServices,
    router: Router,
    public fb: FormBuilder,
    private routerParam: ActivatedRoute
  ) {
    Object.assign(this, { apiService, dataEditor, fds, deleteDialog, router });
    this.dataSource = new MatTableDataSource<InvoiceModel>();
    this.isSyncDialogShow = localStorage.getItem('organisation_id') == '3';
  }

  public ngOnInit(): void {
    let customerDetails = JSON.parse(localStorage.getItem('portalCustomer'));
    this.filterForm = this.fb.group({
      invoice_date: [''],
      invoice_number: [''],
      invoice_due_date: [''],
      customer_name: [''],
      customer_info_id : [customerDetails.id],
      status: [''],
      customer_code: [''],
      route_code: [''],
      route_name: [''],
      salesman_code: [''],
      salesman: [''],
      page: [this.page],
      page_size: [this.pageSize],
      approval: [''],
      erp_status: ['']
    })
    this.displayedColumns = this.allColumns;
    this.filterColumns = [...[...this.allColumns].splice(2)];

    this.getInvoiceList();

    this.subscriptions.push(
      this.dataEditor.newData.subscribe((value) => {
        if (value.type === CompDataServiceType.GET_NEW_DATA) {
          this.filterObjectId = value.data.id;
          this.getInvoiceList();
        }
        if (value.type === CompDataServiceType.CLOSE_DETAIL_PAGE) {
          this.closeDetailView();
        }
        if (value.uuid) {
          const clone = JSON.parse(JSON.stringify(this.invoiceData));
          const index = clone.findIndex((x) => x.uuid === value.uuid);
          if (index > -1) {
            clone.splice(index, 1);
            this.invoiceData = clone;
            this.dataSource.data = clone;
          }
        }
      })
    );

    this.subscriptions.push(
      this.eventService.on(Events.SEARCH_INVOICE, ({ request, requestOriginal, response }) => {
        this.dataSource = new MatTableDataSource<InvoiceModel>(response.data);
        this.dataSource.paginator = this.paginator;
      })
    );
    this.checkInvoiceParamUuid();
  }

  checkInvoiceParamUuid() {
    // this.routerParam.queryParams.subscribe(res => {
    //   this.isDetailVisible = false;
    //   let uuid = res["uuid"];
    //   if (uuid) {
    //     this.detailsClosed.emit();
    //     this.closeDetailView();
    //     this.invoiceServices.getInvoiceByID(uuid).subscribe((res) => {
    //       var dataObj = res.data;
    //       this.openDetailView(dataObj);
    //     })
    //   }
    // });
  }

  getInvoiceList() {
    this.subscriptions.push(
      this.invoiceServices
        .getInvoiceList(this.filterForm.value)
        //.getInvoiceList(this.page, this.pageSize)
        .pipe(
          map((result) => {
            return result;
          })
        )
        .subscribe((result) => {
          //this.invoiceData = invoiceData.data
          //let resulta = invoiceData
          this.invoiceData = result.data;
          this.apiResponse = result;
          this.allResData = result.data;
          this.dataSource = new MatTableDataSource<InvoiceModel>(
            this.invoiceData
          );
          if (this.filterObjectId != null) {
            let filterData = result.data.find(x => x.id === this.filterObjectId);
            this.filterData = result.data.find(x => x.id === this.filterObjectId);
            if (this.filterData) {
              this.openDetailView(this.filterData)
            }
            this.filterObjectId = null;
          };
          this.dataSource.paginator = this.paginator;
       })
    );
  }

  public getSelectedRows() {
    this.selectedRows.emit(this.selections.selected);
  }

  onPageFired(data) {
    this.page = data['pageIndex'] + 1;
    this.pageSize = data['pageSize'];
    this.filterForm.patchValue({
      page: this.page,
      page_size: this.pageSize
    });
    this.getInvoiceList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        changes.newInvoiceData &&
        Object.keys(changes.newInvoiceData.currentValue).length > 0
      ) {
        let currentValue = changes.newInvoiceData.currentValue;
        this.newInvoiceData = currentValue;
        this.updateAllData(this.newInvoiceData);
      }
    }
  }

  updateAllData(data) {
    this.getInvoiceList();
    this.selections = new SelectionModel(true, []);
    if (data.delete !== undefined && data.delete == true) {
      this.closeDetailView();
    }
    return false;
  }

  updateDataSource(data) {
    this.dataSource = new MatTableDataSource<any>(data);
    // this.dataSource.paginator = this.paginator;
  }

  public ngOnDestroy() {
    Utils.unsubscribeAll(this.subscriptions);
  }

  public openDetailView(data: InvoiceModel): void {
    if (this.isOdooMessageOpen) {
      return;
    }
    this.isDetailVisible = true;
    this.itemClicked.emit(data);
    this.updateCollapsedColumns();
  }

  public closeDetailView(): void {
    this.isDetailVisible = false;
    this.updateCollapsedColumns();
  }

  public getDisplayedColumns(): string[] {
    return this.displayedColumns
      .filter((column) => column.show)
      .map((column) => column.def);
  }

  public isAllSelected(): boolean {
    return this.selections.selected.length === this.dataSource.data.length;
  }



  onColumnFilterOpen(item) {
    console.log(item);
    this.selectedColumnFilter = item
  }
  onColumnFilter(status) {
    if (!status) {
      this.filterForm.get(this.selectedColumnFilter).setValue(null);
    }
    this.filterForm.get('page').setValue(1);
    this.page = 1;
    this.dataSource = new MatTableDataSource<any>();
    this.dataSource.paginator = this.paginator;
    this.getInvoiceList();
  }
  public toggleSelection(): void {
    this.isAllSelected()
      ? this.selections.clear()
      : this.dataSource.data.forEach((row) => this.selections.select(row));
  }

  public checkboxLabel(row?: InvoiceModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selections.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }

  private updateCollapsedColumns(): void {
    this.displayedColumns = this.isDetailVisible
      ? this.collapsedColumns
      : this.allColumns;
  }
  getPaymentStatus(status: any) {

    let paymentStatus: string;
    const grandTotal = Number(status.grand_total);
    const pendingTotal = Number(status.pending_credit);

    if (pendingTotal === 0) {
      paymentStatus = "Paid"
    }

    else if (grandTotal == pendingTotal) {
      paymentStatus = "Un-Paid"
    }
    else if (pendingTotal < grandTotal) {
      paymentStatus = "Partial-Paid"
    }
    return paymentStatus;
  }

  getOrderStatus(status: any) {
    let ordStatus = {
      color: OrderUpdateProcessColor.Pending,
      label: status,
    };
    switch (status) {
      case OrderUpdateProcess.Approved:
        ordStatus = {
          color: OrderUpdateProcessColor.Approved,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.Pending:
        ordStatus = {
          color: OrderUpdateProcessColor.Pending,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.PartialInvoice:
        ordStatus = {
          color: OrderUpdateProcessColor.PartialDeliver,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.InProcess:
        ordStatus = {
          color: OrderUpdateProcessColor.InProcess,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.Accept:
        ordStatus = {
          color: OrderUpdateProcessColor.Accept,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.Invoiced:
        ordStatus = {
          color: OrderUpdateProcessColor.Delivered,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.Completed:
        ordStatus = {
          color: OrderUpdateProcessColor.Completed,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.Cancel:
        ordStatus = {
          color: OrderUpdateProcessColor.Cancel,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
      case OrderUpdateProcess.Rejected:
        ordStatus = {
          color: OrderUpdateProcessColor.Rejected,
          label: status,
        };
        this.orderStatus = ordStatus;
        break;
    }
  }

  getOrderStatusValue(status: any) {
    this.getOrderStatus(status);
    return this.orderStatus.label;
  }

  orderStatusColor(status: any) {
    this.getOrderStatus(status);
    return this.orderStatus.color;
  }

  numberFormat(number) {
    return this.apiService.numberFormatType(number);
  }

  numberFormatWithSymbol(number) {
    return this.apiService.numberFormatWithSymbol(number);
  }

  filterOdooMessageData(data: any) {
    try {
      this.isOdooMessageOpen = false;
      if (data) {
        var obj: any = JSON.parse(data);
        if (obj.data)
          this.deleteDialog.open(this.dialogRef, { data: obj.data.message });

        if (obj.response) {
          var text = "";
          obj.response.forEach(element => {
            element.products.forEach(product => {
              text = `${text} ${product},`
            });
          });
          this.deleteDialog.open(this.dialogRef, { data: text });

        }
      }

    } catch (e) {

      this.deleteDialog.open(this.dialogRef, { data: data });

    }
  }

  postOdoo(invoiceData) {
    this.apiService.postInvoiceOdooData(invoiceData.uuid,localStorage.getItem('organisation_id')).subscribe(res => {
      this.getInvoiceList();

    });
  }
}

export let invoiceData = {
    "status": true,
    "data": [
      {
        "id": 203323,
        "uuid": "a84eeb80-63d6-11ec-8b36-9d213912518e",
        "organisation_id": 6,
        "customer_id": 10940,
        "depot_id": null,
        "order_id": null,
        "order_type_id": 1,
        "delivery_id": null,
        "salesman_id": 10834,
        "route_id": 71,
        "trip_id": 3774,
        "invoice_type": "1",
        "invoice_number": "01I100091",
        "invoice_date": "2021-12-23",
        "invoice_due_date": "2021-12-23",
        "payment_term_id": 0,
        "total_qty": "0.00",
        "total_gross": "420.00",
        "total_discount_amount": "0.00",
        "total_net": "420.00",
        "total_vat": "21.00",
        "total_excise": "0.00",
        "grand_total": "441.00",
        "rounding_off_amount": "0.00",
        "pending_credit": "0.000",
        "pdc_amount": "0.000",
        "is_exchange": 1,
        "exchange_number": null,
        "current_stage": "Approved",
        "current_stage_comment": null,
        "approval_status": "Created",
        "is_sync": 1,
        "erp_id": "Invoice for Sales Order: 01I100091 has been posted, Invoice: MPerf01I100091  ",
        "erp_status": "Pass",
        "payment_received": 1,
        "source": 1,
        "status": 1,
        "is_premium_invoice": null,
        "lob_id": null,
        "customer_lpo": null,
        "created_at": "2021-12-23T09:56:53.000000Z",
        "updated_at": "2021-12-23T10:07:34.000000Z",
        "deleted_at": null,
        "reason": null,
        "need_to_approve": "no",
        "objectid": "",
        "user": {
          "id": 10940,
          "parent_id": "3",
          "firstname": "QAMAR AL MADINA SUPERMARKET LLC BR3",
          "lastname": " ",
          "email": "C14274@gmail.com",
          "customer_info": {
            "id": 18318,
            "user_id": 10940,
            "customer_code": "C14274"
          }
        },
        "salesman_user": {
          "id": 10834,
          "parent_id": null,
          "firstname": "Saifullah",
          "lastname": "Shokatali",
          "email": "Saifullah@gmail.com",
          "salesman_info": {
            "id": 151,
            "user_id": 10834,
            "salesman_code": "4041081"
          }
        },
        "route": {
          "id": 71,
          "route_name": "Ajman",
          "route_code": "Zone 101"
        },
        "depot": null,
        "order": null,
        "invoices": [
          {
            "id": 286596,
            "uuid": "a850c570-63d6-11ec-abfe-c52ad747932b",
            "invoice_id": 203323,
            "item_id": 1808,
            "item_uom_id": 2126,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "7.00",
            "lower_unit_qty": "7.00",
            "item_price": "60.00",
            "item_gross": "420.00",
            "item_discount_amount": "0.00",
            "item_net": "420.00",
            "item_vat": "21.00",
            "item_excise": "0.00",
            "item_grand_total": "441.00",
            "variant_id": 25,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-23T09:56:53.000000Z",
            "updated_at": "2021-12-23T09:56:53.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 25,
              "name": "Ecudor ",
              "code": "ECU"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1808,
              "item_name": "Banana",
              "item_code": "100052",
              "lower_unit_uom_id": 2126
            },
            "item_uom": {
              "id": 2126,
              "name": "13Kg/Box",
              "code": "UOM027"
            }
          }
        ],
        "order_type": {
          "id": 1,
          "name": "Cash",
          "description": ""
        },
        "invoice_reminder": null,
        "lob": null
      },
      {
        "id": 202097,
        "uuid": "dd25caf0-6305-11ec-8c94-49923c15791c",
        "organisation_id": 6,
        "customer_id": 10940,
        "depot_id": null,
        "order_id": null,
        "order_type_id": 1,
        "delivery_id": null,
        "salesman_id": 10834,
        "route_id": 71,
        "trip_id": 3716,
        "invoice_type": "1",
        "invoice_number": "01I100068",
        "invoice_date": "2021-12-22",
        "invoice_due_date": "2021-12-22",
        "payment_term_id": 0,
        "total_qty": "0.00",
        "total_gross": "122.00",
        "total_discount_amount": "0.00",
        "total_net": "122.00",
        "total_vat": "6.10",
        "total_excise": "0.00",
        "grand_total": "128.10",
        "rounding_off_amount": "0.00",
        "pending_credit": "0.000",
        "pdc_amount": "0.000",
        "is_exchange": 1,
        "exchange_number": null,
        "current_stage": "Approved",
        "current_stage_comment": null,
        "approval_status": "Created",
        "is_sync": 1,
        "erp_id": "Invoice for Sales Order: 01I100068 has been posted, Invoice: MPerf01I100068  ",
        "erp_status": "Pass",
        "payment_received": 1,
        "source": 1,
        "status": 1,
        "is_premium_invoice": null,
        "lob_id": null,
        "customer_lpo": null,
        "created_at": "2021-12-22T09:02:17.000000Z",
        "updated_at": "2021-12-22T09:09:57.000000Z",
        "deleted_at": null,
        "reason": null,
        "need_to_approve": "no",
        "objectid": "",
        "user": {
          "id": 10940,
          "parent_id": "3",
          "firstname": "QAMAR AL MADINA SUPERMARKET LLC BR3",
          "lastname": " ",
          "email": "C14274@gmail.com",
          "customer_info": {
            "id": 18318,
            "user_id": 10940,
            "customer_code": "C14274"
          }
        },
        "salesman_user": {
          "id": 10834,
          "parent_id": null,
          "firstname": "Saifullah",
          "lastname": "Shokatali",
          "email": "Saifullah@gmail.com",
          "salesman_info": {
            "id": 151,
            "user_id": 10834,
            "salesman_code": "4041081"
          }
        },
        "route": {
          "id": 71,
          "route_name": "Ajman",
          "route_code": "Zone 101"
        },
        "depot": null,
        "order": null,
        "invoices": [
          {
            "id": 281338,
            "uuid": "dd27c000-6305-11ec-8a80-8d0781de7f6e",
            "invoice_id": 202097,
            "item_id": 1809,
            "item_uom_id": 2112,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "42.00",
            "item_gross": "42.00",
            "item_discount_amount": "0.00",
            "item_net": "42.00",
            "item_vat": "2.10",
            "item_excise": "0.00",
            "item_grand_total": "44.10",
            "variant_id": 17,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-22T09:02:17.000000Z",
            "updated_at": "2021-12-22T09:02:17.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 17,
              "name": "Argentina",
              "code": "ARG"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1809,
              "item_name": "Orange Valencia",
              "item_code": "100640",
              "lower_unit_uom_id": 2112
            },
            "item_uom": {
              "id": 2112,
              "name": "15Kg/Box",
              "code": "UOM011"
            }
          },
          {
            "id": 281339,
            "uuid": "dd293520-6305-11ec-85a1-6997339b2c7f",
            "invoice_id": 202097,
            "item_id": 1805,
            "item_uom_id": 2108,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "80.00",
            "item_gross": "80.00",
            "item_discount_amount": "0.00",
            "item_net": "80.00",
            "item_vat": "4.00",
            "item_excise": "0.00",
            "item_grand_total": "84.00",
            "variant_id": 6,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-22T09:02:17.000000Z",
            "updated_at": "2021-12-22T09:02:17.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 6,
              "name": "Serbia",
              "code": "SRB"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1805,
              "item_name": "Apple Green ",
              "item_code": "100011",
              "lower_unit_uom_id": 2108
            },
            "item_uom": {
              "id": 2108,
              "name": "18Kg/Box",
              "code": "UOM007"
            }
          }
        ],
        "order_type": {
          "id": 1,
          "name": "Cash",
          "description": ""
        },
        "invoice_reminder": null,
        "lob": null
      },
      {
        "id": 202094,
        "uuid": "899e5d50-6305-11ec-9ec1-658387269bdd",
        "organisation_id": 6,
        "customer_id": 10940,
        "depot_id": null,
        "order_id": null,
        "order_type_id": 1,
        "delivery_id": null,
        "salesman_id": 10834,
        "route_id": 71,
        "trip_id": 3716,
        "invoice_type": "1",
        "invoice_number": "01I100067",
        "invoice_date": "2021-12-22",
        "invoice_due_date": "2021-12-22",
        "payment_term_id": 0,
        "total_qty": "0.00",
        "total_gross": "360.00",
        "total_discount_amount": "0.00",
        "total_net": "360.00",
        "total_vat": "18.00",
        "total_excise": "0.00",
        "grand_total": "378.00",
        "rounding_off_amount": "0.00",
        "pending_credit": "0.000",
        "pdc_amount": "0.000",
        "is_exchange": 1,
        "exchange_number": null,
        "current_stage": "Approved",
        "current_stage_comment": null,
        "approval_status": "Created",
        "is_sync": 1,
        "erp_id": "Invoice for Sales Order: 01I100067 has been posted, Invoice: MPerf01I100067  ",
        "erp_status": "Pass",
        "payment_received": 1,
        "source": 1,
        "status": 1,
        "is_premium_invoice": null,
        "lob_id": null,
        "customer_lpo": null,
        "created_at": "2021-12-22T08:59:57.000000Z",
        "updated_at": "2021-12-22T09:00:17.000000Z",
        "deleted_at": null,
        "reason": null,
        "need_to_approve": "no",
        "objectid": "",
        "user": {
          "id": 10940,
          "parent_id": "3",
          "firstname": "QAMAR AL MADINA SUPERMARKET LLC BR3",
          "lastname": " ",
          "email": "C14274@gmail.com",
          "customer_info": {
            "id": 18318,
            "user_id": 10940,
            "customer_code": "C14274"
          }
        },
        "salesman_user": {
          "id": 10834,
          "parent_id": null,
          "firstname": "Saifullah",
          "lastname": "Shokatali",
          "email": "Saifullah@gmail.com",
          "salesman_info": {
            "id": 151,
            "user_id": 10834,
            "salesman_code": "4041081"
          }
        },
        "route": {
          "id": 71,
          "route_name": "Ajman",
          "route_code": "Zone 101"
        },
        "depot": null,
        "order": null,
        "invoices": [
          {
            "id": 281328,
            "uuid": "89a0d340-6305-11ec-b99d-5bc0b44c7a39",
            "invoice_id": 202094,
            "item_id": 1808,
            "item_uom_id": 2126,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "6.00",
            "lower_unit_qty": "6.00",
            "item_price": "60.00",
            "item_gross": "360.00",
            "item_discount_amount": "0.00",
            "item_net": "360.00",
            "item_vat": "18.00",
            "item_excise": "0.00",
            "item_grand_total": "378.00",
            "variant_id": 25,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-22T08:59:57.000000Z",
            "updated_at": "2021-12-22T08:59:57.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 25,
              "name": "Ecudor ",
              "code": "ECU"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1808,
              "item_name": "Banana",
              "item_code": "100052",
              "lower_unit_uom_id": 2126
            },
            "item_uom": {
              "id": 2126,
              "name": "13Kg/Box",
              "code": "UOM027"
            }
          }
        ],
        "order_type": {
          "id": 1,
          "name": "Cash",
          "description": ""
        },
        "invoice_reminder": null,
        "lob": null
      },
      {
        "id": 201003,
        "uuid": "c3e6abd0-6237-11ec-9139-e180e55d2f2a",
        "organisation_id": 6,
        "customer_id": 10940,
        "depot_id": null,
        "order_id": null,
        "order_type_id": 1,
        "delivery_id": null,
        "salesman_id": 10834,
        "route_id": 71,
        "trip_id": 3652,
        "invoice_type": "1",
        "invoice_number": "01I100039",
        "invoice_date": "2021-12-21",
        "invoice_due_date": "2021-12-21",
        "payment_term_id": 0,
        "total_qty": "0.00",
        "total_gross": "72.00",
        "total_discount_amount": "0.00",
        "total_net": "72.00",
        "total_vat": "3.60",
        "total_excise": "0.00",
        "grand_total": "75.60",
        "rounding_off_amount": "0.00",
        "pending_credit": "0.000",
        "pdc_amount": "0.000",
        "is_exchange": 1,
        "exchange_number": null,
        "current_stage": "Approved",
        "current_stage_comment": null,
        "approval_status": "Created",
        "is_sync": 1,
        "erp_id": "Invoice for Sales Order: 01I100039 has been posted, Invoice: MPerf01I100039  ",
        "erp_status": "Pass",
        "payment_received": 1,
        "source": 1,
        "status": 1,
        "is_premium_invoice": null,
        "lob_id": null,
        "customer_lpo": null,
        "created_at": "2021-12-21T08:26:59.000000Z",
        "updated_at": "2021-12-21T08:27:59.000000Z",
        "deleted_at": null,
        "reason": null,
        "need_to_approve": "no",
        "objectid": "",
        "user": {
          "id": 10940,
          "parent_id": "3",
          "firstname": "QAMAR AL MADINA SUPERMARKET LLC BR3",
          "lastname": " ",
          "email": "C14274@gmail.com",
          "customer_info": {
            "id": 18318,
            "user_id": 10940,
            "customer_code": "C14274"
          }
        },
        "salesman_user": {
          "id": 10834,
          "parent_id": null,
          "firstname": "Saifullah",
          "lastname": "Shokatali",
          "email": "Saifullah@gmail.com",
          "salesman_info": {
            "id": 151,
            "user_id": 10834,
            "salesman_code": "4041081"
          }
        },
        "route": {
          "id": 71,
          "route_name": "Ajman",
          "route_code": "Zone 101"
        },
        "depot": null,
        "order": null,
        "invoices": [
          {
            "id": 276716,
            "uuid": "c3e8ae50-6237-11ec-8f9e-ed159d12314b",
            "invoice_id": 201003,
            "item_id": 1804,
            "item_uom_id": 2108,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "72.00",
            "item_gross": "72.00",
            "item_discount_amount": "0.00",
            "item_net": "72.00",
            "item_vat": "3.60",
            "item_excise": "0.00",
            "item_grand_total": "75.60",
            "variant_id": 5,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-21T08:26:59.000000Z",
            "updated_at": "2021-12-21T08:26:59.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 5,
              "name": "Poland",
              "code": "POL"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1804,
              "item_name": "Apple Royal Gala",
              "item_code": "100022",
              "lower_unit_uom_id": 2108
            },
            "item_uom": {
              "id": 2108,
              "name": "18Kg/Box",
              "code": "UOM007"
            }
          }
        ],
        "order_type": {
          "id": 1,
          "name": "Cash",
          "description": ""
        },
        "invoice_reminder": null,
        "lob": null
      },
      {
        "id": 199821,
        "uuid": "694d9b30-616a-11ec-b29b-158bb8f49139",
        "organisation_id": 6,
        "customer_id": 10940,
        "depot_id": null,
        "order_id": null,
        "order_type_id": 1,
        "delivery_id": null,
        "salesman_id": 10834,
        "route_id": 71,
        "trip_id": 3568,
        "invoice_type": "1",
        "invoice_number": "01I100017",
        "invoice_date": "2021-12-20",
        "invoice_due_date": "2021-12-20",
        "payment_term_id": 0,
        "total_qty": "0.00",
        "total_gross": "282.00",
        "total_discount_amount": "0.00",
        "total_net": "282.00",
        "total_vat": "14.10",
        "total_excise": "0.00",
        "grand_total": "296.10",
        "rounding_off_amount": "0.00",
        "pending_credit": "0.000",
        "pdc_amount": "0.000",
        "is_exchange": 1,
        "exchange_number": null,
        "current_stage": "Approved",
        "current_stage_comment": null,
        "approval_status": "Created",
        "is_sync": 1,
        "erp_id": "Invoice for Sales Order: 01I100017 has been posted, Invoice: MPerf01I100017  ",
        "erp_status": "Pass",
        "payment_received": 1,
        "source": 1,
        "status": 1,
        "is_premium_invoice": null,
        "lob_id": null,
        "customer_lpo": null,
        "created_at": "2021-12-20T07:57:00.000000Z",
        "updated_at": "2021-12-20T07:57:20.000000Z",
        "deleted_at": null,
        "reason": null,
        "need_to_approve": "no",
        "objectid": "",
        "user": {
          "id": 10940,
          "parent_id": "3",
          "firstname": "QAMAR AL MADINA SUPERMARKET LLC BR3",
          "lastname": " ",
          "email": "C14274@gmail.com",
          "customer_info": {
            "id": 18318,
            "user_id": 10940,
            "customer_code": "C14274"
          }
        },
        "salesman_user": {
          "id": 10834,
          "parent_id": null,
          "firstname": "Saifullah",
          "lastname": "Shokatali",
          "email": "Saifullah@gmail.com",
          "salesman_info": {
            "id": 151,
            "user_id": 10834,
            "salesman_code": "4041081"
          }
        },
        "route": {
          "id": 71,
          "route_name": "Ajman",
          "route_code": "Zone 101"
        },
        "depot": null,
        "order": null,
        "invoices": [
          {
            "id": 271784,
            "uuid": "694f8cf0-616a-11ec-b4a7-41367f76b7cf",
            "invoice_id": 199821,
            "item_id": 1808,
            "item_uom_id": 2126,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "3.00",
            "lower_unit_qty": "3.00",
            "item_price": "55.00",
            "item_gross": "165.00",
            "item_discount_amount": "0.00",
            "item_net": "165.00",
            "item_vat": "8.25",
            "item_excise": "0.00",
            "item_grand_total": "173.25",
            "variant_id": 25,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-20T07:57:00.000000Z",
            "updated_at": "2021-12-20T07:57:00.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 25,
              "name": "Ecudor ",
              "code": "ECU"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1808,
              "item_name": "Banana",
              "item_code": "100052",
              "lower_unit_uom_id": 2126
            },
            "item_uom": {
              "id": 2126,
              "name": "13Kg/Box",
              "code": "UOM027"
            }
          },
          {
            "id": 271785,
            "uuid": "69511070-616a-11ec-9ec9-23876684a91d",
            "invoice_id": 199821,
            "item_id": 1809,
            "item_uom_id": 2112,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "42.00",
            "item_gross": "42.00",
            "item_discount_amount": "0.00",
            "item_net": "42.00",
            "item_vat": "2.10",
            "item_excise": "0.00",
            "item_grand_total": "44.10",
            "variant_id": 17,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-20T07:57:00.000000Z",
            "updated_at": "2021-12-20T07:57:00.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 17,
              "name": "Argentina",
              "code": "ARG"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1809,
              "item_name": "Orange Valencia",
              "item_code": "100640",
              "lower_unit_uom_id": 2112
            },
            "item_uom": {
              "id": 2112,
              "name": "15Kg/Box",
              "code": "UOM011"
            }
          },
          {
            "id": 271786,
            "uuid": "69528be0-616a-11ec-a296-c97cb0e423f3",
            "invoice_id": 199821,
            "item_id": 1804,
            "item_uom_id": 2108,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "75.00",
            "item_gross": "75.00",
            "item_discount_amount": "0.00",
            "item_net": "75.00",
            "item_vat": "3.75",
            "item_excise": "0.00",
            "item_grand_total": "78.75",
            "variant_id": 5,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-20T07:57:00.000000Z",
            "updated_at": "2021-12-20T07:57:00.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 5,
              "name": "Poland",
              "code": "POL"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1804,
              "item_name": "Apple Royal Gala",
              "item_code": "100022",
              "lower_unit_uom_id": 2108
            },
            "item_uom": {
              "id": 2108,
              "name": "18Kg/Box",
              "code": "UOM007"
            }
          }
        ],
        "order_type": {
          "id": 1,
          "name": "Cash",
          "description": ""
        },
        "invoice_reminder": null,
        "lob": null
      },
      {
        "id": 198784,
        "uuid": "48d25e00-60a2-11ec-96c7-c16e25f31dea",
        "organisation_id": 6,
        "customer_id": 10940,
        "depot_id": null,
        "order_id": null,
        "order_type_id": 1,
        "delivery_id": null,
        "salesman_id": 10834,
        "route_id": 71,
        "trip_id": 3516,
        "invoice_type": "1",
        "invoice_number": "01I100004",
        "invoice_date": "2021-12-19",
        "invoice_due_date": "2021-12-19",
        "payment_term_id": 0,
        "total_qty": "0.00",
        "total_gross": "129.00",
        "total_discount_amount": "0.00",
        "total_net": "129.00",
        "total_vat": "6.45",
        "total_excise": "0.00",
        "grand_total": "135.45",
        "rounding_off_amount": "0.00",
        "pending_credit": "0.000",
        "pdc_amount": "0.000",
        "is_exchange": 1,
        "exchange_number": null,
        "current_stage": "Approved",
        "current_stage_comment": null,
        "approval_status": "Created",
        "is_sync": 1,
        "erp_id": "Invoice for Sales Order: 01I100004 has been posted, Invoice: MPerf01I100004  ",
        "erp_status": "Pass",
        "payment_received": 1,
        "source": 1,
        "status": 1,
        "is_premium_invoice": null,
        "lob_id": null,
        "customer_lpo": null,
        "created_at": "2021-12-19T08:04:26.000000Z",
        "updated_at": "2021-12-19T08:05:06.000000Z",
        "deleted_at": null,
        "reason": null,
        "need_to_approve": "no",
        "objectid": "",
        "user": {
          "id": 10940,
          "parent_id": "3",
          "firstname": "QAMAR AL MADINA SUPERMARKET LLC BR3",
          "lastname": " ",
          "email": "C14274@gmail.com",
          "customer_info": {
            "id": 18318,
            "user_id": 10940,
            "customer_code": "C14274"
          }
        },
        "salesman_user": {
          "id": 10834,
          "parent_id": null,
          "firstname": "Saifullah",
          "lastname": "Shokatali",
          "email": "Saifullah@gmail.com",
          "salesman_info": {
            "id": 151,
            "user_id": 10834,
            "salesman_code": "4041081"
          }
        },
        "route": {
          "id": 71,
          "route_name": "Ajman",
          "route_code": "Zone 101"
        },
        "depot": null,
        "order": null,
        "invoices": [
          {
            "id": 267099,
            "uuid": "48d43e80-60a2-11ec-9038-95a586c61b3d",
            "invoice_id": 198784,
            "item_id": 1808,
            "item_uom_id": 2126,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "54.00",
            "item_gross": "54.00",
            "item_discount_amount": "0.00",
            "item_net": "54.00",
            "item_vat": "2.70",
            "item_excise": "0.00",
            "item_grand_total": "56.70",
            "variant_id": 25,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-19T08:04:26.000000Z",
            "updated_at": "2021-12-19T08:04:26.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 25,
              "name": "Ecudor ",
              "code": "ECU"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1808,
              "item_name": "Banana",
              "item_code": "100052",
              "lower_unit_uom_id": 2126
            },
            "item_uom": {
              "id": 2126,
              "name": "13Kg/Box",
              "code": "UOM027"
            }
          },
          {
            "id": 267100,
            "uuid": "48d5aa80-60a2-11ec-9335-cf6dc7efd56d",
            "invoice_id": 198784,
            "item_id": 1804,
            "item_uom_id": 2108,
            "discount_id": null,
            "is_free": 0,
            "is_item_poi": 0,
            "promotion_id": null,
            "item_qty": "1.00",
            "lower_unit_qty": "1.00",
            "item_price": "75.00",
            "item_gross": "75.00",
            "item_discount_amount": "0.00",
            "item_net": "75.00",
            "item_vat": "3.75",
            "item_excise": "0.00",
            "item_grand_total": "78.75",
            "variant_id": 5,
            "item_class_id": 1,
            "batch_number": null,
            "created_at": "2021-12-19T08:04:26.000000Z",
            "updated_at": "2021-12-19T08:04:26.000000Z",
            "deleted_at": null,
            "item_variant": {
              "id": 5,
              "name": "Poland",
              "code": "POL"
            },
            "item_class": {
              "id": 1,
              "name": "1",
              "code": "1"
            },
            "item": {
              "id": 1804,
              "item_name": "Apple Royal Gala",
              "item_code": "100022",
              "lower_unit_uom_id": 2108
            },
            "item_uom": {
              "id": 2108,
              "name": "18Kg/Box",
              "code": "UOM007"
            }
          }
        ],
        "order_type": {
          "id": 1,
          "name": "Cash",
          "description": ""
        },
        "invoice_reminder": null,
        "lob": null
      }
    ],
    "message": "Invoices listing",
    "errors": [
      
    ],
    "pagination": {
      "total_pages": 1,
      "current_page": 10,
      "total_records": 6
    }
}
