<mat-drawer-container class="formdrawer-container" hasBackdrop="false">
  <mat-drawer id="formDrawer" mode="over" position="end" #formDrawer class="border">
    <div style="min-width: 360px;">
      <app-add-expenses (updateTableData)="updateTableData($event)"></app-add-expenses>
    </div>
  </mat-drawer>

  <section class="scrollxy">
    <div class="table-box" [ngClass]="{ collapsed: isDetailVisible }">
      <div class="heading-row ">
        <p class="h4 font-weight-light d-inline" *ngIf="!isDetailVisible">
          Expense
        </p>
        <div class="d-inline float-right">
          <button type="button" class="btn btn-sm btn-p mr-2" appPermission [permissions]="userPermissions"
            [type]="'create'" (click)="openAddExpenses()" disabled>
            <i class="os-icon os-icon-plus mr-2" aria-hidden="true"></i>New
          </button>
          <button *ngIf="checkedRows.length > 0" class="btn btn-sm btn-white dropdown-toggle ml-2" mat-button
            [matMenuTriggerFor]="menu2">
            Bulk Actions
          </button>
          <mat-menu #menu2="matMenu">
            <button mat-menu-item (click)="bulkAction('active')">Mark as Active</button>
            <button mat-menu-item (click)="bulkAction('inactive')" >Mark as Inactive</button>
            <!-- <button mat-menu-item (click)="bulkAction('delete')" appPermission [permissions]="userPermissions" [type]="'delete'">
              Delete
            </button> -->
          </mat-menu>
        </div>
      </div>
      <app-expensesdt (selectedRows)="selectedRows($event)" [newExpenseData]="newExpenseData" (itemClicked)="itemClicked($event)"></app-expensesdt>
    </div>
    <div class="details-box" [ngClass]="{ expanded: isDetailVisible }">

      <app-expense-detail (detailsClosed)="closeClicked()" [isDetailVisible]="isDetailVisible" [expense]="exp">
      </app-expense-detail>
    </div>
  </section>
</mat-drawer-container>