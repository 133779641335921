import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { MasterService } from '../../main/master/master.service';
import { PosService } from './pos.service';

@Injectable({
  providedIn: 'root'
})
export class PosResolveService implements Resolve<any> {

  constructor(private posService: PosService, private apiService: ApiService) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const obj = {
      "list_data": ["country", "country_master", "org_country", "region", "sales_organisation", "area",
        "channel", "customer_category", "route", "customer_type", "payment_term", "merchandiser"],
      "function_for": "customer"
    }
     const posList = this.posService.posDetailListTable({ page: 1, page_size: 10 }).pipe(map(result => result));
    // const customerAdd = this.masterService.masterList(obj).pipe(map(result => result));
    // const lobList = this.masterService.customerLobList().pipe(map(result => result));
    // const creditLimit = this.apiService.getCreditLimits().pipe(map(result => result));
    // const customerGroup = this.apiService.getCustomerGroup().pipe(map(result => result));
    // // console.log(lobList);
     return forkJoin({ posList });
    //return;
  }
}
