<section class="scrolly">
  <ng-container *ngIf="advanceSearchRequest.length>0">
    <app-search-criteria [data]="advanceSearchRequest" (change)="onChangeCriteria()" (close)="onCloseCriteria()">
    </app-search-criteria>
  </ng-container>
  <div class="del-margin-bottom">
    <div class="table-responsive">
      <table class="table table-hover" mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <button class="btn btn-sm filter-btn" *ngIf="!isDetailVisible" [matMenuTriggerFor]="menu">
              <i class="fa fa-table" aria-hidden="true"></i>
            </button>
            <mat-menu #menu="matMenu">
              <div class="card-body p-2" style="min-width: 200px;">
                <ul style="list-style: none; padding-left: 7px;">
                  <li *ngFor="let column of filterColumns">
                    <input type="checkbox" [(ngModel)]="column.show" class="m-2" (click)="$event.stopPropagation()" />
                    {{ column.title }}
                  </li>
                </ul>
                <div class="float-right mb-2">
                  <button class="btn btn-p bg-secondary">Cancel</button>
                </div>
              </div>
            </mat-menu>
            <mat-checkbox class="head-checkbox" (change)="$event ? toggleSelection() : null"
              (change)="getSelectedRows()" [checked]="selections.hasValue() && isAllSelected()"
              [indeterminate]="selections.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
              (change)="$event ? selections.toggle(row) : null" (change)="getSelectedRows()"
              [checked]="selections.isSelected(row)" [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="misc">
          <th mat-header-cell *matHeaderCellDef>Invoices</th>
          <td mat-cell *matCellDef="let element" class="misc-cell">
            <span class="col-name">{{
              element.customer
              ? element.customer.customer_name
              : element?.depot?.depot_name
              }}</span>
            <span class="col-price">{{
              numberFormatWithSymbol(element.grand_total)
              }}</span>
            <span class="col-date">{{ element.order_date }}</span>
            <span class="col-code">{{ element.order_number }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Date" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="invoice_date" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('invoice_date')"
                placeholder="Enter Date" type="date">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element.created_at | date :"d MMM y h:mm a" }}</td>
        </ng-container>
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Invoice#" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="invoice_number" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('invoice_number')"
                placeholder="Enter Invoice#" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element.invoice_number }}</td>
        </ng-container>


        <ng-container matColumnDef="customer_code">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Customer Code#" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="customer_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_code')"
                placeholder="Enter Customer Code#" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">{{ element?.user?.customer_info?.customer_code}}</td>
        </ng-container>


        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Customer Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="customer_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_name')"
                placeholder="Enter Customer Name" type="text">
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{
            element.user
            ? element?.user?.firstname + " " + element?.user?.lastname
            : element?.depot?.depot_name
            }}
          </td>
        </ng-container>
        <!--  Route Code-->
        <ng-container matColumnDef="route_code">
          <th mat-header-cell *matHeaderCellDef>

            <app-column-filter title="Route Code" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="route_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('route_code')"
                placeholder="Enter Route Code" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.route?.route_code }}</td>
        </ng-container>
        <!--  End Route code -->
        <!-- Route Name -->
        <ng-container matColumnDef="route_name">
          <th mat-header-cell *matHeaderCellDef>

            <app-column-filter title="Route Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="route_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('route_name')"
                placeholder="Enter Route Name" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.route?.route_name }}</td>
        </ng-container>
        <!-- End Route Name -->
        <!-- Salesman Code -->
        <ng-container matColumnDef="salesman_code">
          <th mat-header-cell *matHeaderCellDef>

            <app-column-filter title="Salesman Code" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="salesman_code" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('salesman_code')"
                placeholder="Enter Salesman Code" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.salesman_user?.salesman_info?.salesman_code }}</td>
        </ng-container>
        <!-- End Salesman Code   -->
        <!-- Salesman Name -->
        <ng-container matColumnDef="salesman_name">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Salesman Name" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="salesman_name" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('salesman_name')"
                placeholder="Enter Salesman Name" type="text">
            </app-column-filter>

          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container>
              {{
              element?.salesman_user == null ? '' :
              element.salesman_user?.firstname + " " + element.salesman_user?.lastname
              }}
            </ng-container>
          </td>
        </ng-container>
        <!-- End Salesman Name -->
        <ng-container matColumnDef="due">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Customer Due Date" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="invoice_due_date" (close)="onColumnFilter($event)">
              <input class="form-control form-control-sm" [formControl]="filterForm.get('invoice_due_date')"
                placeholder="Enter Customer Due Date" type="date">
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.invoice_due_date }}</td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef>Amount</th>
          <td mat-cell *matCellDef="let element">
            {{ numberFormatWithSymbol(element.grand_total) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="approval">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Approval" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="approval" (close)="onColumnFilter($event)">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('approval')">
                <mat-option value="1">Pending</mat-option>
                <mat-option value="0">Active</mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.current_stage;">
              <span [style.color]="orderStatusColor(element.current_stage)">
                {{ getOrderStatusValue(element.current_stage) }}
              </span>
            </ng-container>

          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.approval_status;">
              <span>
                {{ element.approval_status }}
              </span>
            </ng-container>

          </td>
        </ng-container>
        <ng-container matColumnDef="payment_status">
          <th mat-header-cell *matHeaderCellDef>
            <app-column-filter title="Payment Status" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" controlName="" (close)="onColumnFilter($event)">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('')">
                <mat-option value="1">Pending</mat-option>
                <mat-option value="0">Active</mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{getPaymentStatus(element)}}
          </td>
        </ng-container>
        <ng-container matColumnDef="expand">
          <th mat-header-cell *matHeaderCellDef style="display: none;">Detail</th>
          <td mat-cell *matCellDef="let element">
            <div style="display: flex; justify-content: space-between;">
              <strong>
                {{
                element.user
                ? element?.user?.firstname + " " + element?.user?.lastname
                : element?.depot?.depot_name
                }}</strong>
              <span>
                {{ numberFormatWithSymbol(element.grand_total) }}</span>
            </div>
            <div style="display: flex; justify-content: space-between;">
              <span style="color: #2485e8;">{{ element.invoice_number }}</span>
              <span style="color: #2485e8;">
                {{ element.invoice_due_date | date }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="ERP_status">
          <th class="erp-status" mat-header-cell *matHeaderCellDef>
            <app-column-filter title="ERP Status" (changeSelected)="onColumnFilterOpen($event)"
              [selected]="selectedColumnFilter" (close)="onColumnFilter($event)" controlName="erp_status">
              <mat-select class="form-control form-control-sm" [formControl]="filterForm.get('erp_status')">
                <mat-option value="Not Posted">Not Posted</mat-option>
                <mat-option value="Posted">Posted</mat-option>
                <mat-option value="Failed">Failed</mat-option>
              </mat-select>
            </app-column-filter>
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.erp_id == null || element.erp_id == 0 && !element.erp_status && element.is_sync == 0? 'Not
            Posted': ''}}
            {{ element.erp_status =='Pass' && element.is_sync == 1 ? 'Posted': ''}}
            {{ element.erp_status && element.erp_status != 'Pass' ? 'Failed': ''}}

            <i *ngIf="element.erp_status &&  element.erp_status  != 'Pass'" class="fa fa-info-circle" aria-hidden="true"
              (click)="filterOdooMessageData(element.erp_status);isOdooMessageOpen=true">
            </i>
            <ng-container *ngIf="!isSyncDialogShow">
              <i class="fa fa-refresh" style="margin-left: 4px;"
                *ngIf="element.is_sync == 0 && element.current_stage ==='Approved'"
                (click)="postOdoo(element);isOdooMessageOpen=true"></i>
            </ng-container>


          </td>
        </ng-container>

        <ng-container matColumnDef="ERP_Reason">
          <th mat-header-cell *matHeaderCellDef>ERP Reason</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.is_sync == 1">{{element.erp_status}}</div>
            <div *ngIf="element.is_sync == 0" style="display: flex; justify-content: space-between;">
              <span *ngIf="element.erp_status == null">{{element.erp_status}}</span>
              <span *ngIf="element.erp_status &&  element.erp_status  != 'Pass'">{{element.erp_status}}</span>
            </div>
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" [ngClass]="{ 'misc-row': isDetailVisible }"
          (click)="openDetailView(row);isOdooMessageOpen=false"></tr>
      </table>

    </div>
    <mat-paginator [hidden]="apiResponse.pagination?.total_records < 10" (page)="onPageFired($event)"
      [length]="apiResponse.pagination?.total_records" [pageSize]="pageSize" [pageSizeOptions]="paggingArray">
    </mat-paginator>
  </div>
</section>

<ng-template #dialogRef let-data>
  <div class="card">
    <div class="card-header pt-4">
      <span class="h6 font-weight-bold">ERP failed</span>
      <a [mat-dialog-close]="true" class="closeicon settingclose"><i class="os-icon os-icon-x"></i></a>
    </div>
    <div class="card-body">
      <p>{{data}}</p>
    </div>
  </div>
</ng-template>