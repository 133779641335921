<body>
<section class="">
    <div class="heading-row d-flex align-items-center justify-content-between">
        <h2 class="d-inline mb-0"> <i class="fa fa-file-pdf-o iconCl" aria-hidden="true"></i> Order Vs Invoice Reports</h2>
        <div class="d-inline float-right  pl-0">
            <button mat-button class="btn btn-sm btn-secondary" (click)="goBackToInvoiceList()"><i
                    class="fa fa-angle-left mr-1"></i><span class="back-btn"> Back</span></button>
        </div>
    </div>
    <div class="separator"></div>
    <div class="card">
        <form [formGroup]="sideFiltersForm">
            <div class="card-body">
                <div class="row invoice-base-row d-flex align-items-center">
                    <div class="col-md-2">
                        <div class="form-group smmatinput">
                            <label for="">Start date</label>
                            <input type="date" formControlName="start_date" class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group smmatinput">
                            <label for="">End date</label>
                            <input type="date" formControlName="end_date" class="form-control form-control-sm">
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group smmatinput pt-2 mb-0">
                            <button type="button" class="btn btn-sm btn-p" (click)="showReport(0,'')">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="separator"></div>
</section>
<!-- <div class="table-container">
    <div class="form-group smmatinput" style="float: right;margin: 10px;">
      <button type="button" class="btn btn-sm btn-p" (click)="showOrder(1,'CSV')">Export CSV</button>
    </div>
    <div class="table-responsive">
      <mat-table *ngIf="dataSource" [dataSource]="dataSource" class="mat-elevation-z8 table-hover table-scroll">
        <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column?.field">
          <mat-header-cell *matHeaderCellDef>
            {{ column.field }}
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <ng-container *ngIf="column?.value=='order_number'">
              <a href="javascript:void(0)" (click)="navigatePage(row)"
                *ngIf="row?.status=='Created' || row?.status=='Updated'">{{ row[column?.value] }}</a>
              <span *ngIf="row?.status!='Created' && row?.status!='Updated'">{{ row[column?.value] }}</span>
            </ng-container>
            <ng-container *ngIf="column?.value!='order_number'">
              {{ row[column?.value] }}
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator pageSize="50" [pageSizeOptions]="[5,10,15,25,50,100]"></mat-paginator>
  </div> -->
  <div class="form-group smmatinput-btn d-flex justify-content-end" style="margin: 10px;">
    <button type="button" class="btn btn-sm btn-p" (click)="showReport(1,'CSV')">Export CSV</button>
  </div>
<div class=" items-table-box pr-1">
    <div class="table-responsive">
        <table mat-table [dataSource]="dataSource" class=" table table-hover invoice-base-table">
            <ng-container matColumnDef="order_no">
                <th mat-header-cell *matHeaderCellDef>Order No</th>
                <td mat-cell *matCellDef="let element">{{ element?.order?.order_number }}</td>
            </ng-container>
            <ng-container matColumnDef="delivery_date">
                <th mat-header-cell *matHeaderCellDef>Delivery Date</th>
                <td mat-cell *matCellDef="let element">{{ element?.delivery_date }}</td>
            </ng-container>
            <ng-container matColumnDef="item_code">
                <th mat-header-cell *matHeaderCellDef>Item Code</th>
                <td mat-cell *matCellDef="let element">{{ element?.item_code }}</td>
            </ng-container>
            <ng-container matColumnDef="item_name">
                <th mat-header-cell *matHeaderCellDef>Item Name</th>
                <td mat-cell *matCellDef="let element">{{ element?.items?.item_name }}</td>
            </ng-container>
            <ng-container matColumnDef="order_qty">
                <th mat-header-cell *matHeaderCellDef>Order Qty</th>
                <td mat-cell *matCellDef="let element">{{ element?.item_qty }}</td>
            </ng-container>
            <ng-container matColumnDef="order_amount">
                <th mat-header-cell *matHeaderCellDef>Order Amount</th>
                <td mat-cell *matCellDef="let element">
                    {{ element?.total_amount }}
                </td>
            </ng-container>
            <ng-container matColumnDef="invoice_no">
                <th mat-header-cell *matHeaderCellDef>Invoice No</th>
                <td mat-cell *matCellDef="let element">{{ element?.invoice_number }}</td>
            </ng-container>
            <ng-container matColumnDef="invoice_qty">
                <th mat-header-cell *matHeaderCellDef>Invoice Qty</th>
                <td mat-cell *matCellDef="let element">{{ element?.invoice_qty }}</td>
            </ng-container>
            <ng-container matColumnDef="invoice_amount">
                <th mat-header-cell *matHeaderCellDef>Invoice Amount</th>
                <td mat-cell *matCellDef="let element">{{ element.invoice_amount }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
        </table>
    </div>
    <mat-paginator pageSize="50" [pageSizeOptions]="[5, 10, 15, 25, 50, 100]"></mat-paginator>
</div>
</body>