import { Component, Input, OnInit } from '@angular/core';
import { Customer } from 'src/app/components/main/transaction/collection/collection-models';
import { customerData } from 'src/app/features/shared/mock-data/mock-data.component';

@Component({
  selector: 'app-left-panel-overview',
  templateUrl: './left-panel-overview.component.html',
  styleUrls: ['./left-panel-overview.component.scss']
})
export class LeftPanelOverviewComponent implements OnInit {
  @Input() public customer: any;
  @Input() public lobInfo: any;
  public domain = window.location.host.split('.')[0];
  public showCollapsedAttr = false;
  public showCollapsedAddr = false;
  public showCollapsedPartner = true;
  _RETURN = 'Return';
  _CREDITNOTE = 'Credit Limit';
  _SALES = 'Sales';
  _ORDER = 'Order';
  _BLANKBLOACK = 'Order';
  
  constructor() { }

  ngOnInit(): void {
  }

  getDocString(string) {
    var str = string.split("/");
    return str[str.length - 1];
  }
  downloadFile(doc) {
    var url = doc.doc_string;
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `${url}`);
    link.setAttribute('download', `${url}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  getDueOn(val) {
    if (val == '2') {
      return 'Customer Statement';
    } else {
      return 'Invoice Date';
    }
  }

  checkBlockValue(value) {
    if (this.lobInfo?.customer_block_types)
      return this.lobInfo?.customer_block_types.find(x => x.type == value)?.is_block == 1 || false;
    else {
      return false;
    }
  }

  getDateBlockDate(value) {
    //debugger;
    var obj = this.lobInfo?.customer_block_types?.find(x => x.type == value);

    if (this.lobInfo?.customer_block_types && obj?.is_block == 0)
      return `${obj.start_date} / ${obj.end_date}`;
    else {
      return '';
    }
  }


  checkLOBBlockValue(value) {
    if (this.customer?.customer_block_types)
      return this.customer?.customer_block_types.find(x => x.type == value)?.is_block == 1 || false;
    else {
      return false;
    }
  }

  getLOBDateBlockDate(value) {
    //debugger;
    var obj = this.customer?.customer_block_types.find(x => x.type == value);

    if (this.customer?.customer_block_types && obj?.is_block == 0)
      return `${obj.start_date} / ${obj.end_date}`;
    else {
      return '';
    }
  }

}
