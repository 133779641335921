import { Component, OnInit } from '@angular/core';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { NavigationEnd, Router } from '@angular/router';
import { SidenavService } from 'src/app/services/sidenav.service';

@Component({
  selector: 'app-settings-drawer',
  templateUrl: './settings-drawer.component.html',
  styleUrls: ['./settings-drawer.component.scss']
})
export class SettingsDrawerComponent implements OnInit {
  panelOpenState;
  sidebarSettingConfig = [];
  module_name = "";
  sidebar = [];
  activePath: string;
  constructor(
    private fds: FormDrawerService,
    private router: Router,
    private sidenavService: SidenavService
  ) { }

  ngOnInit(): void {
    this.initSideBar();
  }

  initSideBar() {
    this.activePath = this.router.url;
    console.log('navbarActivePath', this.activePath);
    //let appName = sessionStorage.getItem('appName');
    if (this.activePath.includes('/loyality')) {
      localStorage.setItem('loyalityNav', JSON.stringify(true));
      //this.getLoyalityNav();
      this.sidebarSettingConfig = [];
      this.getLoyalitySettings();
    } else if (this.activePath.includes('/portal')) {
      localStorage.setItem('portalNav', JSON.stringify(true));
      this.sidebarSettingConfig = [];
      this.getPortalSettings();
    }
    else {
      if (this.sidebarSettingConfig.length == 0) {
        this.sidebarSettingConfig = [];
        this.getSideBar();
      }
    }
  }

  close() {
    this.fds.closeNav();
  }
  navigateTo(s: string) {
    console.log(s)
    if (s == 'settings/item/class' || s == 'settings/item/variant') {
      this.router.navigate([s])

    } else {

      let path: string = this.router.url;
      if (!(path.includes('settings'))) {
        this.sidenavService.setBackNavigationPath(path);
        // //console.log("path is: ",this.sidenavService.getBackNavigationPath());
      }
      this.fds.closeNav();
      this.router.navigate([s])
    }
  }
  displayRoles(): boolean {
    if (localStorage.getItem('userType') == '1' || localStorage.getItem('userType') == '0') {
      return true;
    } else {
      return false;
    }
  }

  getSideBar() {
    this.sidenavService.getSettingSideBar().subscribe((res) => {
      this.sidebarSettingConfig = res;
    });
  }

  getLoyalitySettings() {
    this.sidenavService.getLoyalitySettingsSideBar().subscribe((res) => {
      this.sidebarSettingConfig = res;
    });
  }

  getPortalSettings() {
    this.sidenavService.getPortalSettingsSideBar().subscribe((res) => {

      let permission = JSON.parse(localStorage.getItem('portalPermissions'))
      
      this.sidebarSettingConfig = res.filter((p:any)=> permission.some(r=>p.label === r.moduleName));
      this.sidebarSettingConfig = res;
      // console.log(this.sidebarSettingConfig)
    });
  }

  featureCheck(value) {
    return this.sidenavService.featureCheck(value);
  }

  portalSetting(value) {
    // console. log(this.sidenavservice. portalsettingsear(value))
    return this.sidenavService.portalsettingsBar(value);
  }

  settingFeatureCheckDropDowns(value) {
    return this.sidenavService.settingFeatureCheckDropDowns(value);
  }

  sumenuExistCheck(submenu, hasSubmenu) {
    return this.sidenavService.sumenuExistCheck(submenu, hasSubmenu);
  }

  settingFeatureCheck(value, hasSubmenu) {
    return this.sidenavService.settingFeatureCheck(value, hasSubmenu);
  }


  //For Portal Side
  checkPortalSidebar() {
    const value = JSON.parse(localStorage.getItem('isPortalLoggedIn'))
    if (value && value === true && this.router.url.includes('portal')) {
      return true;
    } else {
      return false;
    }
  }
}
