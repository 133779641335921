<mat-drawer-container class="formdrawer-container" hasBackdrop="false">
  <mat-drawer id="formDrawer" mode="over" position="end" #formDrawer class="border">
    <div style="min-width: 400px;">
      <app-history [module]="module" (close)="onClose()"></app-history>
    </div>
  </mat-drawer>

  <section class="overflow-hidden">
    <div class="table-box customerbox" [ngClass]="{ collapsed: isDetailVisible }">
      <div class="heading-row clearfix">
        <p class="d-md-inline" *ngIf="!isDetailVisible">
          <i class="os-icon os-icon-user mr-2" aria-hidden="true"></i> Credit
          Notes
        </p>
        <div class="d-inline float-right">
          <!-- <button type="button" class="btn btn-sm btn-p" (click)="openForm()" appPermission
            [permissions]="userPermissions" [type]="'create'" disabled>
            <i class="os-icon os-icon-plus mr-2" aria-hidden="true"></i>New
          </button> -->
          <button *ngIf="checkedRows.length > 0" class="btn btn-sm btn-white dropdown-toggle ml-2" mat-button
            [matMenuTriggerFor]="menu2">
            Bulk Actions
          </button>
          <mat-menu #menu2="matMenu">
            <button mat-menu-item (click)="bulkAction('active')">Mark as Active</button>
            <button mat-menu-item (click)="bulkAction('inactive')">Mark as Inactive</button>
            <!-- <button mat-menu-item (click)="bulkAction('delete')" appPermission [permissions]="userPermissions" [type]="'delete'">
              Delete
            </button> -->
          </mat-menu>
          <!-- impot /Export  -->
          <!-- <button class="btn btn-sm btn-white ml-2 dropdown-toggle" [matMenuTriggerFor]="menu3">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </button>
          <mat-menu #menu3="matMenu">
            <button mat-menu-item (click)="openImportCredit()" disabled>Import Credit Note</button>
            <button mat-menu-item type="button" (click)="openExportCredit()">Export Credit Note</button>
            <button mat-menu-item type="button" (click)="openUpdateCredit()" disabled>Update Credit Note</button>
            <button mat-menu-item type="button" *ngIf="isSyncDialogShow" (click)="openSyncDialog()">Sync Credit Note</button>
          </mat-menu> -->
        </div>
      </div>
      <app-credit-note-data-table (selectedRows)="selectedRows($event)" (detailsClosed)="closeClicked()"
        [newCreditData]="newCreditData" (itemClicked)="itemClicked($event)"></app-credit-note-data-table>
    </div>
    <div class="details-box" [ngClass]="{ expanded: isDetailVisible }">
      <app-credit-note-detail (detailsClosed)="closeClicked()" [isDetailVisible]="isDetailVisible"
        [creditNoteData]="creditNoteData" (toggleHistory)="openHistoryView()">
      </app-credit-note-detail>
    </div>
  </section>
</mat-drawer-container>