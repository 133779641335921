import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Users } from 'src/app/components/datatables/users-dt/users-dt.component';
import { CompDataServiceType } from 'src/app/services/constants';
import { DataEditor } from 'src/app/services/data-editor.service';
import { FormDrawerService } from 'src/app/services/form-drawer.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit ,OnChanges {
  @Output() public detailsClosed: EventEmitter<any> = new EventEmitter<any>();
  @Input() public user: Users | any;
  @Input() public isDetailVisible: boolean;

  constructor(private dataService: DataEditor, formDrawer: FormDrawerService) { }

  ngOnInit(): void {
    // console.log(this.user)
  }

  ngOnChanges(){
    // console.log(this.user)
  }

  public closeDetailView(): void {
    this.isDetailVisible = false;
    this.detailsClosed.emit();
    this.dataService.sendData({ type: CompDataServiceType.CLOSE_DETAIL_PAGE });
  }


}
