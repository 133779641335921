import { Component, Input, OnInit } from '@angular/core';
import { Customer } from 'src/app/components/main/transaction/collection/collection-models';
import { customerData } from 'src/app/features/shared/mock-data/mock-data.component';
import { PortalInfoService } from '../../../portal-info.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-right-panel-overview',
  templateUrl: './right-panel-overview.component.html',
  styleUrls: ['./right-panel-overview.component.scss'],
})
export class RightPanelOverviewComponent implements OnInit {
  @Input() public customer: any;
  @Input() public lobInfo: any;
  public notOpenedFromOverViewRightPanel: boolean = false;
  public canvas: any;
  public ctx: any;
  public data: any;
  public options: any;
  public invoiceData = [];
  public chartFilters = [
    { id: 6, name: 'Last 6 Months' },
    { id: 12, name: 'Last 12 Months' },
  ];
  public selectOption;
  public balance;
  // lobInfo: any;

  constructor(
    private infoService: PortalInfoService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    //  this.data = {
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //   datasets: [
    //     {
    //       label: 'First Dataset',
    //       data: [65, 59, 80, 81, 56, 55, 40]
    //     }
    //   ]
    // };
    // this.selectOption = this.getFilterFirstOption();
    // this.options = {
    //   title: {
    //     display: true,
    //     text: 'Income and Expense',
    //     fontSize: 16,
    //   },
    //   legend: {
    //     position: 'bottom',
    //   },
    // };

    this.selectOption = this.getFilterFirstOption();
    this.options = {
      title: {
        display: true,
        text: 'Income and Expense',
        fontSize: 16,
      },
      legend: {
        position: 'bottom',
      },
    };
    this.getInvoiceChartData(this.getFilterFirstOption());
    this.getBalanceData();
  }
  getInvoiceChartData = (month) => {
    this.infoService
      .getInvoiceChart({
        customer_id: this.customer?.user_id,
        totalMonths: month,
        lob_id: this.lobInfo?.lob_id || 0,
      })
      .subscribe((res: any) => {
        if (res.status) {
          const lables = [];
          const balances = [];
          const expenseBalance = [];
          this.invoiceData = res.data;
          this.invoiceData.forEach((item) => {
            lables.push(item.yearmonth);
            balances.push(item.balance);
            expenseBalance.push(item.expenseBalance);
          });

          this.data = {
            labels: lables,
            datasets: [
              {
                label: 'Income',
                backgroundColor: '#a9d47d',
                data: balances,
              },
              {
                label: 'Expense',
                backgroundColor: '#42A5F5',
                data: expenseBalance,
              },
            ],
          };
        }
      });
  };
  getBalanceData = () => {
    this.infoService
      .getBalance(this.customer?.user_id, this.lobInfo?.lob_id || 0)
      .subscribe((res: any) => {
        if (res.status) {
          this.balance = res.data;
        }
      });
  };

  getFilterFirstOption = () => {
    return this.chartFilters[0]?.id;
  };
  numberFormatWithSymbol(number: any) {
    return this.apiService.numberFormatWithSymbol(number);
  }
}
