import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class PortalAuthGuard implements CanActivate {
  currentRoute: any
  constructor(private router: Router, private authService: AuthService) {
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.checkPortal()) {
      return true;
    } else {
      this.router.navigate(['/auth/my-portal/engear/login']);
      return false;
    }
  }

  checkPortal() {
    const value = JSON.parse(localStorage.getItem('isPortalLoggedIn'));
    if (value && value === true && this.authService.isPortalUserLoggedIn()) {
      return true;
    } else {
      return false;
    }
  }
}