import { object } from '@amcharts/amcharts4/core';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { STATUS, INVOICE_STATUS } from 'src/app/app.constant';

@Component({
  selector: 'app-advance-search-form-grn',
  templateUrl: './advance-search-form-grn.component.html',
  styleUrls: ['./advance-search-form-grn.component.scss']
})
export class AdvanceSearchFormGRNComponent implements OnInit {
  form: FormGroup
  statusList: Array<any> = STATUS;
  @Input() routes: Array<any> = []
  @Input() salesman: Array<any> = []
  constructor() { }
  routeFormControl: FormControl = new FormControl([]);
  salesmanFormControl: FormControl = new FormControl([]);

  ngOnInit(): void {
    this.form = new FormGroup({
      module: new FormControl('grn'),
      startdate: new FormControl(),
      route: new FormControl(),
      enddate: new FormControl(),
      salesman: new FormControl(),
      current_stage: new FormControl(),
      erp_status: new FormControl(),
      grn_number: new FormControl()
    });

    var model = JSON.parse(localStorage.getItem('advanceSearchModel'));
    if (model != null && model.module == "salesman_load") {
      Object.keys(model).forEach(res => {
        this.form.controls[res]?.setValue(model[res]);
      });
    }
  }

  
  selectionchangedRoute() {
    if (this.routeFormControl.value && this.routeFormControl.value.length > 0) {
      this.form.controls['route'].setValue(this.routeFormControl.value[0].id);
    }
  }
  selectionchangedSalesman() {
    if (this.salesmanFormControl.value && this.salesmanFormControl.value.length > 0) {

      this.form.controls['salesman'].setValue(this.salesmanFormControl.value[0].id);
    }
  }

}
