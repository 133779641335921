<div class="item-form">
    <div class="card">
        <div class="card-header pt-4">
            <span class="h6 font-weight-bold text-secondary">
                Invite user</span>
            <a (click)="close()" class="closeicon settingclose"><i class="os-icon os-icon-x float-right"
                    aria-hidden="true"></i></a>
        </div>

        <div class="card-body">
            <form [formGroup]="inviteUserFormGroup" [ngClass]="{'has-error': isSubmitted}">
                <div class="row mb-2">
                    <div class="col">
                        <label for="" class="">First Name*:</label>
                        <input type="text" class="form-control form-control-sm" formControlName="firstname">
                            <div class="" *ngIf="(inviteUserFormGroup.get('firstname')?.touched || isSubmitted) && inviteUserFormGroup.get('firstname')?.hasError('required')">
                                <small>First name is required.</small>
                            </div>
                    </div>
                    <div class="col">
                        <label for="" class="">Last Name*:</label>
                        <input type="text" class="form-control form-control-sm" formControlName="lastname">
                            <div class="" *ngIf="(inviteUserFormGroup.get('lastname')?.touched || isSubmitted) && inviteUserFormGroup.get('lastname')?.hasError('required')">
                                <small>Last name is required.</small>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <label for="" class="">Email*:</label>
                        <input *ngIf="!isEdit" type="text" class="form-control form-control-sm" formControlName="email">
                        <input type="text" class="form-control form-control-sm" formControlName="email" *ngIf="isEdit"
                            disabled>
                            <div class="" *ngIf="(inviteUserFormGroup.get('email')?.touched || isSubmitted) && inviteUserFormGroup.get('email')?.hasError('required')">
                                <small>Email is required.</small>

                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <label for="" class="">Phone*:</label>
                        <input type="text" class="form-control form-control-sm" formControlName="mobile">
                        <div class="" *ngIf="(inviteUserFormGroup.get('mobile')?.touched || isSubmitted) && inviteUserFormGroup.get('mobile')?.hasError('required')">
                                 <small>Phone is required.</small>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col">
                        <label for="" class="">Roles*:</label>
                        <select class="form-control form-control-sm" formControlName="role_id">
                            <option *ngFor="let role of roleData" [hidden]="role.id ===61" [disabled]="role.id ===61" [value]="role.id">{{role.name}}</option>
                        </select>
                        <div class="" *ngIf="(inviteUserFormGroup.get('role_id')?.touched || isSubmitted) && inviteUserFormGroup.get('role_id')?.hasError('required')">                      
                                <small>Role is required.</small>              
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="card-footer">
            <div class="d-inline float-right">
                <button class="btn btn-sm btn-light mr-2" (click)="close()">Cancel</button>
                <button class="btn btn-sm btn-p" (click)="inviteUser()"><span *ngIf="isEdit">Update User</span><span
                        *ngIf="!isEdit">Invite user</span></button>
            </div>
        </div>

    </div>
</div>