import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { Role } from 'src/app/components/datatables/org-role-dt/org-role-dt.component';
import { Users } from 'src/app/components/datatables/users-dt/users-dt.component';
import { ColumnConfig } from 'src/app/interfaces/interfaces';
import { CompDataServiceType } from 'src/app/services/constants';
import { DataEditor } from 'src/app/services/data-editor.service';
import { FormDrawerService } from 'src/app/services/form-drawer.service';
import { PortalSettingService } from '../portal-setting.service';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, AfterViewInit {
  @ViewChild('formDrawer') userRoleFormDrawer: MatDrawer;
  @Output() public itemClicked: EventEmitter<any> = new EventEmitter<any>();
  public user: Users;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public roleDataSource: MatTableDataSource<any>;
  public userDataSource: MatTableDataSource<any>;
  // data:any = [
  //   {name:'Jay Jawarkar',description:'This is Description'},
  //   {name:'Jay Jawarkar',description:'This is Description'},
  //   {name:'Jay Jawarkar',description:'This is Description'},
  // ];
  public drawerName = '';
  public isDetailVisible: boolean;
  public roles: boolean = false;
  public localUserData = JSON.parse(localStorage.getItem('portalCustomer'));
  public displayedColumns: ColumnConfig[] = [];
  public userTableColumns: string[] = ['user_details', 'role', 'actions']
  //   { def: 'user_details', title: 'Details', show: true },
  //   { def: 'role', title: 'Role', show: true },
  //   { def: 'actions', title: 'Actions', show: true },
  // ]
  public roleTableColumns: string[] = ['role_name', 'desc', 'actions']
  //   { def: 'role_name', title: 'Role Name', show: true },
  //   { def: 'desc', title: 'Description', show: true },
  //   { def: 'actions', title: 'Actions', show: true }
  // ]

  constructor(private fds: FormDrawerService, private portalService: PortalSettingService, private dataEditor: DataEditor) {
    this.roleDataSource = new MatTableDataSource<Role>();
    this.userDataSource = new MatTableDataSource<any>();
  }

  ngOnInit(): void {
    // this.dataSource = new MatTableDataSource<any>(this.data);
    this.fds.formName.subscribe(() => {
      this.loadInitialData();
    });
  }

  ngAfterViewInit(): void {
    this.fds.setDrawer(this.userRoleFormDrawer);
  }

  loadInitialData() {
    console.log(this.localUserData);
    // const model = {
    //   customer_user_role_id: this.localUserData?.user.role_id
    // }
    // this.portalService.getRoleList(model).subscribe(res => {
    //   this.roleDataSource = new MatTableDataSource<any>(res.data);
    //   // console.log(res);
    // })
    const model = {
      parent_user_id: this.localUserData?.user_id
    }
    this.portalService.getUserList(model).subscribe(res => {
      this.userDataSource = new MatTableDataSource<any>(res.data);
      // console.log(res);
    })
    this.portalService.org_Roles().subscribe(res => {
      this.roleDataSource = new MatTableDataSource<any>(res.data);
      // console.log(this.roleDataSource);
    })
  }

  reloadPage(event){
  this.loadInitialData();
  }

  public getDisplayedColumns(): string[] {
    return this.displayedColumns.filter(column => column.show).map(column => column.def);
  }

  public openDetailView(data: Users): void {
    this.isDetailVisible = true;
    this.user = data;
  }

  openCloneRole(role: any): void {
    this.drawerName = 'role-access';
    // this.fds.setFormName("role-access");
    // this.fds.open();
    this.dataEditor.sendData({ type: CompDataServiceType.DATA_EDIT_FORM, data: role });
    this.fds.setFormName('role-access');
    this.fds.setFormType('Edit');
    this.fds.open();
  }

  public closeClicked(): void {
    this.isDetailVisible = false;
  }

  public openAddUser() {
    this.drawerName = 'invite-user'
    this.fds.setFormName("invite-user");
    this.fds.open();
  }

  public tabChange(event) {
    if (event.index == 1) {
      this.roles = true;
      this.isDetailVisible = false;
    } else {
      this.roles = false;
    }
  }

}
