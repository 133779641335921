import { Injectable } from '@angular/core';
import { endpoints } from 'src/app/api-list/api-end-points';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PortalSettingService {

  constructor(private http: HttpClient) { }

  public getRoleList(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.user_role.roleList, model)
  }

  public getUserList(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.user_role.userList, model)
  }

  public inviteUser(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.user_role.inviteUser, model)
  }

  public permissions(): Observable<any> {
    return this.http.get<any>(endpoints.portalapiendpoint.user_role.permissions)
  }

  public org_Roles(): Observable<any> {
    return this.http.get<any>(endpoints.portalapiendpoint.user_role.org_Roles)
  }

  // public saveRoles(uuid,payload): Observable<any> {
  //   return this.http.get<any>(endpoints.portalapiendpoint.user_role.saveRoles('uuid'),payload)
  //   // return this.network.post(
  //   //   endpoints.apiendpoint.pricing.import('import'), payload
  //   // );
  // }

  saveRoles(id: string, data): Observable<any> {
    const url = `${endpoints.portalapiendpoint.user_role.saveRoles}/${id}`;
    return this.http.post(url, data);
  }
}
