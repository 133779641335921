<!-- <angular2-multiselect [data]="options" [(ngModel)]="selectedItems" [settings]="dropdownSettings"
    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)"  (onClose)="onClose($event)" (onFilterDeSelectAll)="onDeSelectAll($event)" (onFilterSelectAll)="onFilterSelectAll($event)" 
   >
</angular2-multiselect> -->

<angular2-multiselect [data]="options" [ngModel]="selectedItems" [settings]="dropdownSettings"
    (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
    (onDeSelectAll)="onDeSelectAll($event)" [formControl]="control" (onFilterDeSelectAll)="onDeSelectAll($event)"
    (onFilterSelectAll)="onFilterSelectAll($event)">
</angular2-multiselect>