import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { endpoints } from '../api-list/api-end-points';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token: string;
  public domain = window.location.host.split('.')[0];
  public baseUrl: string = this.domain == 'nfpc' ? environment.nfpcApiUrl : environment.baseApiUrl;
  // public baseUrl: string = 'https://mobiato-msfa.com/application-backend/public/api';

  private http: HttpClient;

  constructor(http: HttpClient, private router: Router) {
    Object.assign(this, { http });
  }

  public login(userLoginCredential: LoginCredential): Observable<any> {
    let url;
    if (userLoginCredential.type == "system") {
      url = `${this.baseUrl}/auth/login`;
    } else {
      url = `${this.baseUrl}/auth/social-login`;
    }

    return this.http.post(url, userLoginCredential);
  }

  public customerInfo(model): Observable<any> {
    return this.http.post<any>(endpoints.portalapiendpoint.customer_items.customerInfo, model);
  }

  public portalLogin(payload: any): Observable<any> {
    const url = `${this.baseUrl}/auth/portal/login`;
    return this.http.post(url, payload);
  }

  public portalSignUp(payload: any): Observable<any> {
    const url = `${this.baseUrl}/portal/signup`;
    return this.http.post(url, payload);
  }

  public checkPortal() {
    const value = JSON.parse(localStorage.getItem('isPortalLoggedIn'));
    // console.log(value);
    if (value && value === true && this.router.url.includes('portal')) {
      return true;
    } else {
      return false;
    }
  }


  public forgotPassword(forgotForm: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/forgot-password`, forgotForm);
  }

  public resetPassword(resetForm: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/reset-password`, resetForm);
  }

  public getToken(): string {
    return localStorage.getItem('token');
  }

  getSessionToken(): string {
    return sessionStorage.getItem('token');
  }

  public getPortalToken(): string {
    return localStorage.getItem('portalAccessToken');
  }
  // public logout(): void {
  //   localStorage.setItem('isLoggedIn', 'false');
  //   localStorage.removeItem('token');
  // }

  public logout(): Observable<any> {
    const url = `${this.baseUrl}/logout`;
    return this.http.get(url);
  }

  get avatar_img() {
    return localStorage.getItem('avatar_img') != 'undefined' ? JSON.parse(localStorage.getItem('avatar_img')) : '';
  }

  public errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    //console.log(errorMessage);
    return throwError(errorMessage);
  }

  isPortalUserLoggedIn(): boolean {
    return localStorage.getItem('portalAccessToken') ? true : false;
  }
}
interface LoginCredential {
  email: string;
  password: string;
  type: string;
}
