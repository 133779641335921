import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataEditor } from 'src/app/services/data-editor.service';
import { ReportService } from '../../reports/report.service';

@Component({
  selector: 'app-order-vs-invoice-report',
  templateUrl: './order-vs-invoice-report.component.html',
  styleUrls: ['./order-vs-invoice-report.component.scss']
})
export class OrderVsInvoiceReportComponent implements OnInit {
  sideFiltersForm;
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // public columns: any[] = [
  //   { field: 'Order No', value: 'order_no' },
  //   { field: 'Delivery Date', value: 'delivery_date' },
  //   { field: 'Item Code', value: 'item_code' },
  //   { field: 'Item Name', value: 'item_name' },
  //   { field: 'Order Qty', value: 'order_qty' },
  //   { field: 'Order Amount', value: 'order_amount' },
  //   { field: 'Invoice No', value: 'invoice_no' },
  //   { field: 'Invoice Qty', value: 'invoice_qty' },
  //   { field: 'Invoice Amount', value: 'invoice_amount' },
  // ];
  public displayedColumns = [
    'order_no',
    'delivery_date',
    'item_code',
    'item_name',
    'order_qty',
    'order_amount',
    'invoice_no',
    'invoice_qty',
    'invoice_amount',
  ];

  constructor(private router: Router,
    private api: ApiService,
    private fb: FormBuilder,
    private ReportService: ReportService,
    private dataEdit: DataEditor,
    private detChange: ChangeDetectorRef,
  ) {
    this.dataSource = new MatTableDataSource<OrderVsInvoiceReportComponent>();

  }

  ngOnInit(): void {
    this.sideFiltersForm = this.fb.group({
      start_date: '',
      end_date: '',
    });
  }

  public goBackToInvoiceList(): void {
    this.router.navigate(['portal/invoice']);
  }

  showReport(exp, type) {
    console.log(this.sideFiltersForm.value);
    let sfv = this.sideFiltersForm.value;
    const model = {
      startdate: sfv.start_date,
      enddate: sfv.end_date,
      export: exp,
      export_type: type,
      module: 'order-vs-invoice',
    };
    this.ReportService.getReportData(model).subscribe((res) => {
      if (res?.status == true) {
        if (exp == 1) {
          this.api.downloadFile(res.data.file_url, type);
        } else {
          this.dataSource = new MatTableDataSource<any>(res.data);
          this.dataSource.paginator = this.paginator;
        }
      }
      console.log(this.dataSource);
    });
  }
}
