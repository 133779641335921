<form [formGroup]="form" novalidate>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">First Name</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="firstname" class="form-control form-control-sm" placeholder="First Name">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Last Name</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="lastname" class="form-control form-control-sm" placeholder="Last Name">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Company Name</label>
                <div class="col-sm-9">
                    <input type="text"  formControlName="company_name"class="form-control form-control-sm" placeholder="Company Name">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Mobile</label>
                <div class="col-sm-9">
                    <input type="text"  formControlName="mobile"class="form-control form-control-sm" placeholder="Mobile">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Email</label>
                <div class="col-sm-9">
                    <input type="text"  formControlName="email"class="form-control form-control-sm" placeholder="Email">
                </div>
            </div>
        </div>
       
       
    </div>
</form>