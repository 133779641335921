<section class="makeScrollable">
  <div class="card-header">
    <span class="h6" style="font-size: 20px;"><b>Add Customers</b></span>
    <button class="dialog-close-btn" (click)="close()" mat-dialog-close>
      <i class="fa fa-times fa-lg" aria-hidden="true"></i>
    </button>
  </div>
  <div class="channel-holder scrolly">
    <div class=" data-body">
      <div class="table-responsive">
        <table class="table table-hover" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox class="head-checkbox" (change)="$event ? toggleSelection() : null"
                [checked]="selections.hasValue() && isAllSelected()"
                [indeterminate]="selections.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
                (change)="$event ? selections.toggle(row) : null" [checked]="selections.isSelected(row)"
                [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="customer_code">
            <th mat-header-cell *matHeaderCellDef>
              <app-column-filter title="Customer Code" (changeSelected)="onColumnFilterOpen($event)"
                [selected]="selectedColumnFilter" (close)="onColumnFilter($event)" controlName="customer_code">
                <input class="form-control form-control-sm" [formControl]="filterForm.get('customer_code')"
                  placeholder="Enter Code" type="text">
              </app-column-filter>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.customer_code}} </td>
          </ng-container>
          <ng-container matColumnDef="customer_name">
            <th mat-header-cell *matHeaderCellDef>
              <app-column-filter title="Customer Name" (changeSelected)="onColumnFilterOpen($event)"
                [selected]="selectedColumnFilter" (close)="onColumnFilter($event)" controlName="name">
                <input class="form-control form-control-sm" [formControl]="filterForm.get('name')"
                  placeholder="Enter Name" type="text">
              </app-column-filter>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.user?.firstname}} {{element.user?.lastname}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index"></tr>
        </table>
        <mat-paginator [hidden]="apiResponse.pagination?.total_records < 10" (page)="onPageFired($event)"
          [length]="apiResponse.pagination?.total_records" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 50]">
        </mat-paginator>
      </div>
    </div>
  </div>
  <div class="cancel-btn">
    <button mat-button class="mat-inherit-line-height height-40" (click)="close()" mat-dialog-close>
      Cancel
    </button>
    <button class="btn btn-sm btn-primary mr-2" (click)="saveJpCustomers()">
      Save
    </button>
  </div>
</section>