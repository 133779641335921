<div class="card">
  <div class="card-header">
    <p class="h4 font-weight-light d-inline">
      Import {{title}}
    </p>
  </div>
  <div class="row">
    <mat-horizontal-stepper linear #stepper>
      <mat-step [stepControl]="importForm" label="Configure" state="from">
        <div class=" card-body">
          <div class="row">
            <div class="col-lg-7">
              <form [formGroup]="importForm">
                <div>
                  <p class="font-medium">Download a <a [href]="csvURL">sample
                      csv file</a> or <a [href]="excelURL">sample
                      xls file</a>
                    and compare it to your import file to ensure you have the file perfect for the import.</p>
                </div>
                <div class="row">
                  <div class="col-lg-4"> <label>Upload Files : </label></div>
                  <div class="col-lg-7">
                    <input style="display: none" type="file" (change)="onSelectFile($event)"
                      formControlName="customer_file" name="customer_file" [(ngModel)]="importFile.customer_file" #file
                      required>
                    <button class="btn btn-sm btn-p" *ngIf='!(filesList.length > 0)' (click)="file.click()">Upload
                      file</button>
                    <span *ngIf='!(filesList.length > 0)'>No file selected</span>
                    <mat-chip-list #chipList aria-label="selection" *ngIf='(filesList.length > 0)'>
                      <mat-chip *ngFor="let file of filesList" [removable]="removable" (removed)="remove(file)">
                        {{file.name}}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip>
                    </mat-chip-list>
                    <!-- > -->
                    <div class="font-style"> <small> Maximum File Size: 5 MB </small>&nbsp;|&nbsp; <small> File Format:
                        CSV or TSV or XLS </small> </div>
                  </div>

                  <div class="col-lg-4"><label> Duplicate Handling: </label></div>
                  <div class="col-lg-8">
                    <mat-radio-group formControlName="skipduplicate" name="skipduplicate"
                      [(ngModel)]="importFile.skipduplicate" required>
                      <mat-radio-button value=1>Skip</mat-radio-button>
                      <p>Retains the records in Invoice and does not sync the identical records from the import file.
                      </p>
                      <mat-radio-button value=0>Overwrite</mat-radio-button>
                      <p>Overwrites existing records in Invoice with the identical records in the import file.</p>
                    </mat-radio-group>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-inline">
              <button mat-button matStepperNext class="btn btn-sm btn-p" (click)="mappingfield()">Next</button>
              <button mat-button class="btn btn-sm btn-light mr-2 cancle-btn" (click)="backToMain()">Cancel</button>
            </div>
          </div>
        </div>
      </mat-step>

      <mat-step label="Map Fields">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-2">
              <p>Your Selected File :</p>
            </div>
            <div class="col-sm-3"><span class="red" *ngFor="let file of filesList">{{file.name}}</span></div>
          </div>
          <div class="row" style="display: inline-block;margin:10px;">
            <p>The best match to each field on the selected file
              have been auto-selected.</p>
            <div class="row">
              <p class="h4 font-weight-light d-inline"> {{title}} Details </p>
            </div>
            <form [formGroup]="fieldFrom">
              <div class="row">
                <div class="col-6">{{title| uppercase }} FIELD</div>
                <div class="col-6">IMPORTED FILE HEADERS</div>
              </div>
              <ng-container formArrayName="mapField">
                <div class="row" *ngFor="let header of fieldFrom.get('mapField')['controls']; let i = index;"
                  [formGroupName]="i">
                  <div class="col-6"><label>{{header.controls['label_name'].value}}</label></div>
                  <div class="col-6">
                    <mat-select class="form-control form-control-sm" formControlName="label_value"
                      (selectionChange)="mapChange($event.value)">
                      <mat-option *ngFor="let field of data" [value]="field">{{field}}</mat-option>
                    </mat-select>
                  </div>
                </div>
              </ng-container>
              <div class="card-footer">
                <div class="d-inline">
                  <!-- <button mat-button matStepperPrevious class="btn btn-sm btn-light mr-2 cancle-btn">Back</button> -->
                  <button mat-button class="btn btn-sm btn-p" matStepperNext (click)="importData()">Next</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </mat-step>
      <mat-step label="Preview">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <mat-expansion-panel (opened)="!panelOpenState" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Duplicate Fields
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let data of duplicateData">
                  <label> Duplicate row no. {{data}} found in your File</label>
                </div>
              </mat-expansion-panel>

              <mat-expansion-panel (opened)="!panelOpenState" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Unmapped Fields
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let mapped of unMappedData">
                  <label>{{mapped}}</label>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-inline float-left">
              <button mat-button matStepperPrevious class="btn btn-sm btn-light mr-2">Back</button>
              <button class="btn btn-sm btn-p" (click)="finalImport()">Import</button>
              <button class="btn btn-sm btn-light mr-2" (click)="backToMain()">Cancel</button>
            </div>
          </div>
        </div>

      </mat-step>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="from">
        <mat-icon>forum</mat-icon>
      </ng-template>
    </mat-horizontal-stepper>
  </div>
</div>