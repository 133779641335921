<section>
  <div class="heading-row clearfix">
    <h2 class="d-md-inline">
      <i class="fa fa-credit-card mr-2"></i>Credit Note
      <span class="order-number">#{{ creditNoteData?.credit_note_number }}</span>
    </h2>
    <div class="d-inline float-right">
      <div class="btn-group btnrow mr-2">
        <button class="btn" type="button" [autoClose]="'outside'" triggers="manual" #email="ngbPopover"
          placement="bottom" (click)="email.open();" [ngbPopover]="emailPopContent" [popoverTitle]="emailPopTitle">
          <i class="os-icon os-icon-mail"></i>
        </button>
        <!-- <button *ngIf="showOrderEditAndDelete" class="btn" type="button"
          [routerLink]="['/portal/credit-note/edit', creditNoteData?.uuid]">
          <i class="os-icon os-icon-edit-32"></i>
        </button> -->
        <button class="btn" type="button" (click)="getDocument('pdf')">
          <mat-icon [inline]="true">picture_as_pdf</mat-icon>
        </button>
        <button class="btn" type="button" printSectionId="print-section" printTitle="Statement" [printStyle]="{
          h1: { color: 'red' },
          h2: { border: 'solid 1px' }
        }" [useExistingCss]="true" ngxPrint>
          <i class="os-icon os-icon-printer"></i>
        </button>
        <!-- <button class="btn" type="button">
          <mat-icon [inline]="true">attach_file</mat-icon>
        </button> -->
        <button (click)="onToggleHistory()" class="btn" type="button">
          <mat-icon [inline]="true">textsms</mat-icon>
        </button>
      </div>
      <!-- <span>

        <button class="btn btn-sm btn-white mr-2 dropdown-toggle" [matMenuTriggerFor]="menu2">
          More
        </button>
        <mat-menu #menu2="matMenu" xPosition="before">
          <button *ngIf="showOrderEditAndDelete" mat-menu-item class="height-40" appPermission
            [permissions]="userPermissions" [type]="'delete'" (click)="openDeleteBox()">
            Delete
          </button>
          <button *ngIf="showOrderStatusOptions" mat-menu-item class="height-40" (click)="openApplyToInvoiceModal()">
            Apply to Invoice
          </button>

        </mat-menu>
      </span> -->
      <button class="btn" (click)="closeDetailView()">
        <i class="os-icon os-icon-close"></i>
      </button>
    </div>
  </div>
  <div class="separator"></div>
  <div class="approval-holder" *ngIf="hasApprovalPending">
    <div class="approval-box">
      <div class="approve-icon">
        <i class="fa fa-check-square-o"></i>
      </div>
      <div class="approve-content">
        <h2>Approve this Credit Note</h2>
        <p>
          This Credit Note has been submitted for approval. Verify and approve
          the credit note.
        </p>
      </div>
      <div class="approve-actions">
        <button mat-button class="approve" (click)="approve()">Approve</button>
        <button mat-button class="reject" (click)="reject()">Reject</button>
      </div>
    </div>
  </div>
  <form class="order-form custom-mat square scrolly">
    <div class="card-header active-form overview">
      <div class="row">
        <div class="col-md-4">
          <div class="form-group" *ngIf="!isDepotOrder">
            <label for="">Customer</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
                creditNoteData?.customer
                  ? creditNoteData?.customer.firstname +
                    '' +
                    creditNoteData?.customer.lastname
                  : ''
              " disabled />
          </div>
        </div>
      </div>
    </div>

    <div class="card-body overview">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <div class="form-group" *ngIf="!isDepotOrder && creditNoteData?.lob">
            <label for="">Customer Lob</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
            creditNoteData?.lob?.name || ''" disabled />
          </div>
          <div class="form-group" *ngIf="creditNoteData?.customer_lpo">
            <label for="">Customer LPO</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="creditNoteData?.customer_lpo" disabled />
          </div>
          <div class="form-group">
            <label for="">Credit Note Type</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
            creditNoteData?.creditNoteType || ''" disabled />
          </div>
          <div class="form-group" *ngIf="creditNoteData?.invoice?.invoice_number">
            <label for="">Invoice</label>
            <input type="text" class="form-control form-control-sm non-editable"
              [value]="creditNoteData?.invoice?.invoice_number" disabled />
          </div>
          <div class="form-group" *ngIf="creditNoteData?.reason">
            <label for="">Reason</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="creditNoteData?.reason"
              disabled />
          </div>
          <div class="form-group" *ngIf="creditNoteData?.salesman">
            <label>Salesman Code</label>
            <input type="text" class="form-control form-control-sm non-editable"
             [value]="creditNoteData?.salesman?.salesman_info?.salesman_code" disabled />
          </div>
          <div class="form-group" *ngIf="creditNoteData?.salesman">
            <label>Salesman</label>
            <input type="text" class="form-control form-control-sm non-editable" [value]="
            creditNoteData?.salesman
                  ? creditNoteData?.salesman?.firstname +
                    ' ' +
                    creditNoteData?.salesman?.lastname
                  : ''
              " disabled />
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group" *ngIf="creditNoteData?.credit_note_number">
            <label for="">Credit Note Number</label>
            <input type="text" class="form-control form-control-sm non-editable"
              [value]="creditNoteData?.credit_note_number" disabled />
          </div>
          <div class="form-group">
            <label for="">Credit Note Date</label>
            <input type="date" class="form-control form-control-sm non-editable"
              [value]="creditNoteData?.credit_note_date" disabled />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="item-container custom-mat custom-mat-no-border">
        <table class="item-table cardtable">
          <thead>
            <tr>
              <th class="head" *ngFor="let head of itemTableHeaders">
                {{ head.label }}
              </th>
            </tr>
          </thead>
          <tbody class="form-body">
            <tr class="item-row" *ngFor="
                let item of creditNoteData?.credit_note_details;
                let i = index
              ">
              <td data-th="#">{{ i + 1 }}</td>
              <td data-th="ITEM NAME	" style="width: 150px;" class="item-name">
                <!-- <input type="text" class="form-control form-control-sm non-editable" [value]="item?.item?.item_name"
                  disabled /> -->
                <span style="word-break: break-word; font-size: 11px;">{{item?.item?.item_code}}</span>
              </td>
              <td data-th="Item Name" class="item-name" style="width: 150px;">
                <!-- <input type="text" class="form-control form-control-sm non-editable" [value]="item.item.item_name"
                  disabled /> -->
                <span style="word-break: break-word; font-size: 11px;">{{item?.item?.item_name}}</span>
              </td>
              <td data-th="Item Variant" class="item-name" style="width: 150px;" *ngIf="isItemVariantClassShow">
                <span style="word-break: break-word; font-size: 11px;">{{item?.item_variant?.name}}</span>
              </td> 
              <td data-th="Item Class" class="item-name" style="width: 150px;" *ngIf="isItemVariantClassShow">
                <span style="word-break: break-word; font-size: 11px;">{{item?.item_class?.name}}</span>
              </td>
              <td data-th="UOM" class="uom-td">
                <input type="text" class="form-control form-control-sm non-editable"
                  [value]="getUomValue(item.item_uom_id)" disabled />
              </td>
              <td data-th="QUANTITY">
                <input type="number" min="0" class="form-control non-editable" [value]="item.item_qty" disabled />
              </td>
              <td data-th="PRICE">
                <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_price)" disabled />
              </td>
              <td data-th="REASON" class="uom-td">
                <input type="text" class="form-control form-control-sm non-editable" [value]="item.reason" disabled />
              </td>
              <td data-th="EXCISE">
                <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_excise)"
                  disabled />
              </td>
              <!-- <td data-th="Invoice Number" class="uom-td">
                <input type="text" class="form-control form-control-sm non-editable" [value]="item?.invoice_number"
                  disabled />
              </td>
              <td data-th="Invoice Amount" class="uom-td">
                <input type="text" class="form-control form-control-sm non-editable"
                  [value]="numberFormat(item?.invoice_total)" disabled />
              </td> -->

              

              <td data-th="Expiry Date">
                <input type="date" min="0" class="form-control non-editable" [value]="item.item_expiry_date" disabled />
              </td>
              <td data-th="DISCOUNT">
                <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_discount_amount)"
                  disabled />
              </td>
              <td data-th="NET">
                <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_net)" disabled />
              </td>
              <td data-th="VAT">
                <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_vat)" disabled />
              </td>
              <td data-th="TOTAL">
                <input type="text" class="form-control non-editable" [value]="numberFormat(item.item_grand_total)"
                  disabled />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="footer-overview-container">
        <div class="row justify-content-end">
          <div class="col-sm-4">
            <div class="overall-stats-table">
              <div *ngFor="let stat of orderStats.slice(0, orderStats.length - 1)">
                <span class="stat-label">{{ stat.label }}</span>
                <span class="stat-value">{{
                  creditNoteData && numberFormatWithSymbol(creditNoteData[stat.key])

                  }}</span>
              </div>
              <div>
                <span class="stat-label final-total">Total</span>
                <span class="stat-value final-total">{{
                  numberFormatWithSymbol(creditNoteData?.grand_total)
                  }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- <div class="footer-block"></div> -->
</section>

<ng-template #emailPopTitle>
  <span>Sent Credit Note {{creditNoteData.credit_note_number}}</span>
</ng-template>
<ng-template #emailPopContent>
  <app-send-email [data]="emailData" (close)="email.close()"></app-send-email>
</ng-template>

<div class="card border" id="print-section">
  <div class="card-body" [innerHTML]="creditNoteTemplate"></div>
</div>