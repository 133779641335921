import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { InvoiceServices } from '../../../../portal/invoice/invoice.service';
import { CommonToasterService } from 'src/app/services/common-toaster.service';

@Component({
  selector: 'app-invoice-download',
  templateUrl: './invoice-download.component.html',
  styleUrls: ['./invoice-download.component.scss'],
})
export class InvoiceDownloadComponent implements OnInit {
  pipe = new DatePipe('en-US');
  public exportForm: FormGroup;
  public export: any = [];
  private apiService: ApiService;
  public invoiceServices: InvoiceServices;
  private datePipe: DatePipe;

  constructor(
    datePipe: DatePipe,
    apiService: ApiService,
    private toast: CommonToasterService,
    invoiceServices: InvoiceServices
  ) {
    Object.assign(this, { apiService, invoiceServices });
  }

  ngOnInit(): void {
    this.exportForm = new FormGroup({
      date: new FormControl(''),
    });
  }
  downloadInvoiceUrl() {
    this.export.date = this.pipe.transform(this.export.date, 'yyyy-MM-dd');
    this.invoiceServices
      .downloadInvoice({
        invoice_date: this.export.date,
      })
      .subscribe((result: any) => {
        if (result.status) {
          this.toast.showSuccess('Sucessfull', result.message);
        } else {
          this.toast.showError('Error', result.message);
        }
      });
  }
}
