import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-default-message',
  templateUrl: './default-message.component.html',
  styles: [
  ]
})
export class DefaultMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
