import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonToasterService } from 'src/app/services/common-toaster.service';
import { environment } from 'src/environments/environment';
import { CollectionService } from '../../collection/collection.service';
import { OrderService } from '../../orders/order.service';
import { luhnCheck } from '../helpers/validate.helper';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {
  public paymentDetailForm: FormGroup;
  public cardNumber = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  public cvvNumber = [/\d/, /\d/, /\d/];
  public yearNumber = [/\d/, /\d/];
  public isSubmitted = false;
  public monthlist: any = [];
  public years: any = [];
  public month: any;
  public year: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PaymentGatewayComponent>,
    private toaster: CommonToasterService,
    private orderService: OrderService,
    private collectionService: CollectionService,
    public dialog: MatDialog,
    public router: Router,
    private ngZone: NgZone,
  ) {
    this.paymentDetailForm = new FormGroup({
      // email: new FormControl('', [Validators.required]),
      cardNumber: new FormControl('', [Validators.required, Validators.minLength(16), this.luhnValidator()]),
      month: new FormControl('', [Validators.required, this.customMonthValidator()]),
      year: new FormControl('', [Validators.required, this.customYearValidator()]),
      cvv: new FormControl('', [Validators.required]),
      nameOnCard: new FormControl('', [Validators.required])
    })
  }


  ngOnInit() {
    console.log(this.data)
  }

  cancel(){
    this.dialogRef.close();
  }

  onInputEntry(event, nextInput ,maxInput) {
    let input = event.target;
    let length = input.value.length;
    let maxLength = maxInput;
    if (length >= maxLength) {
      nextInput.focus();
    }
  }

  confirmPayment() {
    this.isSubmitted = true;
    console.log(this.paymentDetailForm)
    if (this.paymentDetailForm.valid) {
      (<any>window).Stripe.card.createToken({
        number: this.paymentDetailForm.value.cardNumber,
        exp_month: this.paymentDetailForm.value.month,
        exp_year: this.paymentDetailForm.value.year,
        cvc: this.paymentDetailForm.value.cvv
      }, (status: number, response: any) => {
        if(this.data.type === 'order'){
          this.orderPayPost('Paid');
          this.dialogRef.close();
        }else if(this.data.type === 'collection'){
          this.collectionPayPost();
          this.dialogRef.close();
        }
      })
    }
  }

  collectionPayPost(){
this.collectionService.addCollection(this.data.data).subscribe(
        (response) => {
          this.toaster.showSuccess(
            'Success',
            'Collection has been added successfuly.'
          );
          this.ngZone.run(()=>{
            this.router.navigate(['portal/collection']);
            });
        },
        (error) => { }
      );
  }

  orderPayPost(paymentStatus) {
    if (this.data.isEditData == true) {
      this.data.originalData.payment_status = paymentStatus;
      this.orderService.editOrder(this.data.originalData.uuid, this.data.originalData).subscribe(res => {
        if (res.status === true) {
          this.toaster.showSuccess('Success', res.message);
          this.ngZone.run(()=>{
            this.router.navigate(['portal/order']);
            });
          
        } else {
          this.toaster.showError('Error', res.message);
        }
      });
    }
    else {
      this.data.originalData.payment_status = paymentStatus;
      this.orderService.addorderList(this.data.originalData).subscribe(res => {
        if (res.status === true) {
          this.toaster.showSuccess('Success', res.message);
          this.ngZone.run(()=>{
            this.router.navigate(['portal/order']);
            });
        } else {
          this.toaster.showError('Error', res.message);
        }
      });
    }
  }


  //   //#region Set MonthLsit
  // GetMonths() {
  //   for (let i = 1; i <= 12; i++) {
  //     this.month = {};
  //     if(i.toString().length == 1)
  //     {
  //       this.month.text = "0"+i.toString();
  //       this.month.value = "0"+i.toString();
  //     }
  //     else
  //     {
  //       this.month.text = i.toString();
  //       this.month.value = i.toString();
  //     }

  //     this.monthlist.push(this.month);
  //   }
  // }
  // //#endregion
  // //#region  Set Year List
  // GetYears() {
  //   let year = new Date().getFullYear();
  //   for (let i = year; i <= year + 20; i++) {
  //     this.year = {};
  //     this.year.text = i.toString();
  //     this.year.value = i.toString();
  //     this.years.push(this.year);
  //   }
  // }
  // //#endregion




  //custom validators
  luhnValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if(luhnCheck(control.value)){
        return null;
      }else{
        return { 'validateCard': true }
      }
    };
  }

  customMonthValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const month = Number(control.value);
      if(month > 0 && month < 13 && control.value.length === 2){
        return null
      }else{
        return { 'validateMonth': true };
      }
    };
  }

  customYearValidator(): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const year = Number(control.value);
      const startYear = Number(new Date().toLocaleDateString('en', { year: '2-digit' }));
      const endYear = startYear + 20;
      if(year >= startYear && year <= endYear && control.value.length === 2){
        return null
      }else{
        return { 'validateYear': true };
      }
    };
  }
}
