<form [formGroup]="form" novalidate>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Date Range</label>
                <!-- <div class="col-sm-9" style="display:flex;">
                    <input type="date" formControlName="startdate" class="form-control form-control-sm" placeholder="Start Date">
                   &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
                   <input type="date" formControlName="enddate" class="form-control form-control-sm" placeholder="End Date">
                </div> -->
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col">
                            <input type="date" formControlName="startdate" class="form-control form-control-sm"
                                placeholder="Start Date">
                        </div>
                        <div class="col-1 p-0">
                            &nbsp; &nbsp;<span>_</span>&nbsp;&nbsp;
                        </div>
                        <div class="col">
                            <input type="date" formControlName="enddate" class="form-control form-control-sm"
                                placeholder="End Date">
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Route</label>
                <div class="col-sm-9">
                    <app-multiautocomplete isSingle="true" [options]="routes" (selectionchanged)="selectionchangedRoute()" [control]="routeFormControl"
                    text="route_name" id="id" placeholder="Search route">
                  </app-multiautocomplete>
                    <!-- <mat-select class="form-control form-control-sm" formControlName="route">
                        <mat-option *ngFor="let item of routes" [value]="item.id">
                            {{ item.route_name }}
                        </mat-option>
                    </mat-select> -->
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Salesman Load NO</label>
                <div class="col-sm-9">
                    <input type="text" formControlName="code" class="form-control form-control-sm"
                        placeholder="Salesman Load NO" />
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Salesman</label>
                <div class="col-sm-9">
                    <app-multiautocomplete isSingle="true" [options]="salesman" (selectionchanged)="selectionchangedSalesman()" [control]="salesmanFormControl" text="user"
                    id="id" placeholder="Select Salesman" objectName="user-root" param1="firstname" param2="lastname">
                  </app-multiautocomplete>
                    <!-- <mat-select class="form-control form-control-sm" formControlName="salesman">
                        <mat-option *ngFor="let item of salesman" [value]="item.id">
                            {{ item.firstname }} {{ item.lastname }}
                        </mat-option>
                    </mat-select> -->
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">Status</label>
                <div class="col-sm-9">
                    <mat-select class="form-control form-control-sm" formControlName="current_stage">
                        <mat-option value="pending">Pending </mat-option>
              <mat-option value="confirm">Confirm</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group row">
                <label class="col-sm-3 col-form-label">ERP Status</label>
                <div class="col-sm-9">
                    <mat-select class="form-control form-control-sm" formControlName="erp_status">
                        <mat-option value="Not Posted">Not Posted</mat-option>
                        <mat-option value="Posted">Posted</mat-option>
                        <mat-option value="Failed">Failed</mat-option>
                    </mat-select>
                </div>
            </div>
        </div>
    </div>
</form>