<section class="scrolly">
  <ng-container *ngIf="advanceSearchRequest.length>0">
    <app-search-criteria [data]="advanceSearchRequest" (change)="onChangeCriteria()" (close)="onCloseCriteria()">
    </app-search-criteria>
  </ng-container>
  <div class="table-responsive">
    <table class="table table-hover" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox class="head-checkbox" (change)="$event ? toggleSelection() : null" (change)="getSelectedRows()"
            [checked]="selections.hasValue() && isAllSelected()"
            [indeterminate]="selections.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
          </mat-checkbox>
          <a class="filter-btn" *ngIf="!isDetailVisible" [matMenuTriggerFor]="menu">
            <i class="fa fa-table" aria-hidden="true"></i>
          </a>
          <mat-menu #menu="matMenu">
            <div class="card-body p-2" style="min-width: 200px;">
              <ul style="list-style: none; padding-left: 7px;">
                <li *ngFor="let column of filterColumns">
                  <input type="checkbox" [(ngModel)]="column.show" class="m-2" (click)="$event.stopPropagation()" />
                  {{ column.title }}
                </li>
              </ul>
              <div class="float-right mb-2">
                <button class="btn btn-p bg-secondary">Cancel</button>
              </div>
            </div>
          </mat-menu>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox class="row-checkbox" (click)="$event.stopPropagation()"
            (change)="$event ? selections.toggle(row) : null" (change)="getSelectedRows()"
            [checked]="selections.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="expense_date">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Date" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" placeholder="Enter Date" type="date">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.expense_date }}</td>
      </ng-container>
      <ng-container matColumnDef="expense_category">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Expenses Category" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" placeholder="Expenses Category" type="text">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.expense_category?.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Reference" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" placeholder="Enter Reference" type="text">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">&nbsp;{{ element.reference }}</td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Customer" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" placeholder="Enter Customer" type="text">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          &nbsp;{{ element.customer?.firstname }}
        </td>
      </ng-container>
      <ng-container matColumnDef="customerCode">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Customer Code" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" placeholder="Enter Customer Code" type="text">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          &nbsp;{{element.customer?.customerinfo ? element.customer?.customerinfo?.customer_code :'N/A' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>
          <app-column-filter title="Amount" (changeSelected)="onColumnFilterOpen($event)"
            [selected]="selectedColumnFilter" (close)="onColumnFilter($event)">
            <input class="form-control form-control-sm" placeholder="Enter Amount" type="text">
          </app-column-filter>
        </th>
        <td mat-cell *matCellDef="let element">
          &nbsp;{{ numberFormatWithSymbol(element.amount) }}</td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef style="display: none;">Detail</th>
        <td mat-cell *matCellDef="let element">
          <div style="display: flex; justify-content: space-between;">
            <strong>{{ element.customer?.firstname }}</strong>
            <span>
              {{ numberFormatWithSymbol(element.amount) }}</span>
          </div>
          <div style="display: flex; justify-content: space-between;">
            <span style="color: #2485e8;">{{ element.reference }}</span>
            <span style="color: #2485e8;">
              {{ element.expense_date | date }}</span>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
      <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" (click)="openDetailView(row)"></tr>
    </table>
  </div>

  <div>
    <mat-paginator [hidden]="apiResponse.pagination?.total_records < 10" (page)="onPageFired($event)"
      [length]="apiResponse.pagination?.total_records" [pageSize]="pageSize" [pageSizeOptions]="paggingArray">
    </mat-paginator>
  </div>

</section>